import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Home_Component_Our_Partners from '../components/Home_Component_Our_Partners';

export class Careers extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (

            <div>
            <div>

                <div className="top-section breadcrumb about-bd">
                    <div className="container">
                        <h2 className="text-center mtb-0">Careers</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="bd-text">
                        <Link to="/">Agrigate /</Link>  <a className="active">Careers</a>
                    </div>
                </div>

                {/* Side Nav  */}
                {/* <div id="mySidenav" class="sidenav ">  
                        <a href="#" id="projects">List your warehouse</a>  
                    </div> */}

                <div className="ptop-50 mb-30">
                    <div className="container">
                        <div className="">
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-5">
                                    <div className="careers-img text-center">
                                        <img src={require('../assests/images/ps-team1.png')} />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="aboutText">
                                        <h3 className="">Passionate team</h3>
                                        <p className="">Our people are passionate about transforming warehousing discovery. We have a single goal—to keep pushing forward and delivering the best warehousing platform for customers.</p>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                        </div>
                        <div className="car-div"></div>
                        <div className="">
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-5">
                                    <div className="aboutText">
                                        <h3 className="">Cutting-edge technology</h3>
                                        <p className="">Not just the developers, the techies, or the designers, or the business analysts—everyone at Agrigate pushes the creative boundaries and obsesses over the details. We’re a group of people that’s thinking big for changing the traditional ways of warehousing and storage.</p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="careers-img text-center">
                                        <img src={require('../assests/images/edge-tech.png')} />
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container ptop-50 text-center">
                    <div class="caption">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-10">
                                <div className="caption-icon"> <img src={require('../assests/images/cap-icon3.png')} /></div>
                                <h4>Looking to make an impact? We’re looking for you.</h4>
                                <p className="">At Agrigate it’s about making a difference and being a part of a bigger picture. Join us and together we will do incredible things in warehousing and storage discovery.</p>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                    </div>
                </div>


            </div>
            <Home_Component_Our_Partners></Home_Component_Our_Partners>
            </div>
            )
    }
}

export default Careers
