import React, { Component } from 'react';
import Chart from "react-google-charts";
import queryString from 'query-string';
import { PieChart } from 'react-minimal-pie-chart';

export class Warehouse_Details_Stock_Details_Tab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            stockdetails: [],
            isFetching: false,
            commoditystock: [],
            fundedNonFundedStock: ""
        };
        // this.Headline = this.Headline.bind(this);
    }
    componentDidMount() {
        // eslint-disable-next-line no-restricted-globals
        //const value=queryString.parse(location.search);
        const id = this.props.wh_id;
        //first api for list

        fetch("https://agrigates.com/v2/bmsnew/warehouse/getBalanceStockSummary?warehouseId=" + id, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(

            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    stockdetails: result.data,
                    isFetching: true,
                    commoditystock: result.data.stockSummary,
                    fundedNonFundedStock: result.data.stockSummary.fundedNonFundedStock
                });
                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    render() {
        const { error, isLoaded, stockdetails, isFetching, commoditystock, fundedNonFundedStock } = this.state;
        //alert(JSON.stringify(commoditystock));
        //alert(JSON.stringify(`[{${stockdetails.fundedNonFundedStock}}]`))
        var piedata = []
        this.state.commoditystock.map(item => {
            var randomColor = "#000000".replace(/0/g, function () {
                return (~~(Math.random() * 16)).toString(16);
            });
            piedata.push({ 'title': item.commodity, 'value': parseFloat(item.quantity), 'color': randomColor })
        })

        console.log("piedata");
        console.log(piedata);

        var fundedpiedata = [];
        const funddata = this.state.stockdetails.fundedNonFundedStock;
        if (funddata != null) {
            console.log(funddata);
            var v1 = funddata[0];
            var v2 = funddata[1];
            fundedpiedata = [{ 'title': 'Funded', 'value': parseFloat(v1), 'color': '#95a67c' }, { 'title': 'NotFunded', 'value': parseFloat(v2), 'color': '#405981' }];
        }

        return (
            <div>
                {isFetching == false ? (
                    <div class="full-heading mt-10">Loading...</div>
                ) : (


                    <div>
                        <div class="full-heading mt-10">Quantity Wise Commodity Details</div>
                        <div class="row">
                            <div class="col-md-6">
                                <div id="donutchart" style={{ width: "100%", padding: "30px" }}>

                                    <PieChart
                                        data={piedata} lineWidth={40} paddingAngle={1}
                                    />;

                                </div>
                            </div>
                            <div class="col-md-6" >
                                <div class="sd-table  mt-35" >
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th class="">Commodity</th>
                                                <th class="">Quantity ( in MT)</th>
                                            </tr>
                                            {
                                                stockdetails.stockSummary.map(item => (
                                                    <tr>
                                                        <td>{item.commodity}</td>
                                                        <td>{item.quantity}</td>
                                                    </tr>
                                                )
                                                )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="full-heading mt-10">Funded Vs Non Funded Stock</div>
                        <div class="row">
                            <div class="col-md-6">
                                <div id="donutchart2" style={{ width: "100%", padding: "30px" }}>

                                    <PieChart
                                        data={fundedpiedata} lineWidth={40} paddingAngle={1}
                                    />;
                                </div>
                            </div>
                            <div class="col-md-6" >
                                <div class="sd-table  mt-35" >
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th class="">Funded</th>
                                                <th class="">Non Funded</th>
                                            </tr>
                                            <tr>
                                                {
                                                    stockdetails.fundedNonFundedStock.map(item => (
                                                        <td>{item}</td>
                                                    ))
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default Warehouse_Details_Stock_Details_Tab
