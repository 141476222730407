import React, { Component } from 'react';
import Warehouse_Details_Feedback_Tab from './Warehouse_Details_Feedback_Tab';
import Chart from "react-google-charts";
import Warehouse_Details_Detail_Tab from './Warehouse_Details_Detail_Tab';
import Warehouse_Details_Stock_Details_Tab from './Warehouse_Details_Stock_Details_Tab';
import $ from 'jquery';
import queryString from 'query-string';
import Loaderfn from './Loaderfn'
import Warehouse_details_survillance_tab from './Warehouse_details_survillance_tab'

export class Warehouse_Details_MainContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            warehouselist: [],
            isFetching: false

        };
        // this.Headline = this.Headline.bind(this);
    }

    componentDidMount() {

        //alert(1);
        //first api for list
        fetch("https://agrigates.com/v2/bmsnew/warehouse/getlist", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "limit": '20000000'
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    warehouselist: result.data,
                    isFetching: true
                });
                //alert(JSON.stringify(this.warehouselist));
                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }



    render() {

        // eslint-disable-next-line no-restricted-globals
        const values = queryString.parse();
        const ids = this.props.wh_id;
        //alert(ids);
        // alert(this.props.wh_id);
        //const queryString = require('query-string');
        // eslint-disable-next-line no-restricted-globals
        // var parsed = queryString.parse(location.search);
        // eslint-disable-next-line no-restricted-globals
        // const obj = queryString.parse(this.props.location.search);
        // alert(obj.id);
        // const queryParams = this.props.location.query;
        // alert(queryParams);
        const { error, isLoaded, warehouselist } = this.state;
        const { isFetching } = this.state;
        var singledata = null;

        // alert(singledata.images.length);

        singledata = warehouselist.length == 0 ? null : warehouselist.filter(x => x.warehouse_id == ids);
        //alert(JSON.stringify(singledata));
        if (singledata != null) {
            singledata = singledata[0];
        }



        // for tabs
        const id = this.props.tabs;
        $(document).ready(function () {
            // eslint-disable-next-line no-restricted-globals
            const value = queryString.parse(location.search);
            //alert(id);
            if (id == undefined) {
                $('ul.ag-tabs li').removeClass('current');
                $('.ag-tab-content').removeClass('current');
                $("#link-tab-11").addClass('current');
                $("#tab-11").addClass('current');
            }
            else {
                $('ul.ag-tabs li').removeClass('current');
                $('.ag-tab-content').removeClass('current');
                $("#link-" + id).addClass('current');
                $("#" + id).addClass('current');
            }


            $('ul.ag-tabs li').click(function () {
                var tab_id = $(this).attr('data-tab');
                $('ul.ag-tabs li').removeClass('current');
                $('.ag-tab-content').removeClass('current');
                $(this).addClass('current');
                $("#" + tab_id).addClass('current');
                //$("#tab-22").addClass('current');

            })

        })

        return (

            <div>

                {isFetching == false ? (
                    <Loaderfn></Loaderfn>
                ) : (
                    <div>
                        <h3 class="info-ui wow fadeIn" style={{ marginLeft: '20px' }}>{singledata.warehouse_name}</h3>
                        <div class="col-md-8">
                            <div class="filter-box">
                                <ul class="info-ui wow fadeIn">
                                    <li>Location:<span class="dtl-li">{singledata.location_name}</span></li>
                                    {/* <li>Capacity:<span class="dtl-li">{singledata.length== null ? 0 : singledata.length * singledata.breadth == null ? 0 : singledata.breadth} sq.ft.</span></li> */}
                                    <li>Capacity:<span class="dtl-li">{singledata.warehouse_capacity} sq.ft.</span></li>
                                    <li>Type:<span class="dtl-li">{singledata.warehouse_subtype_name}</span></li>
                                </ul>
                            </div>
                            {/* <!-- warehouse-box End--> */}

                            <div class="mt-20 wow fadeIn">
                                {singledata.images.length > 0 ? <img src={singledata.images[0].file_path} style={{ height: '450px' }} width="100%"></img> : <img src={require('../assests/images/demowarehouse.jpg')} width="100%" style={{ height: '450px' }} />}</div>
                            <div class="tab-container mt-30">
                                <ul class="ag-tabs">
                                    <li class="tab-link" data-tab="tab-11" id="link-tab-11">Warehouse Details</li>
                                    <li class="tab-link" data-tab="tab-22" id="link-tab-22">Surveillance</li>
                                    <li class="tab-link" data-tab="tab-33" id="link-tab-33">Stock Details</li>
                                    <li class="tab-link" data-tab="tab-44" id="link-tab-44">Feedback</li>
                                </ul>
                                {/* <!-- tab-11 --> */}
                                <div id="tab-11" class="ag-tab-content current rent-box mt-30 wow fadeIn">

                                    <Warehouse_Details_Detail_Tab wh_id={this.props.wh_id}></Warehouse_Details_Detail_Tab>
                                </div>
                                {/* <!-- First Box End--> */}

                                {/* <!-- tab-22 --> */}
                                <div id="tab-22" class="ag-tab-content rent-box mt-30 wow fadeIn">
                                    <Warehouse_details_survillance_tab wh_id={this.props.wh_id}></Warehouse_details_survillance_tab>
                                </div>
                                {/* <!-- tab-33 --> */}
                                <div id="tab-33" class="ag-tab-content  rent-box mt-30 wow fadeIn">
                                    <Warehouse_Details_Stock_Details_Tab wh_id={this.props.wh_id}></Warehouse_Details_Stock_Details_Tab>
                                </div>
                                {/* <!-- tab-33 --> */}
                                {/* <!-- tab-44 --> */}
                                <div id="tab-44" class="ag-tab-content wow fadeIn">
                                    <Warehouse_Details_Feedback_Tab wh_id={this.props.wh_id}></Warehouse_Details_Feedback_Tab>
                                </div>
                                {/* <!-- tab-44 --> */}

                            </div>

                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default Warehouse_Details_MainContent
$(document).ready(function () {
    $('ul.ag-tabs li').click(function () {
        var tab_id = $(this).attr('data-tab');

        $('ul.ag-tabs li').removeClass('current');
        $('.ag-tab-content').removeClass('current');

        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
    })

})