import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';

export class detail extends Component {
        constructor(props) {
            super(props);
            this.state = {
                data: {
                    '1': [
                    { godown_no: '', stack_no: '', lot_id: '', no_of_bags: '', GrossWeightIncludingGunnyWeight: '' },
                    ],
                    '2': [
                    { vehicle_no: '', weighbridge_slip_no: '', gross_weight: '', tare_weight: '', net_weight: '', no_of_bags: '' },
                    ],
                    '3':[],
                    cis_no:new URLSearchParams(this.props.location.search).get('cis_no'),
                    cdf_no:new URLSearchParams(this.props.location.search).get('cdf_no')   
                }
            };
        }
        componentDidMount() {
            window.scrollTo(0, 0);
            const id = this.props.wh_id;
            const queryString = this.props.location.search;
        
            // Parsing the query string
            const queryParams = new URLSearchParams(queryString);
            
            // Getting specific query parameters
            const cdf_no = queryParams.get('cdf_no');
            const cis_no = new URLSearchParams(this.props.location.search).get('cis_no');
            
            // console.log('CDF Number:', cdf_no);
            // console.log('CIS Number:', cis_no);

            if(cis_no){
                fetch("https://agrigates.com/v2/bmsnew/client/warehouseActivitycis?cis_no=" + cis_no, {
                    method: "get",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    }
                }
                )
                .then(response => response.json())
                .then(result => {
                    const formattedData = {
                        '1': result.data[1] || [],
                        '2': result.data[2] ,
                        'cis_no': cis_no,  // Adding cis_no to the state
                        'cdf_no': cdf_no 
                    };
                    this.setState({
                        data: formattedData,
                    });
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
            if(cdf_no){
                fetch("https://agrigates.com/v2/bmsnew/client/warehouseActivitycdf?cdf_no=" + cdf_no, {
                    method: "get",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    },
                }
                )
                .then(response => response.json())
                .then(result => {
                    const formattedData = {
                        '1': result.data[1],
                        '2': result.data[2],
                        '3': result.data[3] ,
                        'cis_no': cis_no,  // Adding cis_no to the state
                        'cdf_no': cdf_no
                    };
                    this.setState({
                        data: formattedData,
                    });
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        }
        
        //Inward Data rendering
        renderTable1 = () => {
            const { data } = this.state;
            return (
              <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '16px' }}> {/* Increase font size */}
                <thead>
                  <tr>
                    {/* Set specific widths and increase padding */}
                    <th style={{ width: '10%', border: '1px solid black', padding: '12px' }}>SR.NO.</th>
                    <th style={{ width: '18%', border: '1px solid black', padding: '12px' }}>Godown No.</th>
                    <th style={{ width: '18%', border: '1px solid black', padding: '12px' }}>Stack No.</th>
                    <th style={{ width: '18%', border: '1px solid black', padding: '12px' }}>Lot ID</th>
                    <th style={{ width: '18%', border: '1px solid black', padding: '12px' }}>No. of Bags</th>
                    <th style={{ width: '18%', border: '1px solid black', padding: '12px' }}>Gross Weight Including Gunny Weight</th>
                  </tr>
                </thead>
                <tbody>
                  {data['1'].map((item, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{index + 1}</td>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{item.godown_no}</td>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{item.stack_no}</td>
                      <td style={{ border: '1px solid black', padding: '12px' }}></td> {/* Assuming this is intentionally left empty */}
                      <td style={{ border: '1px solid black', padding: '12px' }}>{item.no_of_bags}</td>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{item.GrossWeightIncludingGunnyWeight}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
        } 
        renderTable2 = () => {
            const { data } = this.state;
            return (
              <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '16px' }}> {/* Increase font size */}
                <thead>
                  <tr>
                    {/* Set specific widths and increase padding */}
                    <th style={{ width: '10%', border: '1px solid black', padding: '12px' }}>SR.NO.</th>
                    <th style={{ width: '15%', border: '1px solid black', padding: '12px' }}>Vehicle No.</th>
                    <th style={{ width: '25%', border: '1px solid black', padding: '12px' }}>Weighbridge Slip No.</th>
                    <th style={{ width: '15%', border: '1px solid black', padding: '12px' }}>Gross Weight</th>
                    <th style={{ width: '15%', border: '1px solid black', padding: '12px' }}>Tare Weight</th>
                    <th style={{ width: '10%', border: '1px solid black', padding: '12px' }}>Net Weight</th>
                    <th style={{ width: '10%', border: '1px solid black', padding: '12px' }}>No. of Bags</th>
                  </tr>
                </thead>
                <tbody>
                  {data['2'].map((item, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{index + 1}</td>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{item.vehicle_no}</td>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{item.weighbridge_slip_no}</td>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{item.gross_weight}</td>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{item.tare_weight}</td>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{item.net_weight}</td>
                      <td style={{ border: '1px solid black', padding: '12px' }}>{item.no_of_bags}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
        }
       
        //Outward Data rendering
        renderTable3 = () => {
            const { data } = this.state;
            return (
            <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '16px' }}> {/* Increase font size */}
                <thead>
                <tr>
                    {/* Set specific widths and increase padding */}
                    <th style={{ width: '10%', border: '1px solid black', padding: '12px' }}>SR.NO.</th>
                    <th style={{ width: '18%', border: '1px solid black', padding: '12px' }}>Godown No.</th>
                    <th style={{ width: '18%', border: '1px solid black', padding: '12px' }}>Stack No.</th>
                    <th style={{ width: '18%', border: '1px solid black', padding: '12px' }}>No. of Bags</th>
                </tr>
                </thead>
                <tbody>
                {data['1'].map((item, index) => (
                    <tr key={index}>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{index + 1}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.godown_no}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.stack_no}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.no_of_bags}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            )
        }
        renderTable4 = () => {
            const { data } = this.state;
            return (
            <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '16px' }}> {/* Increase font size */}
                <thead>
                <tr>
                    {/* Set specific widths and increase padding */}
                    <th style={{ width: '10%', border: '1px solid black', padding: '12px' }}>SR.NO.</th>
                    <th style={{ width: '15%', border: '1px solid black', padding: '12px' }}>Vehicle No.</th>
                    <th style={{ width: '25%', border: '1px solid black', padding: '12px' }}>Weighbridge Slip No.</th>
                    <th style={{ width: '25%', border: '1px solid black', padding: '12px' }}>Gate Pass No.</th>
                    <th style={{ width: '15%', border: '1px solid black', padding: '12px' }}>Gross Weight</th>
                    <th style={{ width: '15%', border: '1px solid black', padding: '12px' }}>Tare Weight</th>
                    <th style={{ width: '10%', border: '1px solid black', padding: '12px' }}>Net Weight</th>
                    <th style={{ width: '10%', border: '1px solid black', padding: '12px' }}>No. of Bags</th>
                </tr>
                </thead>
                <tbody>
                {data['2'].map((item, index) => (
                    <tr key={index}>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{index + 1}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.vehicle_no}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.weighbridge_slip_no}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.gate_pass_no}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.gross_weight}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.tare_weight}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.net_weight}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.no_of_bags}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            )
        }
        renderTable5 = () => {
            const { data } = this.state;
            return (
            <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '16px' }}> {/* Increase font size */}
                <thead>
                <tr>
                    {/* Set specific widths and increase padding */}
                    <th style={{ width: '10%', border: '1px solid black', padding: '12px' }}>SR.NO.</th>
                    <th style={{ width: '15%', border: '1px solid black', padding: '12px' }}>WR SR No.</th>
                    <th style={{ width: '25%', border: '1px solid black', padding: '12px' }}>Bags to be release</th>
                    <th style={{ width: '25%', border: '1px solid black', padding: '12px' }}>MT to be release</th>
                </tr>
                </thead>
                <tbody>
                {data['3'].map((item, index) => (
                    <tr key={index}>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{index + 1}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.fk_wr_sr_no}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.withdrawal_bags}</td>
                    <td style={{ border: '1px solid black', padding: '12px' }}>{item.withdrawal_qty}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            )
        }

        render() {
            return (
                <div>
                    <div className="mt-30">
                        <div className="container">
                            <span>Please fill in the details of your warehouse.</span>
                            <div className="mt-20" >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="">
                                            <div className="inner-box mt-20">
                                                {this.state.data.cis_no?(
                                                    <React.Fragment>
                                                    <h4>Inward No:-{this.state.data.cis_no}</h4>
                                                    {/* Rendering Table 1 */}
                                                    <table>
                                                    <tbody>
                                                    <h4>Godown Details-</h4>
                                                    {this.renderTable1()}
                                                    </tbody>
                                                    </table>
                                                    
                                                    {/* Rendering Table 2 */}
                                                    <table>
                                                    <tbody>
                                                    <h4>Vehicle Details-</h4>
                                                        {this.renderTable2()}
                                                    </tbody>
                                                    </table>
                                                    </React.Fragment>
                                                ):(
                                                    <React.Fragment>
                                                    <h4>Outward No:-{this.state.data.cdf_no}</h4>
                                                    <table>
                                                    <tbody>
                                                    <h4>Godown Details-</h4>
                                                    {this.renderTable3()}
                                                    </tbody>
                                                    </table>
                                                    
                                                    {/* Rendering Table 2 */}
                                                    <table>
                                                    <tbody>
                                                    <h4>Vehicle Details-</h4>
                                                        {this.renderTable4()}
                                                    </tbody>
                                                    </table>
                                                    {/* Rendering Table 3 */}
                                                    <table>
                                                    <tbody>
                                                    <h4>WR Details-</h4>
                                                        {this.renderTable5()}
                                                    </tbody>
                                                    </table>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
}

export default detail
