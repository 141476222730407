import React, { Component } from 'react'
import Warehouse_List_ListContent from './Warehouse_List_ListContent'
export class Warehouse_List_Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            warehouselist: []
        };


    }

    componentDidMount() {

        fetch("https://agrigates.com/v2/bmsnew/warehouse/getlist", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "currentLatitude": "19.1141837",
                        "currentLongitude": "72.8807381",
                        "distance": "500",
                        "showInSpaceDiscovery": "1"
                    },
                    "offset": 0,
                    "limit": 50
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    warehouselist: result.data
                });

                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }



    render() {
        var { error, isLoaded, warehouselist } = this.state;
        //for unique record
        const uniqueArrfilters = [... new Set(warehouselist.map(data => data.warehouse_type_name))];

        return (
            <div>
                {/* <!-- Filters Details --> */}

                <div class="col-md-4">
                    <div class="filter-box">
                        <div class="pull-right label label-danger">2</div>Filters
                    </div>
                    <div class="inner-box mt-20">
                        <div class="filter-panel">
                            <h4 class="heading">Warehouse Type</h4>

                            {
                                uniqueArrfilters.map(filter => (
                                    <div class="checkbox">
                                        <label><input type="checkbox" value={filter} />{filter}</label>
                                    </div>
                                ))
                            }

                        </div>
                        <div class="filter-panel">
                            <h4 class="heading">Rent Range(per month)</h4>
                        </div>
                        <div class="filter-panel">
                            <h4 class="heading">Featured and Amenties</h4>
                            <div class="checkbox">
                                <label><input type="checkbox" value="" />Security</label>
                            </div>
                            <div class="checkbox">
                                <label><input type="checkbox" value="" />Stack Card</label>
                            </div>
                            <div class="checkbox">
                                <label><input type="checkbox" value="" />Firefighting Arrangements</label>
                            </div>
                            <div class="checkbox">
                                <label><input type="checkbox" value="" />Weighbridge Facilities</label>
                            </div>
                            <div class="checkbox">
                                <label><input type="checkbox" value="" />Power Backup</label>
                            </div>
                            <div class="checkbox">
                                <label><input type="checkbox" value="" />Labour Avallability</label>
                            </div>
                            <div class="checkbox">
                                <label><input type="checkbox" value="" />Grading Sorting Facility</label>
                            </div>
                            <div class="checkbox">
                                <label><input type="checkbox" value="" />Unloading Platform</label>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <!-- col-md-4 End--> */}
                {/* <!-- col-md-8 End--> */}

            </div>
        )
    }
}

export default Warehouse_List_Filters
