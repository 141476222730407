import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Home from './pages/Home';
import './assests/css/style.css';
import Warehouse_List from './pages/Warehouse_List';
import Warehouse_Details from './pages/Warehouse_Details';
import Warehouse_Details_Detail_Tab from './components/Warehouse_Details_Detail_Tab';
//home component
import  Home_Component_Search from './components/Home_Component_Search.js';
import Warehouse_Details_Feedback_Tab from './components/Warehouse_Details_Feedback_Tab';
import User_profile from './pages/User_profile';
import About from './pages/About';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
import Stock_Pile from './pages/Stock_Pile';
import Recent_Details from './pages/Recent_Details';
import Booking_orders from './pages/Booking_orders';
import Warehouse_listing from './pages/Warehouse_listing';
import Story from './pages/Story'
import Services from './pages/Services'
import Profiledetailpage from './pages/Profiledetailpage'
//import GoogleSuggest from './pages/GoogleSuggest';

//home component end

import { HashRouter, Route } from "react-router-dom";
import detail from './pages/detail.js';

//import React, { Component } from 'react'
const child = ({match}) => (
  <div style={{padding:'200px'}}>
          <h2>ID :{match.params.userId}

          </h2>
  </div>
)
export class App extends Component {
  render() {
    return (
      <div>
         <HashRouter>
        <Header></Header> 
       
        <Route exact path="/" component={Home} basename="/agrigate"/>
          <Route path="/home" component={Home}/>          
          <Route path="/warehouse-list/:search?" component={Warehouse_List} />
          <Route path="/warehouse-details/:id?/:tab?" component = {Warehouse_Details}/>        
          <Route path="/user-profile" component={User_profile}/>
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/careers" component={Careers} />
          <Route path="/stock-pile" component={Stock_Pile} />
          <Route path="/recent-details" component={Recent_Details}/>
          <Route path="/booking-orders" component={Booking_orders} />
          <Route path="/warehouse-listing" component={Warehouse_listing} />
          <Route path="/detail" component={detail} />
          <Route path="/services" component={Services} />
          <Route path="/story" component={Story} />
          <Route path="/profile" component={Profiledetailpage} />
          {/* <Route path={"/warehouse-details/details"} component={Warehouse_Details_Detail_Tab} />
          <Route path={"/warehouse-details/feetback"} component = {Warehouse_Details_Feedback_Tab}/> */}
        
        <Footer></Footer>
        </HashRouter>
      </div>
    )
  }
}

export default App

