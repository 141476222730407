import React, { Component } from 'react'
import $ from 'jquery';
import DatePicker from "react-datepicker";
import axios from 'axios';
import Moment from 'moment';
import { AsyncStorage } from 'AsyncStorage';
import Loader from 'react-loader-spinner';
import Header from '../shared/Header'


import "react-datepicker/dist/react-datepicker.css";


export class Edit_userprofile extends Component {
    constructor(props) {
        super(props);
        this.state = { username: '' };
        this.apifunction = new Header();
        this.state = {
            error: null,
            isLoaded: false,
            userdetails: [],
            isFetching: false,
            fname: '',
            lname: '',
            email: '',
            name: '',
            phone: '',
            email: '',
            address: '',
            dob: '',
            aadhar: '',
            pan: '',
            gst: '',
            image: null,
            Attachmentfile: [],
            userid: ''
        };
        //AsyncStorage.getItem('UserID').then((value) => { this.setState({ userid: value }) });
        var today = new Date();
        var getdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                this.setState({ userid: value });
            }
            else {
                window.location = '/';
            }
        });

    }

    onFileChange = event => {

        // Update the state 
        this.setState({ image: event.target.files[0] });

    };
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }



    onSubmit = (e) => {
        //window.location = `/#/user-profile`;
        e.preventDefault();

        if (this.state.name == '') {
            $("#showmsg").addClass("alert alert-danger").text("Please Enter Full Name");
        }
        else if (this.state.email == '') {
            $("#showmsg").addClass("alert alert-danger").text("Please Enter Email ID");
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.email)) {
                $("#showmsg").addClass("alert alert-danger").text("Please Enter Valid Email ID");
            }
        }
        else if (this.state.phone == '') {
            $("#showmsg").addClass("alert alert-danger").text("Please Enter Phone Number");
        }
        else {


            //alert(userid);
            // get our form data out of state
            const Request = {
                method: 'put',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'session': 'pass'
                },
                body: JSON.stringify(
                    {
                        "bms_sno": this.state.userid,
                        "bms_user_id": this.state.userid,
                        "bms_name": this.state.name,
                        "bms_email": this.state.email,
                        "bms_mobile": this.state.phone,
                        "bms_client_image": this.state.image,
                        "bms_user_address": this.state.address,
                        "bms_user_dob": this.state.dob,
                        "bms_user_pan": this.state.pan,
                        "bms_user_aadhar": this.state.aadhar,
                        "bms_user_gst": this.state.gst,
                        "bms_updatedBy": this.state.userid,
                        "bms_updateDate": this.state.getdate
                    }
                )
            }

            fetch("https://agrigates.com/v2/bmsnew/users", Request)
                .then(response => response.json())
                .then(result => {

                    this.setState({
                        isLoaded: true,
                        userdetails: result.data[0],
                        isFetching: true

                    });

                    AsyncStorage.setItem('name', this.state.name);
                    //this.apifunction.componentDidMount();
                    AsyncStorage.setItem('email', this.state.email);
                    // localStorage.setItem('name', "adefasdf");
                    //localStorage.setItem('email', this.state.email);
                    //localStorage.setItem('newname','sdsdfsdfhdhudshuygsdfuhsudhusdufh');
                    //alert('Profile Updated Successfully.');                   
                    this.props.handler();
                    window.location.reload(false);
                    this.props.parentCallback("Profile successfully updated.");


                },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                );
        }


    }

    onSubmit_profile_img = (e) => {

        e.preventDefault();

        if (this.state.name == '') {
            $("#showmsg").addClass("alert alert-danger").text("Please Enter Full Name");
        }
        else if (this.state.email == '') {
            $("#showmsg").addClass("alert alert-danger").text("Please Enter Email ID");
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.email)) {
                $("#showmsg").addClass("alert alert-danger").text("Please Enter Valid Email ID");
            }
        }
        else if (this.state.phone == '') {
            $("#showmsg").addClass("alert alert-danger").text("Please Enter Phone Number");
        }
        else {
            //alert(userid);
            // get our form data out of state
            const Request = {
                method: 'put',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'session': 'pass'
                },
                body: JSON.stringify(
                    {
                        "bms_sno": this.state.userid,
                        "bms_user_id": this.state.userid,
                        "bms_name": this.state.name,
                        "bms_email": this.state.email,
                        "bms_mobile": this.state.phone,
                        "bms_client_image": this.state.image,
                        "bms_user_address": this.state.address,
                        "bms_user_dob": this.state.dob,
                        "bms_user_pan": this.state.pan,
                        "bms_user_aadhar": this.state.aadhar,
                        "bms_user_gst": this.state.gst,
                        "bms_updatedBy": this.state.userid,
                        "bms_updateDate": this.state.getdate
                    }
                )
            }

            fetch("https://agrigates.com/v2/bmsnew/users", Request)
                .then(response => response.json())
                .then(result => {

                    this.setState({
                        isLoaded: true,
                        userdetails: result.data[0],
                        isFetching: true

                    });
                    // window.location.reload(false); 


                },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                );
        }
    }

    componentDidMount() {

        //alert(this.state.userid);
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                fetch("https://agrigates.com/v2/bmsnew/users/get", {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    },
                    body: JSON.stringify(
                        {
                            "filter": {
                                "id": this.state.userid
                            }
                        }
                    )
                }
                )
                    .then(response => response.json())
                    .then(result => {

                        this.setState({
                            isLoaded: true,
                            userdetails: result.data[0],
                            isFetching: true,
                            name: result.data[0].bms_name,
                            phone: result.data[0].bms_mobile,
                            email: result.data[0].bms_email,
                            address: result.data[0].bms_user_address,
                            dob: Moment(result.data[0].bms_user_dob).format('yyyy-MM-DD'),//moment(result.data[0].bms_user_dob ).utc().format('MM/DD/YYYY'),
                            aadhar: result.data[0].bms_user_aadhar,
                            pan: result.data[0].bms_user_pan,
                            gst: result.data[0].bms_user_gst,
                            image: result.data[0].bms_client_image
                        });
                        console.log(result.data);
                    },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
            }
        })
    }

    onFileChange_2 = event => {
        // alert('uload secod');   
        this.setState({
            img_1_loader: false
        })
        var selectedFile = event.target.files;
        // alert(selectedFile.length)
        //console.log(selectedFile)
        const formData = new FormData();
        var today = new Date();
        var doctype = "";
        const ext = selectedFile[0].name.split('.').pop();
        const datevalue = today.getDate() + today.getMonth() + today.getFullYear() + today.getHours() + today.getMinutes() + today.getSeconds();
        const filename = "profile_" + datevalue + "." + ext;
        if (ext == "png" || ext == "jpeg" || ext == "svg" || ext == "wpeg" || ext == "PNG" || ext == "JPEG" || ext == "SVG" || ext == "WPEG") {
            doctype = "Image";
        }
        // Update the formData object         
        formData.append("uploadFor", "Profile picture");
        formData.append("attachment", selectedFile[0], filename);
        const Request = {
            method: 'POST',
            headers: {
                'session': 'pass'
            },
            body: formData
        }

        fetch("http://3.6.136.111/file/save", Request)
            .then(response => response.json())
            .then(result => {


                this.setState({
                    IsUploaded: true,
                    image: result.data.filename,
                    img_1_loader: true
                });

                this.onSubmit_profile_img(event);
                //$("#file-picker").hide();
                //alert(JSON.stringify(result))
                // console.log(this.state.Attachmentfile);
                //console.log(this.state.Attachmentfile.data.filename);
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                })
        this.setState({
            isSending: false,
        })


    }



    render() {

        const { error, isLoaded, userdetails, isFetching } = this.state;
        var { name, phone, email, address, dob, aadhar, pan, gst, image } = this.state;

        return (
            <div>
                {isFetching == false ? (
                    <div>Loading...</div>
                ) : (
                    <div class="inner-box mt-20" style={{ padding: '0' }}>
                        <form onSubmit={this.onSubmit} enctype="multipart/form-data">

                            <div class="uprofile-sec text-center">
                                <div class="uprofile-img">
                                    {/* <p class="text-right"><a href="edit-profile.html"><i class="fa fa-pencil"></i> Edit</a></p> */}
                                    {this.state.image != null ?
                                        <img src={this.state.image} />
                                        : <img src={require('../assests/images/demo-profile.jpg')} />
                                    }
                                    {
                                        this.state.img_1_loader == false ?
                                            <Loader type="ThreeDots" color="#00BFFF" height={30} width={50} />
                                            :
                                            ""

                                    }
                                    <input type="file" name="files" id="files" accept="image/*" onChange={this.onFileChange_2} />
                                </div>
                                <div class="uprofile-form">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" placeholder="Full Name" onChange={this.onChange} value={name} />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="phone" disabled class="form-control" placeholder="Phone Number" minLength="10" maxLength="12" onChange={event => this.setState({ phone: event.target.value.replace(/\D/, '') })} value={phone} />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="email" class="form-control" placeholder="Email Address" onChange={this.onChange} value={email} />
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control" name="address" rows="3" placeholder="Address ..." onChange={this.onChange}>{address}</textarea>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control" name="dob" placeholder="DOB" onChange={this.onChange} value={dob} />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="aadhar" minLength="12" maxLength="12" placeholder="Aadhar Number" onChange={this.onChange} value={aadhar} onChange={event => this.setState({ aadhar: event.target.value.replace(/\D/, '') })} />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="pan" placeholder="PAN Number" minLength="10" maxLength="10" onChange={this.onChange} value={pan} />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" minLength="15" maxLength="15" name="gst" placeholder="GST Details" onChange={this.onChange} value={gst} />
                                    </div>
                                    <div class="text-center">
                                        <div class="form-group">
                                            <button class="btn btn-danger" role="button"> SUBMIT</button   >
                                        </div>
                                    </div>
                                    <div id="showmsg"></div>

                                </div>
                            </div>
                        </form>
                    </div>

                )}

            </div>
        )
    }
}

export default Edit_userprofile
