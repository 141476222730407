import React, { Component } from 'react';
import Warehouse_List_Header_Search from '../components/Warehouse_List_Header_Search';
import Home_Component_Our_Partner from '../components/Home_Component_Our_Partners';
import Sidenavbar from '../components/Sidenavbar';
import { Link } from 'react-router-dom';
import Loaderfn from '../components/Loaderfn'
import { AsyncStorage } from 'AsyncStorage';
import Moment from 'moment';

export class Booking_orders extends Component {
    constructor(props) {
        super(props);
        //this.routeParam = props.match.params.userId;
        this.state = {
            error: null,
            isLoaded: false,
            bookings: [],
            isFetching: false,
            userid: ''
        };
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                //alert(value)
                this.setState({ userid: value });
            }
            else {
                window.location = '/';
            }
        });

        //alert(search);
        // this.Headline = this.Headline.bind(this);
    }

    componentDidMount() {

        window.scrollTo(0, 0)
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                fetch("https://agrigates.com/v2/api/orders/get", {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    },
                    body: JSON.stringify(
                        {
                            "filter": {
                                "clientId": this.state.userid// client id kaha se milegi jo login wahi id rhegi kya
                            },
                            "select": [],
                            "join": {
                                "rateCard": true,
                                "commodityGroup": true
                            },
                            "sort": {
                                "orderId": "1"
                            },
                            "offset": 0,
                            "limit": 100
                        }
                    )
                }
                )
                    .then(response => response.json())
                    .then(result => {
                        this.setState({
                            isLoaded: true,
                            bookings: result.data,
                            isFetching: true
                        });

                        console.log(result.data);
                    },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
            }
        })
    }
    render() {
        const { error, isLoaded, bookings } = this.state;
        const { isFetching } = this.state;
        return (
            isLoaded == false ? <Loaderfn> </Loaderfn> :
                <div>
                    {/* <Sidenavbar></Sidenavbar> */}
                    <Warehouse_List_Header_Search></Warehouse_List_Header_Search>
                    <div class="mt-30">
                        <div class="container">
                            <h4 class="top-heading" >Your Booking Orders</h4>
                            <div class="mt-20">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="filter-box">
                                            <ul class="info-ui">
                                                <li>Dashboard</li>
                                            </ul>
                                        </div>
                                        <div class="inner-box mt-20">
                                            <div class="filter-panel">
                                                <h4 class="heading" ><Link to="/user-profile"><a class="">User Profile</a></Link></h4>
                                            </div>
                                            <div class="filter-panel">
                                                <h4 class="heading"> <Link to="/booking-orders"><a class="active">Orders</a></Link></h4>
                                            </div>
                                            <div class="filter-panel">
                                                <h4 class="heading">Stock</h4>
                                                <p class=""> <Link to="/stock-pile"><a class="">Stock Pile</a></Link></p>
                                                <p class=""> <Link to="/recent-details"><a class="">Recent Details</a></Link></p>
                                            </div>

                                        </div>
                                    </div>
                                    {/* <!-- col-md-4 End--> */}
                                    <div class="col-md-8">
                                        <div class="filter-box">
                                            <ul class="info-ui">
                                                <li>Booking Orders</li>
                                            </ul>
                                        </div>
                                        <div class="mt-20" >
                                            {
                                                bookings.length > 0 ?
                                                    bookings.map(item => (

                                                        <div class="inner-box  mt-10" >
                                                            <div class="row">
                                                                <div class="col-md-3 col-sm-3">
                                                                    <div class="pile-img">
                                                                        <input type="hidden" name="hfcommodityid" value={item.od_cg_id} />
                                                                        <img src={item.cg_Link} width="100%" />
                                                                        <div class="img-label"> {item.cg_Name}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-5 col-sm-5">
                                                                    <h4 class="heading">{item.od_wh_name}</h4>
                                                                    <p class="pile-dtl">Order ID:<b> {item.od_order_id}</b></p>
                                                                    {/* <p class="pile-dtl">Order Duration:<b> {new Intl.DateTimeFormat('en-GB', {
                                                                        month: 'short',
                                                                        day: '2-digit',
                                                                        year: 'numeric',
                                                                    }).format(new Date(item.od_from_date))} to  {item.od_to_date == "0000-00-00" ? null : new Intl.DateTimeFormat('en-GB', {
                                                                        month: 'short',
                                                                        day: '2-digit',
                                                                        year: 'numeric',
                                                                    }).format(new Date(item.od_to_date))} </b></p> */}
                                                                    <p class="pile-dtl">Order Duration:<b> {Moment(item.od_from_date).format('DD MMM yyyy')} to  {item.od_to_date == "0000-00-00" ? null :
                                                                        Moment(item.od_to_date).format('DD MMM yyyy')} </b></p>
                                                                </div>
                                                                <div class="col-md-4 col-sm-4">
                                                                    <p class="mt-20 pile-dtl">Order Quantity:<b> {item.od_quantity}</b></p>
                                                                    <p class="pile-dtl">Order Status:<b> {item.od_req_status}</b></p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))
                                                    : <h4>No Record Found.</h4>
                                            }


                                        </div>
                                    </div>
                                    {/* <!-- col-md-8 End--> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Home_Component_Our_Partner></Home_Component_Our_Partner>
                </div>
        )
    }
}

export default Booking_orders
