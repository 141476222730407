import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { AsyncStorage } from 'AsyncStorage';

export class Sidenavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userid : ''//localStorage.getItem('UserID')
        };
        AsyncStorage.getItem('UserID').then((value) => {
            if(value){
               this.setState({userid: value});
            }            
        });
        // this.Headline = this.Headline.bind(this);
    }

    render() {
        return (
            <div>
                <div id="mySidenav" class="sidenav ">
                    {/* <a href="#" id="projects">List your warehouse</a> */}
                    {
                        this.state.userid == '' ?
                        <Link data-toggle="modal" id="projects" data-target="#modal-signin">                            
                            <a class="btn btn-danger" role="button "> List your warehouse </a>                           
                        </Link>
                        :
                        <Link to="/warehouse-listing">
                          <a class="btn btn-danger" role="button " > List your warehouse</a>                            
                        </Link>
                    }
                </div>
            </div>
        )
    }
}

export default Sidenavbar
