import React, { Component } from 'react'
import Warehouse_List_Header_Search from '../components/Warehouse_List_Header_Search';
import Home_Component_Our_Partner from '../components/Home_Component_Our_Partners';
import Moment from 'moment';
import Sidenavbar from '../components/Sidenavbar';
import { Link } from 'react-router-dom';
import Loaderfn from '../components/Loaderfn'
import { AsyncStorage } from 'AsyncStorage';

export class Recent_Details extends Component {
    constructor(props) {
        super(props);
        //this.routeParam = props.match.params.userId;
        this.state = {
            error: null,
            isLoaded: false,
            recent_detail: [],
            isFetching: false,
            userid: ''
        };
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                //alert(value)
                this.setState({ userid: value });
            }
            else {
                window.location = '/';
            }
        });

        //alert(search);
        // this.Headline = this.Headline.bind(this);
    }

    componentDidMount() {


        window.scrollTo(0, 0)
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                fetch("https://agrigates.com/v2/bmsnew/client/warehouseActivity", {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    },
                    body: JSON.stringify(
                        {
                            "filter": {
                                "crfId": this.state.userid  // this is customer id that get after login
                            },
                            "limit": 100
                        }
                    )
                }
                )
                    .then(response => response.json())
                    .then(result => {
                        this.setState({
                            isLoaded: true,
                            recent_detail: result.data,
                            isFetching: true
                        });

                        console.log(result.data);
                    },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
            }
        })
    }
    render() {
        const { error, isLoaded, recent_detail } = this.state;
        const { isFetching } = this.state;
        return (
            isLoaded == false ? <Loaderfn></Loaderfn> :
                <div>
                    {/* <Sidenavbar></Sidenavbar> */}
                    <Warehouse_List_Header_Search></Warehouse_List_Header_Search>
                    <div class="mt-30">
                        <div class="container">
                            <h4 class="top-heading" >Recent Details</h4>
                            <div class="mt-20">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="filter-box">
                                            <ul class="info-ui">
                                                <li>Dashboard</li>
                                            </ul>
                                        </div>
                                        <div class="inner-box mt-20">
                                            <div class="filter-panel">
                                                <h4 class="heading" ><Link to="/user-profile"><a class="">User Profile</a></Link></h4>
                                            </div>
                                            <div class="filter-panel">
                                                <h4 class="heading"> <Link to="/booking-orders"><a class="">Orders</a></Link></h4>
                                            </div>
                                            <div class="filter-panel">
                                                <h4 class="heading">Stock</h4>
                                                <p class=""> <Link to="/stock-pile"><a class="">Stock Pile</a></Link></p>
                                                <p class=""> <Link to="/recent-details"><a class="active">Recent Details</a></Link></p>
                                            </div>

                                        </div>
                                    </div>
                                    {/* <!-- col-md-4 End--> */}
                                    <div class="col-md-8">
                                        <div class="filter-box">
                                            <ul class="info-ui">
                                                <li>Recent Details</li>
                                            </ul>
                                        </div>
                                        <div class="mt-20" >
                                            {
                                                recent_detail.length > 0 ?
                                                    recent_detail.map(item => (
                                                        <div class="inner-box  mt-10" >
                                                            <div class="row">
                                                                <div class="col-md-3 col-sm-3 ">
                                                                    <div class="pile-img">
                                                                        <img src={"https://commodityimagesagrigate.s3.ap-south-1.amazonaws.com/" + item.commodity_image} width="100%" />
                                                                        <div class="img-label">{item.commodity_name}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-5 col-sm-5 ">
                                                                    <h4 class="heading">{item.commodity_name}</h4>
                                                                    <p class="pile-dtl">No of Bags: <b> {item.numberOfBag}  Bags</b></p>
                                                                    <p class="pile-dtl">Warehouse: <b> {item.warehouse_name} | {item.location_name}</b></p>
                                                                </div>
                                                                <div class="col-md-4 col-sm-4 ">
                                                                    <p class="mt-20 pile-dtl">Quantity: <b> {item.weightInMt}MT</b></p>
                                                                    <p class="pile-dtl">Date: <b>
                                                                        {/* {new Intl.DateTimeFormat('en-GB', {
                                                                        month: 'long',
                                                                        day: '2-digit',
                                                                        year: 'numeric',
                                                                    }).format(new Date(item.transaction_date))} */}
                                                                        {Moment(item.transaction_date).format('DD MMM yyyy')}
                                                                    </b>
                                                                        {/* <a class="inward-link">Inward</a> */}
                                                                        {item.InOutType === "Outward" &&
                                                                        <Link to={`/detail?cdf_no=${item.cdf_no}`}>
                                                                        <p class="outward-link">{item.InOutType}</p>
                                                                        </Link>
                                                                    }
                                                                        {item.InOutType === "Inward" &&   
                                                                        <Link to={`/detail?cis_no=${item.transationNumber}`}>
                                                                        <p class="inward-link">{item.InOutType}</p>
                                                                        </Link>
                                                                    }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                    )
                                                    : <h4>No Record Found.</h4>
                                            }


                                        </div>
                                    </div>
                                    {/* <!-- col-md-8 End--> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Home_Component_Our_Partner></Home_Component_Our_Partner>
                </div>
        )
    }
}

export default Recent_Details
