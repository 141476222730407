import React, { Component } from 'react'
import $ from 'jquery';
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import ReactDOM from 'react-dom';

export class Home_Component_Testimonial extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state = {
            error: null,
            isLoaded: false,
            testimonial: [],
            isFetching: false
        };
    }
    componentDidMount() {


        fetch("https://agrigates.com/v2/api/testimonialss/get", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {

                    "filter": {
                        "isActive": "1"
                    },
                    "select": [],
                    "join": {},
                    "sort": {},
                    "offset": 0,
                    "limit": 100

                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    testimonial: result.data,
                    isFetching: true
                });

                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const { error, isLoaded, testimonial } = this.state;
        const { isFetching } = this.state;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1
        };

        return (
            <div>
                {/* <!-- testimonial --> */}
                <div class="testimonial">
                    <div class="container">
                        <div class="row">
                            <h2 class="text-center">Customer Testimonials </h2>
                            <div class="col-md-3"></div>
                            <div class="col-md-6 ">
                                <div class="embed-responsive embed-responsive-16by9 mt-20">
                                    <iframe class="embed-responsive-item" width="" height="" src="https://www.youtube.com/embed/RyUIU41Dm7o" ></iframe>
                                </div>
                            </div>
                            <div class="col-md-3"></div>
                        </div>

                        <div class="">
                            <div class="slideset">
                                <Slider ref={c => (this.slider = c)} {...settings}>
                                    {
                                        testimonial.map((item, index) => (
                                            <div key={index + 1}>
                                                <figure class="testimonial">
                                                    <blockquote style={{ minHeight: '100px' }}>
                                                        <span class="red-quote">&#8220;</span>
                                                        {item.tm_text}
                                                        <div class="arrow"></div>
                                                    </blockquote>

                                                    <div class="author">
                                                        <b>{item.tm_provided_by},</b>
                                                        <span class="th-margin-remove">{item.tm_provided_firm}</span>
                                                    </div>
                                                </figure>
                                            </div>

                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}


export default Home_Component_Testimonial