import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Home_Component_Our_Partners from '../components/Home_Component_Our_Partners';
import Parser from 'html-react-parser';

export class Profiledetailpage extends Component {
    constructor(props) {
        super(props);

        if (this.props.location.state == undefined) {
            window.location.href = '/#/about'
        }
        else {
            this.state = { details: this.props.location.state }
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render(props) {
        if (this.props.location.state == undefined) {
            window.location.href = '/#/about'
            return null;
        }
        else {
            return (
                <div>

<div>
                <div>
                    <div className="top-section breadcrumb about-bd">
                        <div className="container">
                            <h2 className="text-center mtb-0">About</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="bd-text">
                            <Link to="/">Agrigate /</Link>  <a className="active">About Us</a>
                        </div>
                    </div>

                  
                   {/* <!-- Leadership --> */}
                   <div class="our-partners ptop-50">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-10">
                                    <div class="row mt-30">
                                        <div class="col-md-3 col-sm-3">
                                            <div class="profileimg">
                                                <img src={require(`../assests/images/${this.state.details[0].img}`)} width="100%" />
                                            </div>
                                        </div>
                                        <div class="col-md-9 col-sm-9">
                                            <div class="profile">
                                                <h4 class="">{this.state.details[0].name}</h4>
                                                <p class="">{this.state.details[0].dept}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="lead-desc-sec">
                                        <div class="leadership-desc">{Parser(this.state.details[0].content)}</div>
                                     </div>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                        </div>
                    </div>


                      
                   

                    
                </div>)


                <Home_Component_Our_Partners></Home_Component_Our_Partners>
            </div>
      
                   

                   


                </div>
            )
        }
    }
}

export default Profiledetailpage
