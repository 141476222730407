import React, { Component } from 'react';
import * as $ from 'jquery';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import { AsyncStorage } from 'AsyncStorage';

let BMS_APP_URL = "/bms";

export class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            UserDetails: [],
            isFetching: false,
            mobile: '',
            otp: '',
            signin_mobile: '',
            signin_otp: '',
            setpassword: '',
            component_userdetails: [],
            firstname: '',
            userid: ''
        };
        this.show_mobile = this.show_mobile.bind(this);
        if (this.state.userid == '' || this.state.userid == null) {
            this.setState({
                userid: ''
            });
        }

        AsyncStorage.getItem('UserID').then((value) => { this.setState({ userid: value }) });
        //alert(localStorage.getItem('UserID'))
        //alert(this.state.component_userdetails.bms_name)
        // if(this.state.component_userdetails.bms_name != '' &&  this.state.component_userdetails.bms_name != undefined)
        // {
        //     var a = this.state.component_userdetails.bms_name.lastIndexOf(' '); // last occurence of space
        //     var new_firstname = this.state.component_userdetails.bms_name.substring(0, a); // Paul Steve
        //     this.setState({firstname : new_firstname})
        // }


        //$("#modal-signup").modal('show');

        //alert(search);
        // this.Headline = this.Headline.bind(this);
    }


    test() {
        window.location.reload();
        // this.props.history.push("/my/path");
    }

    Signout() {
        // window.localStorage.clear(); //clear all localstorage
        // window.localStorage.setItem('UserID', '');
        // window.localStorage.setItem('username', '');
        // window.localStorage.setItem('token', '');
        // window.localStorage.setItem('mobile', '');
        // window.localStorage.setItem('email', '');
        // window.localStorage.setItem('name', '');
        // window.localStorage.setItem('role', '');

        AsyncStorage.setItem('UserID', '');
        AsyncStorage.setItem('username', '');
        AsyncStorage.setItem('token', '');
        AsyncStorage.setItem('mobile', '');
        AsyncStorage.setItem('email', '');
        AsyncStorage.setItem('name', '');
        AsyncStorage.setItem('role', '');
        AsyncStorage.setItem('fasttrack', '');
        window.location = '/agrigate';
    }

    showlogin_modal() {
        window.$("#modal-signup").modal('hide');
        window.$("#modal-signin").modal('show');

    }
    showSignup_modal() {
        window.$("#modal-signin").modal('hide');
        window.$("#modal-signup").modal('show');

    }
    show_mobile() {
        alert(this.state.mobile);
        //var txtmobile = $("#mobile").val();
        //this.setState({mobile:txtmobile});
    }

    getButtonLabel(role) {
        if (role === "Repository User") {
            return "Repository Management"
        }
        if (role === "Inspection Agency") {
            return "Testing & Inspection Management"
        }
        if (role === "Warehouse User") {
            return "Warehouseing Management"
        }
        if (role === "Collateral Management") {
            return "Collateral Management"
        }
    }

    componentDidMount() {
        AsyncStorage.getItem('role').then((role) => {
            let token = localStorage.getItem("@AsyncStorage:token");
            let userID = localStorage.getItem("@AsyncStorage:UserID");

            //BMS access
            if (role && ["Zonal Dept Manager", "Admin"].includes(role)) {
                console.log('BMS role: ', role);
                let queryParam = "?token=" + token + "&role=" + role + "&userId=" + userID;

                if (role === "Admin") {
                    document.querySelector('#Admin_bms_btn').href = BMS_APP_URL + queryParam;
                    document.getElementById("Admin_Dropdown").style.display = "block"
                } else {
                    document.querySelector('#bms_btn').href = BMS_APP_URL + queryParam;
                    document.getElementById("bms_btn").style.display = "block"
                }
            }

            //fasttrack access
            if (role && ["Repository User", "Inspection Agency", "Warehouse User", "Collateral Management", "Admin"].includes(role)) {
                console.log('fasttrack role: ', role);
                AsyncStorage.getItem('mobile').then((value) => {
                    const apiUrl = "https://agrigates.com/login.php?hardr=2";

                    const requestData = {
                        mobile: value.substring(2),
                        otp: ""
                    };

                    const Request = {
                        method: 'post',
                        body: JSON.stringify(requestData)
                    };

                    // Making this API call to generate fasttrack url
                    fetch(apiUrl, Request)
                        .then((response) => response.json())
                        .then(data => {
                            const { usercode, token, email, group_id } = data;

                            const newUrl = `/admin-ui/#?usercode=${encodeURIComponent(usercode)}&token=${encodeURIComponent(token)}&email=${encodeURIComponent(email)}&group_id=${encodeURIComponent(group_id)}&permissions=1`;

                            console.log('newUrlfasttrack', newUrl);
                            // if (usercode && token && email && group_id) {
                            if (role === "Admin") {
                                document.querySelector('#ft_btn_RM').href = newUrl;
                                document.querySelector('#ft_btn_TIM').href = newUrl;
                                document.querySelector('#ft_btn_WM').href = newUrl;
                                document.querySelector('#ft_btn_CM').href = newUrl;
                                document.getElementById("Admin_Dropdown").style.display = "block"
                            } else {
                                document.querySelector('#ft_btn').href = newUrl;
                                document.querySelector('#ft_btn').innerHTML = this.getButtonLabel(role);
                                document.getElementById("ft_btn").style.display = "block";
                            }
                            // }
                        })
                        .catch(error => {
                            console.log("fasttrack link generation failed", error);
                        });
                });
            }
        });
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                fetch("https://agrigates.com/v2/bmsnew/users/get", {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    },
                    body: JSON.stringify(
                        {
                            "filter": {
                                "id": this.state.userid
                            }
                        }
                    )
                }
                )
                    .then(response => response.json())
                    .then(result => {

                        this.setState({
                            isLoaded: true,
                            component_userdetails: result.data[0],
                            isFetching: true
                        });
                        //AsyncStorage.setItem('name',result.data[0].bms_name);
                        localStorage.setItem('newname', result.data[0].bms_name);
                        // AsyncStorage.getItem('name').then((value) => {
                        //     if(value){
                        //        this.setState({name: value});
                        //     }

                        // });

                        //alert(JSON.stringify(result.data))
                        console.log(result.data);
                    },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
            }
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onSubmit_step1 = (e) => {

        var isValid = false;
        e.preventDefault();
        //alert(this.state.mobile);
        if (this.state.mobile == '') {
            $("#divmsg").addClass("alert alert-danger").text("Please Enter Mobile Number.");
        }
        else {
            var pattern = new RegExp(/^[0-9\b]+$/);

            if (!pattern.test($("#mobile").val())) {
                isValid = false;
                $("#divmsg").addClass("alert alert-danger").text("Please Enter Valid Mobile Number.");
            } else if ($("#mobile").val().length != 10) {

                isValid = false;
                $("#divmsg").addClass("alert alert-danger").text("Please Enter Valid Mobile Number.");
            }
            else {
                // get our form data out of state
                const Request = {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    },
                    body: JSON.stringify(
                        {
                            "mobile": "91" + this.state.mobile
                        }
                    )
                }
                $("#signup_step_1").hide();
                $("#divmsg_signup_s2").addClass("alert alert-success").text("OTP Sent! Please Enter OTP to Login.");
                $("#signup_step_2").show();
                fetch("https://agrigates.com/v2/bmsnew/auth/generateOTP", Request)
                    .then(response => response.json())
                    .then(result => {

                        this.setState({
                            isLoaded: true,
                            userdetails: result,
                            isFetching: true

                        });
                        //$('#modal-signin').modal('show');
                        $("#signup_step_1").hide();
                        $("#divmsg_signup_s2").addClass("alert alert-success").text("OTP Sent! Please Enter OTP to Login.");
                        $("#signup_step_2").show();

                    },
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
                //$("#modal-signin").modal('show');

            }
        }
    }

    onSubmit_step2 = (e) => {
        var isValid = false;
        e.preventDefault();
        //alert(this.state.mobile);
        if (this.state.otp == '') {
            $("#divmsg_signup_s2").addClass("alert alert-danger").text("Please Enter OTP.");
        }

        else {
            // get our form data out of state
            const Request = {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'session': 'pass'
                },
                body: JSON.stringify(
                    {
                        "mobile": "91" + this.state.mobile,
                        "otp": this.state.otp
                    }
                )
            }

            fetch("https://agrigates.com/v2/bmsnew/auth/loginWithOtp", Request)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result == "Incorrect OTP") {
                        this.setState({
                            isLoaded: true,
                            incorrectotp: result,
                            isFetching: true
                        })
                        //alert('Incorrect OTP');
                        $("#divmsg_signup_s2").addClass("alert alert-danger").text("Incorrect OTP");

                    }
                    else {
                        this.setState({
                            isLoaded: true,
                            userdetails: result,
                            isFetching: true

                        });
                        if (this.state.userdetails != null) {
                            this.setState({ userid: this.state.userdetails.id });
                            //AsyncStorage.setItem('UserID', this.state.userdetails.id)



                            // localStorage.setItem('UserID', this.state.userdetails.id);
                            // localStorage.setItem('username', this.state.userdetails.username);
                            // localStorage.setItem('token', this.state.userdetails.token);
                            // localStorage.setItem('mobile', this.state.userdetails.username);
                            // localStorage.setItem('email', this.state.userdetails.email);
                            // localStorage.setItem('name', this.state.userdetails.name);
                            // localStorage.setItem('role', this.state.userdetails.role);


                            AsyncStorage.setItem('UserID', this.state.userdetails.id);
                            AsyncStorage.setItem('username', this.state.userdetails.username);
                            AsyncStorage.setItem('token', this.state.userdetails.token);
                            AsyncStorage.setItem('mobile', this.state.userdetails.username);
                            AsyncStorage.setItem('email', this.state.userdetails.email);
                            AsyncStorage.setItem('name', this.state.userdetails.name);
                            AsyncStorage.setItem('role', this.state.userdetails.role);
                            AsyncStorage.setItem('fasttrack', this.state.userdetails.fasttrack);

                            this.componentDidMount();
                            window.location = '/agrigate/#/user-profile';
                            window.$("#modal-signup").modal('hide');
                        }
                        if (this.state.userdetails == null) {
                            //alert('incorrect otp');
                            //$("#divmsg_signup_s2").addClass("alert alert-danger").text("Incorrect OTP.");
                        }

                    }
                    // alert(JSON.stringify(this.state.incorrectotp))
                    // console.log(this.state.result);
                    // console.log(this.state.userdetails);
                    // console.log(this.state.error);

                    //alert( localStorage.getItem('test') ); // 1
                    // alert('otp verified');
                    // alert(JSON.stringify(this.userdetails));
                    // alert(this.result.id);

                    //$('#modal-signin').modal('show');
                    $("#signup_step_1").hide();
                    $("#divmsg_signup_s2").addClass("alert alert-success").text("OTP Sent! Please Enter OTP to Login.");
                    $("#signup_step_2").show();

                },
                    (error) => {
                        //alert('Incorrect OTP');
                        $("#divmsg_signup_s2").addClass("alert alert-danger").text("Incorrect OTP");
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                );
            //$("#modal-signin").modal('show');
            // if(this.state.userdetails != null)
            // {
            // window.$("#modal-signup").modal('hide');
            // window.location = '/#/user-profile'; 
            // }

        }
    }

    onSubmit_SignIn_step1 = (e) => {
        var isValid = false;
        e.preventDefault();
        //alert(this.state.mobile);
        if (this.state.signin_mobile == '') {
            $("#divmsg_signstep_1").addClass("alert alert-danger").text("Please Enter Mobile Number.");
        }
        else {
            var pattern = new RegExp(/^[0-9\b]+$/);

            if (!pattern.test($("#singin_mobile").val())) {

                isValid = false;
                $("#divmsg_signstep_1").addClass("alert alert-danger").text("Please Enter Valid Mobile Number.");
            } else if ($("#singin_mobile").val().length != 10) {

                isValid = false;
                $("#divmsg_signstep_1").addClass("alert alert-danger").text("Please Enter Valid Mobile Number.");
            }
            else {
                // get our form data out of state

                const Request = {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    },
                    body: JSON.stringify(
                        {
                            "mobile": "91" + this.state.signin_mobile
                        }
                    )
                }
                // $("#signin_step_1").hide();
                // $("#divmsg_signin_s2").addClass("alert alert-success").text("OTP Sent! Please Enter OTP to Login.");
                // $("#signin_step_2").show();
                fetch("https://agrigates.com/v2/bmsnew/auth/generateOTP", Request)
                    .then(response => response.json())
                    .then(result => {

                        this.setState({
                            isLoaded: true,
                            userdetails: result,
                            isFetching: true
                        });
                        //$('#modal-signin').modal('show');
                        $("#signin_step_1").hide();
                        $("#divmsg_signin_s2").addClass("alert alert-success").text("OTP Sent! Please Enter OTP to Login.");
                        $("#signin_step_2").show();
                    },
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
                //$("#modal-signin").modal('show');

            }
        }
    }

    onSubmit_SignIn_step2 = (e) => {

        var isValid = false;
        e.preventDefault();
        //alert(this.state.mobile);
        if (this.state.signin_otp == '') {
            $("#divmsg_signin_s2").addClass("alert alert-danger").text("Please Enter OTP.");
        }

        else {
            // get our form data out of state
            const Request = {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'session': 'pass'
                },
                body: JSON.stringify(
                    {
                        "mobile": "91" + this.state.signin_mobile,
                        "otp": this.state.signin_otp
                    }
                )
            }

            fetch("https://agrigates.com/v2/bmsnew/auth/loginWithOtp", Request)
                .then(response => response.json())
                .then(result => {
                    if (result == "Incorrect OTP") {
                        this.setState({
                            isLoaded: true,
                            incorrectotp: result,
                            isFetching: true
                        })
                        // alert("Incorrect OTP");
                        $("#divmsg_signin_s2").addClass("alert alert-danger").text("Incorrect OTP");

                    }
                    else {

                        this.setState({
                            isLoaded: true,
                            userdetails: result,
                            isFetching: true

                        });

                        AsyncStorage.setItem('UserID', this.state.userdetails.id);
                        AsyncStorage.setItem('username', this.state.userdetails.username);
                        AsyncStorage.setItem('token', this.state.userdetails.token);
                        AsyncStorage.setItem('mobile', this.state.userdetails.username);
                        AsyncStorage.setItem('email', this.state.userdetails.email);
                        AsyncStorage.setItem('name', this.state.userdetails.name);
                        AsyncStorage.setItem('role', this.state.userdetails.role);
                        AsyncStorage.setItem('fasttrack', this.state.userdetails.fasttrack);

                        //BMS Access
                        if (["Zonal Dept Manager", "Admin"].includes(result.role)) {
                            let queryParam = "?token=" + this.state.userdetails.token + "&name=" + this.state.userdetails.name + "&role=" + this.state.userdetails.role + "&userId=" + this.state.userdetails.id;

                            if (result.role === "Admin") {
                                document.querySelector('#Admin_bms_btn').href = BMS_APP_URL + queryParam;
                                document.getElementById("Admin_Dropdown").style.display = "block"
                            } else {
                                document.querySelector('#bms_btn').href = BMS_APP_URL + queryParam;
                                document.getElementById("bms_btn").style.display = "block"
                            }
                        };
                        //Fasttrack access
                        if (["Repository User", "Inspection Agency", "Warehouse User", "Collateral Management", "Admin"].includes(result.role)) {
                            const apiUrl = "https://agrigates.com/login.php?hardr=2";

                            const requestData = {
                                mobile: result.username.substring(2),
                                otp: ""
                            };

                            const Request = {
                                method: 'post',
                                body: JSON.stringify(requestData)
                            };

                            // Making this API call to generate fasttrack url
                            fetch(apiUrl, Request)
                                .then((response) => response.json())
                                .then(data => {
                                    const { usercode, token, email, group_id } = data;

                                    const newUrl = `/admin-ui/#?usercode=${encodeURIComponent(usercode)}&token=${encodeURIComponent(token)}&email=${encodeURIComponent(email)}&group_id=${encodeURIComponent(group_id)}&permissions=1`;

                                    console.log('newUrlfasttrack', newUrl);
					localStorage.setItem('currentUser', JSON.stringify({
            				usercode: usercode,
           				 email: email,
            				group_id: group_id,
            				permissions: '1',
            				token: token }));

                                        localStorage.setItem('ngStorage-currentUser', JSON.stringify({
                                        usercode: usercode,
                                         email: email,
                                        group_id: group_id,
                                        permissions: '1',
                                        token: token,
					currentTimeStamp:1717498370	
					}));


                                    if (result.role === "Admin") {
                                        document.querySelector('#ft_btn_RM').href = newUrl;
                                        document.querySelector('#ft_btn_TIM').href = newUrl;
                                        document.querySelector('#ft_btn_WM').href = newUrl;
                                        document.querySelector('#ft_btn_CM').href = newUrl;
                                        document.getElementById("Admin_Dropdown").style.display = "block"
                                    } else {
                                        document.querySelector('#ft_btn').href = newUrl;
                                        document.querySelector('#ft_btn').innerHTML = this.getButtonLabel(result.role);
                                        document.getElementById("ft_btn").style.display = "block";
                                    }
                                })
                                .catch(error => {
                                    console.log("fasttrack link generation failed", error);
                                });

                        }

                        //alert(localStorage.getItem('lastscreen'))
                        if (this.state.userdetails != null) {
                            this.setState({ userid: this.state.userdetails.id })
                            AsyncStorage.setItem('UserID', this.state.userdetails.id)
                            this.componentDidMount();
                            if (localStorage.getItem('lastscreen') == 'listyourwarehouse') {
                                window.$('#modal-signin').modal('hide');
                                this.componentDidMount();
                                window.location = '/agrigate/#/warehouse-listing';
                            }
                            // else if (localStorage.getItem('lastscreen') == 'bookspacefromlist') {
                            //     window.$('#modal-signin').modal('hide');
                            //     window.location = `/#${window.location.hash.substr(1)}`;
                            //     window.$('#get-details').modal('show');
                            //     window.location.reload();
                            // }
                            // else if (localStorage.getItem('lastscreen') == 'bookspacefromsurvillance') {
                            //     //window.location = '/#/warehouse-listing';
                            //     window.$('#modal-signin').modal('hide');
                            //     window.location = `/#${window.location.hash.substr(1)}`;
                            //     window.location.reload();
                            //     window.$('#details_survi').modal('show');

                            // }
                            // else if (localStorage.getItem('lastscreen') == 'bookspacefromrightsidepostedon') 
                            // { 
                            //     alert(1)
                            //    // window.location = `/#${window.location.hash.substr(1)}`;
                            //     window.location.reload();
                            //     window.$('#get-details_postedon').modal('show');



                            // }
                            else {
                                window.location = '/agrigate/#/user-profile';
                            }
                            // localStorage.setItem('UserID', this.state.userdetails.id);
                            // localStorage.setItem('username', this.state.userdetails.username);
                            // localStorage.setItem('token', this.state.userdetails.token);
                            // localStorage.setItem('mobile', this.state.userdetails.username);
                            // localStorage.setItem('email', this.state.userdetails.email);
                            // localStorage.setItem('name', this.state.userdetails.name);
                            // localStorage.setItem('role', this.state.userdetails.role);


                            window.$("#modal-signin").modal('hide');
                        }
                        if (this.state.userdetails == null) {
                            $("#divmsg_signin_s2").addClass("alert alert-danger").text("Incorrect OTP.");
                        }

                    }



                },
                    (error) => {
                        $("#divmsg_signin_s2").addClass("alert alert-danger").text("Incorrect OTP");
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                );

        }
    }

    setLocalStorage() {
        localStorage.setItem('lastscreen', 'listyourwarehouse');
    }


    render() {
        //alert(this.state.userid)
        const { error, isLoaded, recent_detail, component_userdetails } = this.state;
        const { isFetching, userid } = this.state;
        var new_firstname = '';
        var a = '';
        //this.state.userid = localStorage.getItem('UserID');
        // var logger_username = component_userdetails.bms_name;
        var logger_username = component_userdetails.bms_name;
        if (logger_username != null) {
            a = logger_username.lastIndexOf(' '); // last occurence of space

            if (a == -1) {
                a = logger_username;
                new_firstname = logger_username;
            }
            else {
                a = logger_username.lastIndexOf(' ');
                new_firstname = logger_username.substring(0, a);
            }

            // Paul Steve
            var new_lastname = logger_username.substring(a + 1); // Panakkal
            // alert(new_lastname)
        }

        return (
            <div>
                {/* <!-- Side Nav --> */}
                {
                    this.state.userid == '' || this.state.userid == undefined ?
                        <Link data-toggle="modal" id="projects" data-target="#modal-signin" onClick={() => this.setLocalStorage()}>
                            <div id="mySidenav" class="sidenav ">
                                <a id="projects"> List your warehouse </a>
                            </div>
                        </Link>
                        :
                        <Link to="/warehouse-listing">
                            <div id="mySidenav" class="sidenav ">
                                <a id="projects">List your warehouse</a>
                            </div>
                        </Link>

                }

                <div className="header">
                    <div className="container">
                        <Link to="/" className="navbar-brand"><img src={require('../assests/images/logo.png')} className="custom-logo" /></Link>
                        <input className="menu-btn" type="checkbox" id="menu-btn" />
                        <label className="menu-icon" for="menu-btn"><span className="navicon"></span></label>
                        <ul className="menu nav-left" >
                            <li>
                                <div className="inline-block">
                                    {/* <div className="inline-block"><a data-toggle="modal" data-target="#modal-signin" style={{ cursor: 'pointer' }} >Rent</a> </div> */}
                                    {this.state.userid == '' && <div className="inline-block">
                                        {/* <span class="verline">|</span> */}
                                        <a style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#modal-signup">Register</a></div>}
                                    {/* {this.state.userid != '' && <div className="inline-block"><Link to="/user-profile"><a style={{ cursor: 'pointer' }}>Profile</a></Link></div>} */}
                                </div>
                            </li>
                        </ul>
                        <ul className="menu nav-right">
                            <li><a className="btn btn-danger" style={{ padding: "6px 15px 6px 15px", display: "none" }} id='bms_btn' role="button" href={BMS_APP_URL}>Bank Management</a></li>
                            <li><a className="btn btn-danger" style={{ padding: "6px 15px 6px 15px", display: "none" }} id='ft_btn' role="button" href='#'>Fasttrack</a></li>
                            <li style={{ width: "100px", display: "none" }} id='Admin_Dropdown'>
                                {
                                    <div class="dropdown agddlmenu" style={{ position: 'absolute', margin: "9px" }} >
                                        <a class="dropdown-toggle" data-toggle="dropdown">
                                            All Modules
                                            <span class="caret"></span></a>
                                        <ul class="dropdown-menu dropdown-menu-right">
                                            <li><a href={BMS_APP_URL} id='Admin_bms_btn' style={{ cursor: 'pointer' }}>Bank Management</a></li>
                                            <li><a href='#' id='ft_btn_RM' style={{ cursor: 'pointer' }}>Repository Management</a></li>
                                            <li><a href='#' id='ft_btn_TIM' style={{ cursor: 'pointer' }}>Testing & Inspection Management</a></li>
                                            <li><a href='#' id='ft_btn_WM' style={{ cursor: 'pointer' }}>Warehouseing Management</a></li>
                                            <li><a href='#' id='ft_btn_CM' style={{ cursor: 'pointer' }}>Collateral Management</a></li>
                                        </ul>
                                    </div>
                                }
                            </li>
                            <li>
                                <img src={require('../assests/images/user.png')} className="huser" />
                                <span className="c-care">Customer Care:</span> <span>+91-7340056514</span>
                            </li>
                            <li>
                                {
                                    (this.state.userid != '' && this.state.userid != undefined) &&
                                    <div class="dropdown agddlmenu" style={{ position: 'absolute' }}>
                                        <a class="dropdown-toggle" data-toggle="dropdown">
                                            {
                                                component_userdetails.bms_client_image == null ?
                                                    <img src={require('../assests/images/user-b.png')} class="huser" style={{ borderRadius: '50%', width: '25px' }} />
                                                    :
                                                    <img src={component_userdetails.bms_client_image} class="huser" style={{ borderRadius: '50%', width: '25px' }} />

                                            }
                                            {new_firstname}
                                            <span class="caret"></span></a>
                                        <ul class="dropdown-menu dropdown-menu-right">
                                            <li><Link to="/user-profile"><a style={{ cursor: 'pointer' }}>Profile</a></Link></li>
                                            <li><Link to="/stock-pile"><a style={{ cursor: 'pointer' }}>Stock Pile</a></Link></li>
                                            <li><Link to="/recent-details"><a style={{ cursor: 'pointer' }}>Recent Details</a></Link></li>
                                            <li><Link to="/booking-orders"><a style={{ cursor: 'pointer' }}>Orders</a></Link></li>
                                            <li><a style={{ cursor: 'pointer' }} onClick={this.Signout}>Sign Out</a></li>
                                        </ul>
                                    </div>
                                }
                            </li>

                            {(this.state.userid == '' || this.state.userid == undefined) && <li><a className="btn btn-danger" role="button " data-toggle="modal" data-target="#modal-signin">Sign In</a></li>}
                            {/* {this.state.userid != '' && <li><a className="btn btn-danger" role="button " onClick={this.Signout}>Sign Out</a></li>} */}
                            {/* <li><button href="#" class="btn">List your warehouse</button></li> */}
                        </ul>
                    </div>
                </div>



                <div class="modal fade sign-popup" id="modal-signin">
                    <div class="modal-dialog dialog-sign">
                        <div class="modal-content" id="signin_step_1">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <img src={require('../assests/images/login-icon.jpg')} />
                                <h4 class="modal-title"><b>Sign In</b></h4>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.onSubmit_SignIn_step1}>
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="singin_mobile" name="signin_mobile" onChange={this.onChange} value={this.state.signin_mobile} maxLength="10" placeholder="Enter Mobile Number" />
                                    </div>
                                    <div class="form-group">
                                        {/* <input type="button" class="btn btn-block btn-primary" value="Sign In" /> */}
                                        {/* <Link to="/user-profile"><a class="btn btn-block btn-primary">Sign In</a></Link> */}
                                        <button type="submit" class="btn btn-block btn-primary btn-lg"> Next</button>
                                    </div>
                                    <div id="divmsg_signstep_1"></div>
                                    {/* <div class="form-group">
                                    <div class="signin checkbox">
                                        <label><input type="checkbox" value="" />Remember Me</label>
                                    </div>
                                    <div class="forgotP"><a href="">Forgot Password ?</a></div>
                                </div> */}
                                </form>
                            </div>
                            <div class="modal-footer">
                                <div class="text-center">New to Agrigate ? <a style={{ cursor: 'pointer' }} onClick={this.showSignup_modal}>Create an Account</a></div>
                            </div>
                        </div>
                        <div class="modal-content" hidden id="signin_step_2">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <img src={require('../assests/images/login-icon.jpg')} />
                                <h4 class="modal-title"><b>Sign In</b></h4>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.onSubmit_SignIn_step2}>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter OTP" value={this.state.signin_otp} name="signin_otp" onChange={this.onChange} />
                                    </div>
                                    <div class="form-group">
                                        {/* <input type="button" class="btn btn-block btn-primary" value="Sign In" /> */}
                                        {/* <Link to="/user-profile"><a class="btn btn-block btn-primary">Sign In</a></Link> */}
                                        <button type="submit" class="btn btn-block btn-primary btn-lg">Sign In</button>
                                    </div>
                                    <div id="divmsg_signin_s2"></div>
                                    {/* <div class="form-group">
                                    <div class="signin checkbox">
                                        <label><input type="checkbox" value="" />Remember Me</label>
                                    </div>
                                    <div class="forgotP"><a href="">Forgot Password ?</a></div>
                                </div> */}
                                </form>
                            </div>
                            <div class="modal-footer">
                                <div class="text-center">New to Agrigate ? <a style={{ cursor: 'pointer' }} onClick={this.showSignup_modal}>Create an Account</a></div>
                            </div>
                        </div>

                        {/* <!-- /.modal-content --> */}
                    </div>
                    {/* <!-- /.modal-dialog --> */}
                </div>

                <div class="modal fade sign-popup" id="modal-signup">
                    <div class="modal-dialog">
                        <form onSubmit={this.onSubmit_step1}>
                            <div class="modal-content" id="signup_step_1">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                    <img src={require('../assests/images/login-icon.jpg')} />
                                    <h4 class="modal-title"><b>Sign Up</b></h4>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group mt-20">
                                        <input type="text" class="form-control" id="mobile" name="mobile" onChange={this.onChange} value={this.state.mobile} maxLength="10" placeholder="Enter Mobile Number" />
                                    </div>
                                    <div class="form-group">
                                        {/* <input type="button" class="btn btn-block btn-primary" value="Continue" /> */}
                                        <button type="submit" class="btn btn-block btn-primary btn-lg" >Continue</button>
                                    </div>
                                    <div id="divmsg"> </div>
                                </div>
                                <div class="modal-footer">
                                    <div class="text-center">Existing User ? <a style={{ cursor: 'pointer' }} onClick={this.showlogin_modal}>Log In</a></div>
                                </div>
                            </div>
                        </form>
                        {/* <!-- /.modal-content --> */}
                        <div class="modal-content" hidden id="signup_step_2">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <img src={require('../assests/images/login-icon.jpg')} />
                                <h4 class="modal-title"><b>Sign Up</b></h4>
                            </div>

                            <div class="modal-body">
                                <form onSubmit={this.onSubmit_step2}>
                                    <div id="divmsg_signup_s2"></div>
                                    <div class="form-group mt-20">
                                        <input type="text" class="form-control" placeholder="Enter OTP" value={this.state.otp} onChange={this.onChange} name="otp" />
                                    </div>
                                    <div class="form-group">
                                        {/* <input type="button" class="btn btn-block btn-primary" value="Sign Up" /> */}
                                        <button type="submit" class="btn btn-block btn-primary btn-lg">Sign Up</button>
                                    </div>
                                    <div class="form-group">
                                        {/* <!-- <div class="signin checkbox"> --> */}
                                        {/* <!-- <label><input type="checkbox" value="">Remember Me</label> --> */}
                                        {/* <!-- </div> --> */}
                                        <div class="forgotP"><a style={{ cursor: 'pointer' }} onClick={this.onSubmit_step1}>Resend OTP ?</a></div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <div class="text-center">Existing User ? <a style={{ cursor: 'pointer' }} onClick={this.showlogin_modal}>Login</a></div>
                            </div>
                        </div>
                        {/* <!-- /.modal-content --> */}
                        <div class="modal-content" hidden id="signup_step_3">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <h4 class="modal-title"><b>Sign Up</b></h4>
                                <img src={require('../assests/images/login-icon.jpg')} />
                            </div>
                            <div class="modal-body">
                                <div class="form-group mt-20">
                                    <input type="text" class="form-control" placeholder="Enter Name" />
                                </div>
                                <div class="form-group ">
                                    <input type="password" class="form-control" placeholder="Email ID" />
                                </div>
                                <div class="form-group">
                                    <input type="button" class="btn btn-block btn-primary" value="Finish" />
                                </div>
                            </div>
                            <div class="modal-footer">
                                {/* <!-- <div class="text-center">Existing User ? <a href="">Login</a></div> --> */}
                            </div>
                        </div>
                        {/* <!-- /.modal-content --> */}
                        {/* <!-- /.modal-content --> */}
                        <div class="modal-content" hidden id="signup_step_4">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <img src={require('../assests/images/login-icon.jpg')} />
                                <h4 class="modal-title"><b>Sign Up</b></h4>
                            </div>
                            <div class="modal-body">
                                <div class="form-group mt-20">
                                    <input type="password" class="form-control" placeholder="Enter Password" />
                                </div>
                                <div class="form-group">
                                    <input type="button" class="btn btn-block btn-primary" value="Sign In" />
                                </div>
                                <div class="form-group">
                                    <div class="forgotP"><a href="">Get OTP </a></div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="text-center"><a href="">You already have an account</a></div>
                            </div>
                        </div>
                        {/* <!-- /.modal-content --> */}
                        <div class="modal-content" hidden id="signup_step_5">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <img src={require('../assests/images/login-icon.jpg')} />
                                <h4 class="modal-title"><b>Sign Up</b></h4>
                            </div>
                            <div class="modal-body">
                                <div class="form-group mt-20">
                                    <input type="text" class="form-control" placeholder="Enter OTP" />
                                </div>
                                <div class="form-group">
                                    <input type="button" class="btn btn-block btn-primary" value="Sign In" />
                                </div>
                                <div class="form-group">
                                    <div class="forgotP"><a href="">Resend OTP ?</a></div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                {/* <!-- <div class="text-center">Existing User ? <a href="">Login</a></div> --> */}
                            </div>
                        </div>
                        {/* <!-- /.modal-content --> */}
                        <div class="modal-content" hidden id="signup_step_6">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                {/* <!-- <h4 class="modal-title"><b>Sign Up</b></h4> --> */}
                            </div>
                            <div class="modal-body">
                                <div class="text-center mt-20">
                                    <h4 class="modal-title"><b>Login Success</b></h4>
                                    <div class="mt-20 mb-30"><b>You have succesfully logged in. <br />Please Click to  <a href="">Update your profile.</a></b></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <!-- /.modal-dialog --> */}
                </div>

            </div >
        )
    }
}

export default Header
