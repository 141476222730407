import React, { Component } from 'react';
import Warehouse_List_Header_Search from '../components/Warehouse_List_Header_Search';
import Home_Component_Our_Partner from '../components/Home_Component_Our_Partners';
import Sidenavbar from '../components/Sidenavbar';
import { Link } from 'react-router-dom';
import Loaderfn from '../components/Loaderfn'
import { AsyncStorage } from 'AsyncStorage';
export class Stock_Pile extends Component {
    constructor(props) {
        super(props);
        //this.routeParam = props.match.params.userId;
        this.state = {
            error: null,
            isLoaded: false,
            stockpile: [],
            isFetching: false,
            userid: ''
        };
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                //alert(value)
                this.setState({ userid: value });
            }
            else {
                window.location = '/';
            }
        });
        //alert(search);
        // this.Headline = this.Headline.bind(this);
    }

    componentDidMount() {

        window.scrollTo(0, 0)

        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                fetch("https://agrigates.com/v2/bmsnew/client/stock", {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    },
                    body: JSON.stringify(
                        {
                            "filter": {
                                "crfId": this.state.userid   // this is customer id that get after login
                            }
                        }
                    )
                }
                )
                    .then(response => response.json())
                    .then(result => {
                        this.setState({
                            isLoaded: true,
                            stockpile: result.data,
                            isFetching: true
                        });

                        console.log(result.data);
                    },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
            }
        })
    }

    render() {
        const { error, isLoaded, stockpile } = this.state;
        const { isFetching } = this.state;
        return (

            isLoaded == false ? <Loaderfn></Loaderfn> :
                <div>
                    {/* <!-- Side Nav --> */}
                    {/* <!-- Side Nav --> */}
                    {/* <Sidenavbar></Sidenavbar> */}

                    {/* <!-- Search Form  --> */}
                    <Warehouse_List_Header_Search></Warehouse_List_Header_Search>
                    {/* <!-- Filters Details --> */}
                    <div class="mt-30">
                        <div class="container">
                            <h4 class="top-heading" >Stock Pile</h4>
                            <div class="mt-20">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="filter-box">
                                            <ul class="info-ui">
                                                <li>Dashboard</li>
                                            </ul>
                                        </div>
                                        <div class="inner-box mt-20">
                                            <div class="filter-panel">
                                                <h4 class="heading" ><Link to="/user-profile"><a class="">User Profile</a></Link></h4>
                                            </div>
                                            <div class="filter-panel">
                                                <h4 class="heading"> <Link to="/booking-orders"><a class="">Orders</a></Link></h4>
                                            </div>
                                            <div class="filter-panel">
                                                <h4 class="heading">Stock</h4>
                                                <p class=""> <Link to="/stock-pile"><a class="active">Stock Pile</a></Link></p>
                                                <p class=""> <Link to="/recent-details"><a class="">Recent Details</a></Link></p>
                                            </div>

                                        </div>
                                    </div>
                                    {/* <!-- col-md-4 End--> */}
                                    <div class="col-md-8">
                                        <div class="filter-box">
                                            <ul class="info-ui">
                                                <li>Stock Pile</li>
                                            </ul>
                                        </div>
                                        <div class="mt-20" >

                                        {stockpile.length > 0 ? (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Commodity</th>
                                                        <th>No of Bags</th>
                                                        <th>Warehouse</th>
                                                        <th>Actions</th>
                                                        <th>Quantity</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {stockpile.map(stock => (
                                                        <tr key={stock.alias_name}>
                                                            <td>
                                                                <div className="pile-img">
                                                                    <img src={stock.commodity_image} width="100%" alt={stock.alias_name} />
                                                                    <div className="img-label">{stock.alias_name}</div>
                                                                </div>
                                                            </td>
                                                            <td>{stock.balance_Bags}</td>
                                                            <td>No Info</td>
                                                            <td>
                                                                <button style={{ margin: '2px' }} className="btn btn-danger btn-xs">Sell</button>
                                                                <button style={{ margin: '2px' }} className="btn btn-danger btn-xs">Pledge</button>
                                                                <button style={{ margin: '2px' }} className="btn btn-danger btn-xs">Withdraw</button>
                                                                <button style={{ margin: '2px' }} className="btn btn-danger btn-xs">Rent</button>
                                                            </td>
                                                            <td>{stock.total_stock}</td>
                                                            <td>No Info</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <h4>No Record Found.</h4>
                                        )}
                                        

                                        </div>
                                    </div>
                                    {/* <!-- col-md-8 End--> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Home_Component_Our_Partner></Home_Component_Our_Partner>

                </div>
        )
    }
}

export default Stock_Pile
