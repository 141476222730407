import { Component } from 'react';
import * as React from "react";
import queryString from 'query-string';
import View_feedback from '../components/View_feedback';
import Add_feedback from '../components/Add_feedback';
import { Link } from "react-router-dom";
import { AsyncStorage } from 'AsyncStorage';

export class Warehouse_Details_Feedback_Tab extends Component {
    constructor(props) {
        super(props);
        //this.state = { isEmptyState: true };           
        this.state = {
            error: null,
            isLoaded: false,
            isFetching: false,
            isEmptyState: true,
            wh_id: this.props.wh_id,
            message: "",
            userid : ''
        };
        AsyncStorage.getItem('UserID').then((value) => {
            if(value){
               this.setState({userid: value});
            }            
        });

    }
    trigger_Edit_screen = () => {
        //alert(1);
        this.setState({
            ...this.state,
            isEmptyState: false,
            isAddTripState: true,
            message : ''
        })
    }
    trigger_comeback = () => {
        //alert(1);
        this.setState({
            ...this.state,
            isEmptyState: true,
            isAddTripState: false
        })
    }

    callbackFunction = (childData) => {
        this.setState({ message: childData })
    }



    render() {

        const { isFetching } = this.state;
        return (
            <div>
                <h1>{this.isEmpty}</h1>
                <div>
                    {this.state.isEmptyState && <div class="">
                        <div class="row">
                            <div class="col-md-4 col-sm-2 col-xs-2"></div>
                            <div class="col-md-4 col-sm-8 col-xs-8 mt-35 mb-20">
                                <div class="">
                                    {
                                        this.state.userid == '' ?
                                        <Link data-toggle="modal" id="projects"  data-target="#modal-signin">                            
                                            <a class="btn btn-primary  col-xs-2 btn-block" role="button"> Add Feedback </a>                           
                                        </Link>
                                        :                                      
                                          <a class="btn btn-primary  col-xs-2 btn-block" role="button" onClick={this.trigger_Edit_screen}>Add Feedback</a>                           
                                       
                                    }
                                    
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-2"></div>
                        </div>
                    </div>}
                    {this.state.message != '' ?
                        <div class="alert alert-success">
                            <p>{this.state.message}</p>
                        </div>
                        : ""
                    }
                    {this.state.isEmptyState && <View_feedback wh_id={this.state.wh_id} />}

                    {this.state.isAddTripState && <Add_feedback wh_id={this.state.wh_id} handler={this.trigger_comeback} parentCallback={this.callbackFunction} />}
                </div>

            </div>
        )
    }
}

export default Warehouse_Details_Feedback_Tab
