import React, { Component, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Warehouse_Details_Detail_Tab from './Warehouse_Details_Detail_Tab';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import { Redirect } from 'react-router';
import { GoogleComponent } from 'react-google-location';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import Loaderfn from './Loaderfn'
import ReactDOM from 'react-dom'
import { AsyncStorage } from 'AsyncStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faLocationDot } from '@fortawesome/free-solid-svg-icons';

//require("bootstrap/less/bootstrap.less");
//const API_KEY = "AIzaSyCZaiO-iS1Z8m2uMFnkuZ0BMwN9kd1m4qU";
//var storevalue = 'aaa';
var new_array = [];
var page = 0;

export class Warehouse_List_ListContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            stockdetails: [],
            warehouselist: [],
            filterlist: [],
            commodities: [],
            quantity: '',
            fromdate: '',
            todate: '',
            cg_id: null,
            hfwarehouseid: null,
            hfwarehousename: '',
            hfrc_id: null, //register customer id
            booking_date: '',
            req_status: 'Order Placement',
            order_type: 'Fixed Rent',
            hfClientID: null,
            client_mobile: '',
            isFetching: false,
            singlecommodity: [],
            istest: false,
            radioname: false,
            termscondition: false,
            isFetching: false,
            txtsearch: this.props.search,
            wh_type: this.props.wh_type,
            new_array_list: [],
            warehousedetails: [],
            isfetch_1: false,
            userid: '',
            user_mobileno: '',
            new_data: [],
            activePage: 1,
            alltotal_records: 0,
            warehouse_details_list: [],
            warehouse_details_list_all: [],
            lat: this.props.lat,
            lng: this.props.lng,
            state: this.props.state,
            city: this.props.city,
            i: 11,
            txtlocation: this.props.location,
            complete_list: [],
            filtered_list: []

        };
        this.setState({ txtlocation: this.props.location })
        this.myRef = React.createRef()
        var today = new Date();
        var getdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                this.setState({ userid: value });
            }

        });
        AsyncStorage.getItem('username').then((value) => {
            if (value) {
                this.setState({ user_mobileno: value });
            }

        });
    }


    state = {
        startDate: new Date()
    };

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };

    componentDidMount(props) {
        this.getdata();

    }
    getdata = () => {

        var lat = this.state.lat; //using props
        var lang = this.state.lng; // using props
        var distance = "";

        //alert(lat)
        /// alert({lat,lang})
        if (lat == undefined)
            lat = "0";
        if (lang == undefined)
            lang = "0";
        if (lat == '' || lang == '')
            distance = "";
        else
            distance = "200"
        fetch("https://agrigates.com/v2/bmsnew/warehouse/getlist", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "currentLatitude": lat.toString(),
                        "currentLongitude": lang.toString(),
                        "distance": distance
                        // "showInSpaceDiscovery": "1"
                    },
                    // "offset": page, 
                    "limit": 100000000
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                if ((this.state.wh_type != '' || this.state.wh_type != null) && this.state.wh_type != undefined) {
                    this.setState({
                        isLoaded: true,
                        filtered_list: result.data.filter(x => String(x.warehouse_subtype_name).toLowerCase().includes(this.state.wh_type.toLowerCase())),
                        complete_list: result.data,
                        warehouselist: result.data,
                        filterlist: result.data,
                        isFetching: true
                    });
                }
                else if (this.state.txtsearch != null) {
                    this.setState({
                        isLoaded: true,
                        filtered_list: result.data.filter(x => (String(x.warehouse_name).toLowerCase().includes((this.state.txtsearch).toLowerCase())
                            || String(x.warehouse_capacity).includes(this.state.txtsearch)
                            || String(x.warehouse_address).toLowerCase().includes((this.state.txtsearch).toLowerCase())
                            || String(x.location_name).toLowerCase().includes((this.state.txtsearch).toLowerCase()))),
                        complete_list: result.data,
                        warehouselist: result.data,
                        filterlist: result.data,
                        isFetching: true
                    });
                }
                else {
                    {
                        this.setState({
                            isLoaded: true,
                            filtered_list: result.data,
                            complete_list: result.data,
                            warehouselist: result.data,
                            filterlist: result.data,
                            isFetching: true
                        });
                    }
                }

            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );

        //this.perform_filters("Silo");

    }



    componentDidMount_commodities(id) {
        this.setState({
            quantity: '',
            fromdate: '',
            todate: '',
            termscondition: false
        });

        $('#termscondition').prop('checked', false);
        if (this.state.userid == '') {
            window.location = '/';
        }
        //first api for list

        fetch("https://agrigates.com/v2/api/rateCards/get", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "whId": id
                    },
                    "select": [],
                    "join": {
                        "commodityGroup": true
                    },
                    "sort": {},
                    "offset": 0,
                    "limit": 100
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    stockdetails: result.data,
                    isFetching: true
                });

                console.log(result.data);
                if (this.state.stockdetails.length < 1) {
                    $("#btnbookspace").attr("disabled", true);
                }
                else {
                    $("#btnbookspace").attr("disabled", false);
                }
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    perform_filters(props) {
        var arr = [];
        $("input:checkbox[name=type]:checked").each(function () {
            arr.push($(this).val());
        });
        var WarehouseTypeArr = arr.toString().split(',');
        if (props == null) {

            this.setState({
                isLoaded: true,
                filtered_list: this.state.complete_list,
                isFetching: true

            });
        }
        else {

            let filtered_whlist = [];

            if (WarehouseTypeArr.length == 0 || WarehouseTypeArr == "") {
                filtered_whlist = this.state.complete_list;
            }
            else {
                for (let i = 0; i < WarehouseTypeArr.length; i++) {
                    //if (result.data.filter(x => x.warehouse_subtype_name == WarehouseTypeArr[i])) {
                    filtered_whlist = filtered_whlist.concat(this.state.complete_list.filter(x => String(x.warehouse_subtype_name).toLowerCase().includes(WarehouseTypeArr[i].toLowerCase())));
                    //}
                }
            }
            this.setState({
                isLoaded: true,
                filtered_list: filtered_whlist,
                isFetching: true
            });



        }
    }

    componentDidMount_cg_info(id) {
        //alert(this.state.isFetching);
        if (id != '') {
            this.setState({ radioname: true });
            if (this.state.isFetching == true) {

                this.state.singlecommodity = this.state.stockdetails.filter(x => x.rc_cg_id == id);

            }
        }

    }


    onChange = (e) => {

        this.setState({ [e.target.name]: e.target.value });
    }
    handleCheck = (e) => {
        this.setState({ termscondition: !this.state.termscondition });
    }
    //book warehouse
    onSubmit = (e) => {

        if (this.state.radioname == false) {
            e.preventDefault();
            //alert('Please choose commodity');
            debugger
            $("#showmsg").addClass("alert alert-danger").text("Please choose commodity");
        }
        else if (this.state.quantity == '') {
            e.preventDefault();
            $("#showmsg").addClass("alert alert-danger").text("Please Enter Quantity");
            // alert('Please Enter Quantity');
        }
        else if (this.state.fromdate > this.state.todate) {
            e.preventDefault();
            $("#showmsg_posted").addClass("alert alert-danger").text("From Date should be less than from To Date.");
        }
        else if (this.state.fromdate == '') {
            e.preventDefault();
            $("#showmsg").addClass("alert alert-danger").text("Please Enter From Date");
            //alert('Please Enter From Date');
        }
        else if (this.state.todate == '') {
            e.preventDefault();
            $("#showmsg").addClass("alert alert-danger").text("Please Enter To Date");
            //alert('Please Enter To Date');
        }
        else if (!this.state.termscondition) {
            e.preventDefault();
            $("#showmsg").addClass("alert alert-danger").text("Please check terms and conditions");
            //alert('Please check terms and condition');
        }
        else {
            //$("#btnbookspace").attr("disabled", false);
            e.preventDefault();
            const Request = {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'session': 'pass'
                },
                body: JSON.stringify(
                    {
                        "document": {
                            "od_wh_id": this.state.singlecommodity[0].rc_wh_id,
                            "od_wh_name": this.state.singlecommodity[0].rc_wh_name,
                            "od_rc_id": this.state.singlecommodity[0].rc_id, // registered customer
                            "od_cg_id": this.state.singlecommodity[0].rc_cg_id, // commodity id
                            "od_from_date": this.state.fromdate,
                            "od_to_date": this.state.todate,
                            "od_tnc_time": this.state.booking_date,
                            "od_req_status": this.state.req_status,
                            "od_quantity": this.state.quantity,
                            "od_order_type": this.state.order_type,
                            "od_final_order_price": this.state.singlecommodity[0].rc_min_rate,
                            "od_client_id": this.state.userid, //logged in user
                            "od_client_mobile_no": this.state.user_mobileno, //logged in user mobile no
                            "od_created_at": this.state.getdate,
                            "od_created_by": this.state.userid
                        }
                    })
            }
            fetch("https://agrigates.com/v2/api/orders", Request)
                .then(response => response.json())
                .then(result => {
                    this.setState({
                        //Successmsg: (result)

                    });
                    alert('Warehouse Booked');
                    window.location.href = '/#/booking-orders';
                    window.$('#get-details').modal('hide');
                    //console.log(result);
                },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    })

        }
    }

    perform_Explore_near_by = (lat, lng) => {
        //alert(lat);
        fetch("https://agrigates.com/v2/bmsnew/warehouse/getlist", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "currentLatitude": lat,
                        "currentLongitude": lng,
                        "distance": "50"
                        // "showInSpaceDiscovery": "1"
                    }, "offset": 0, "limit": 1000
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {


                this.setState({
                    isLoaded: true,
                    filtered_list: result.data,
                    filterlist: result.data,
                    isFetching: true
                });

                // result.data.map(item=>this.getwarehouse_detail(item.warehouse_id, result.data))



                // console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }


    perform_search = (props) => {

        this.setState({
            state: $("#state-span").text(),
            city: $("#city-span").text()
        });
        //please dont remove this line, this is handle location textbox
        this.setState({ txtlocation: $("#location-snap").text() })
        $("#cold").prop("checked", false);
        $("#dry").prop("checked", false);
        $("#silo").prop("checked", false);

        //unset filterd checkbox
        // this.setState({
        //     //wh_type : 'no filter',
        //     //type : ""
        // }) ;
        // var lat = this.state.lat;
        // var lang = this.state.lng;
        var lat = $("#lat-span").text();
        var lang = $("#lon-span").text();
        var distance = "";
        if (lat == undefined)
            lat = "0";
        if (lang == undefined)
            lang = "0";
        if (lat == '' || lang == '')
            distance = "";
        else
            distance = "200"
        fetch("https://agrigates.com/v2/bmsnew/warehouse/getlist", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "currentLatitude": lat.toString(),
                        "currentLongitude": lang.toString(),
                        "distance": distance
                        // "showInSpaceDiscovery": "1"
                    }, "offset": 0, "limit": 5000000
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {

                if (this.state.txtsearch == '' || this.state.txtsearch == null) {
                    this.setState({
                        isLoaded: true,
                        filtered_list: result.data,
                        complete_list: result.data,
                        warehouselist: result.data,
                        filterlist: result.data,
                        isFetching: true
                    });

                }
                else {
                    this.setState({
                        isLoaded: true,
                        filtered_list: result.data.filter(x => String(x.warehouse_name).toLowerCase().includes((this.state.txtsearch).toLowerCase()) || String(x.warehouse_capacity).includes(this.state.txtsearch) || String(x.warehouse_address).toLowerCase().includes((this.state.txtsearch).toLowerCase()) || String(x.location_name).toLowerCase().includes((this.state.txtsearch).toLowerCase())),
                        complete_list: result.data.filter(x => String(x.warehouse_name).toLowerCase().includes((this.state.txtsearch).toLowerCase()) || String(x.warehouse_capacity).includes(this.state.txtsearch) || String(x.warehouse_address).toLowerCase().includes((this.state.txtsearch).toLowerCase()) || String(x.location_name).toLowerCase().includes((this.state.txtsearch).toLowerCase())),
                        warehouselist: result.data,
                        filterlist: result.data,
                        isFetching: true
                    });

                }
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );


    }
    twoCalls = e => {
        this.onChange(e)
        this.initMap();
    }
    initMap = () => {
        var input = document.getElementById('searchMapInput');
        /* eslint-disable no-undef */
        var autocomplete = new google.maps.places.Autocomplete(input);
        var aa = "";

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            //console.log(place.adr_address)
            let div = document.createElement('div');
            div.innerHTML = place.adr_address;
            let cityname = div.getElementsByClassName('locality')[0].innerHTML;
            let statename = div.getElementsByClassName('region')[0].innerHTML;
            document.getElementById('city-span').innerHTML = cityname;
            document.getElementById('state-span').innerHTML = statename;
            document.getElementById('location-snap').innerHTML = place.formatted_address;
            document.getElementById('lat-span').innerHTML = place.geometry.location.lat();
            document.getElementById('lon-span').innerHTML = place.geometry.location.lng();

        });


    }
    loadmorehandle = (e) => {

        this.setState({ i: this.state.i + 10 })
        //alert(1)
        // let bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5;
        // if (bottom) {
        //     // loadmore : false  
        //     setTimeout(function () { //Start the timer
        //         this.setState({ loadmore: true }) //After 1 second, set render to true
        //     }.bind(this), 1000)
        //     // i = this.state.i + 10;
        //     this.setState({ i: this.state.i + 2 })
        //     //this.getdata(page);
        // }
    }

    setLocalStorage() {
        localStorage.setItem('lastscreen', 'bookspacefromlist');
    }

    render() {
        const { scrollTop } = this.state;
        var { error, isLoaded, warehouselist, complete_list, filtered_list, filterlist, stockdetails, isFetching, singlecommodity, warehousedetails, new_data, warehouse_details_list_all, warehouse_details_list } = this.state;

        const uniqueArrfilters = [... new Set(filterlist.map(data => data.warehouse_subtype_name))];

        var $checkboxes_warehouse_type = $('#warehouse_type_filters  input[type="checkbox"]');
        var $checkboxes_aminities_features = $('#feature_aminitites_filters  input[type="checkbox"]');
        var countCheckedCheckboxes_warehouseType = 0;
        var countCheckedCheckboxes_aminites = 0;
        countCheckedCheckboxes_warehouseType = $checkboxes_warehouse_type.filter(':checked').length;
        countCheckedCheckboxes_aminites = $checkboxes_aminities_features.filter(':checked').length;
        $('#totalchecked').text(countCheckedCheckboxes_warehouseType + countCheckedCheckboxes_aminites);


        return (
            isFetching == false ? <Loaderfn></Loaderfn> :
                <div>
                    <div class="top-section inner-form">
                        <div class="container" >
                            <div class="row">
                                <div class="">
                                    <div class="col-md-3 col-sm-5 ">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Search for warehouse" name="txtsearch" value={this.state.txtsearch} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-5 ">
                                        <div class="form-group">
                                            {/* <input type="text" class="form-control" placeholder="Search location" /> */}
                                            <input id="searchMapInput" class="form-control " type="text" onChange={this.twoCalls} placeholder="Enter a location" name="txtlocation" value={this.state.txtlocation} />
                                            <ul id="geoData" hidden>
                                                <li>Full Address: <span id="location-snap">{this.props.location}</span></li>
                                                <li>Latitude: <span id="lat-span">{this.props.lat}</span></li>
                                                <li>Longitude: <span id="lon-span">{this.props.lng}</span></li>
                                                <li>State: <span id="state-span">{this.props.state}</span></li>
                                                <li>City: <span id="city-span">{this.props.city}</span></li>
                                                <span ref="myInput" id="myInput"></span>
                                            </ul>


                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-2">
                                        <div class="form-group">
                                            <a onClick={this.perform_search} class="btn btn-danger" role="button"><FontAwesomeIcon icon={faSearch} /> Search</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-30">
                        <div class="container">
                            <div class="">
                                <div class="row">

                                    <div class="col-md-4">
                                        <div class="filter-box">
                                            <div class="pull-right label label-danger" id="totalchecked">0</div>
                                            Filters
                                        </div>
                                        <div class="inner-box mt-20">
                                            <div class="filter-panel" id="warehouse_type_filters">
                                                <h4 class="heading">Warehouse Type</h4>

                                                <div class="checkbox">
                                                    <label><input type="checkbox" id="dry" defaultChecked={this.state.wh_type == "Dry Warehouse" ? true : false} name="type" onClick={() => this.perform_filters("Dry Warehouse")} value="Dry Warehouse" />Dry Warehouse</label>
                                                </div>
                                                <div class="checkbox">
                                                    <label><input type="checkbox" id="cold" defaultChecked={this.state.wh_type == "Cold Storage" ? true : false} name="type" onClick={() => this.perform_filters("Cold Storage")} value="Cold Storage" />Cold Storage</label>
                                                </div>
                                                <div class="checkbox">
                                                    <label><input type="checkbox" id="silo" defaultChecked={this.state.wh_type == "Silo" ? true : false} name="type" onClick={() => this.perform_filters("Silo")} value="Silo" />Silo Warehouse</label>
                                                </div>
                                            </div>
                                            <div class="filter-panel" id="feature_aminitites_filters" hidden>
                                                <h4 class="heading">Features and Amenties</h4>
                                                <div class="checkbox">
                                                    <label><input type="checkbox" value="" onClick={() => this.componentDidMount_filters()} />Security</label>
                                                </div>
                                                <div class="checkbox">
                                                    <label><input type="checkbox" value="" onClick={() => this.componentDidMount_filters()} />Stack Card</label>
                                                </div>
                                                <div class="checkbox">
                                                    <label><input type="checkbox" value="" onClick={() => this.componentDidMount_filters()} />Firefighting Arrangements</label>
                                                </div>
                                                <div class="checkbox">
                                                    <label><input type="checkbox" value="" onClick={() => this.componentDidMount_filters()} />Weighbridge Facilities</label>
                                                </div>
                                                <div class="checkbox">
                                                    <label><input type="checkbox" value="" onClick={() => this.componentDidMount_filters()} />Power Backup</label>
                                                </div>
                                                <div class="checkbox">
                                                    <label><input type="checkbox" value="" onClick={() => this.componentDidMount_filters()} />Labour Avallability</label>
                                                </div>
                                                <div class="checkbox">
                                                    <label><input type="checkbox" value="" onClick={() => this.componentDidMount_filters()} />Grading Sorting Facility</label>
                                                </div>
                                                <div class="checkbox">
                                                    <label><input type="checkbox" value="" onClick={() => this.componentDidMount_filters()} />Unloading Platform</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    {/* <!-- col-md-4 End--> */}



                                    {isFetching == false ? (
                                        <div class="col-md-8">Loading...</div>
                                    ) : (

                                        <div class="col-md-8">
                                            <div class="warehouse-box">
                                                <div class="inline-block">

                                                    {this.state.txtlocation == null ?
                                                        this.state.txtsearch == null ?
                                                            <ul class="ag-breadcrumb">
                                                                <li><a>All</a></li>
                                                            </ul>
                                                            :
                                                            filtered_list.length > 0 ?
                                                                <ul class="ag-breadcrumb">
                                                                    <li><a>All</a></li>
                                                                    <li>{filtered_list[0].state_name}</li>
                                                                    <li>{filtered_list[0].location_name}</li>
                                                                </ul>
                                                                : ""
                                                        :
                                                        <div>
                                                            {
                                                                filtered_list.length > 0 ?
                                                                    <div>
                                                                        {<div>
                                                                            <ul class="ag-breadcrumb">
                                                                                <li><a>All</a></li>
                                                                                <li>{this.state.state}</li>
                                                                                <li>{this.state.city}</li>
                                                                            </ul>
                                                                        </div>
                                                                        }

                                                                    </div> : <ul class="ag-breadcrumb">
                                                                        <li><a>All</a></li>
                                                                        <li>{this.state.state}</li>
                                                                        <li>{this.state.city}</li>
                                                                    </ul>
                                                            }

                                                        </div>
                                                    }


                                                    {/* <p>Warehouse on rent in ,  </p> */}
                                                    {this.state.txtlocation == null ?
                                                        this.state.txtsearch == null ?
                                                            <ul class="ag-breadcrumb">
                                                                <li><a>Warehouse on rent in</a></li>
                                                            </ul>
                                                            :
                                                            filtered_list.length > 0 ?
                                                                <p>Warehouse on rent in &nbsp;
                                                                    {filtered_list[0].state_name}&nbsp;
                                                                    {filtered_list[0].location_name}</p>

                                                                : ""
                                                        :
                                                        <div>
                                                            {
                                                                filtered_list.length > 0 ?
                                                                    <div>
                                                                        {<div>
                                                                            <p>
                                                                                Warehouse on rent in &nbsp;
                                                                                {this.state.state} &nbsp;
                                                                                {this.state.city}
                                                                            </p>
                                                                        </div>
                                                                        }

                                                                    </div> :
                                                                    <p>
                                                                        Warehouse on rent in
                                                                    </p>
                                                            }

                                                        </div>
                                                    }
                                                </div>

                                                <h4 class="pull-right"> ({this.state.filtered_list.length})</h4>

                                            </div>
                                            {/* <!-- warehouse-box End--> */}

                                            {/* <!-- Rent-box --> */}

                                            <div>
                                                {

                                                    filtered_list.length > 0 ?
                                                        filtered_list.slice(0, this.state.i).map(warehouse =>
                                                        (

                                                            <div class="rent-box mt-20 wow fadeIn">
                                                                <input type="hidden" name="hfwarehousename" onLoad={this.onChange} value={this.state.hfwarehousename} />
                                                                {/* <!-- First Box --> */}
                                                                <div class="rentbox-title  rentb-bottom">
                                                                    <h4 class="heading">{warehouse.warehouse_name}</h4>

                                                                    <p class="location-dtl">
                                                                        <span class="rent-add mr-10">{warehouse.warehouse_address} </span>
                                                                        <a style={{ cursor: 'pointer' }} class="nearby">
                                                                            <FontAwesomeIcon icon={faLocationDot} class=" mr-10" style={{ width: "8px" }} />
                                                                            <span style={{ width: 'Fixed' }} class="location" onClick={() => this.perform_Explore_near_by(warehouse.lat, warehouse.lng)}>Explore Nearby</span></a>
                                                                    </p>
                                                                </div>
                                                                {/* <!-- First Box End--> */}
                                                                {/* <!-- Sekcond Box --> */}
                                                                <div class="rentbox-title text-center  rentb-bottom">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-4 " hidden>
                                                                            <h4 class="heading">{parseFloat(warehouse.breadth * warehouse.length).toFixed(2)} </h4>
                                                                            <span>Area</span>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-4 ">
                                                                            <h4 class="heading">{warehouse.warehouse_capacity}</h4>
                                                                            <span>Capacity</span>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-4 ">
                                                                            <h4 class="heading">{warehouse.warehouse_subtype_name} </h4>
                                                                            <span>Type</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- Second Box End--> */}

                                                                {/* <!-- Rent-dtl-form --> */}
                                                                <div class="rent-dtl-form">
                                                                    <div class="rent-img inline-block">
                                                                        {warehouse.images.length > 0 ? <img src={warehouse.images[0].file_path}></img> : <img src={require('../assests/images/demowarehouse.jpg')} />}

                                                                        <div class="img-label">{warehouse.warehouse_name}</div>
                                                                    </div>

                                                                    {/* <!-- Modal Get Details--> */}


                                                                    <div class="warehouse-detail-box  width30 inline-block">
                                                                        <div class="whbox">
                                                                            <div class="fgroup bb-line">
                                                                                <span>Location-State/City:</span>
                                                                                <p>{warehouse.state_name}/{warehouse.location_name}</p>
                                                                            </div>
                                                                            <div class="fgroup bb-line" hidden>
                                                                                <span>Insurance:</span>
                                                                                <p>{warehouse.is_insurance_warehouse_available == 0 ? 'No' : 'Yes'}</p>
                                                                            </div>
                                                                            <div class="fgroup bb-line" hidden>
                                                                                <span>Structural Properties:</span>
                                                                                <p>{warehouse.structural_insurance}</p>
                                                                            </div>
                                                                            <div class="fgroup">
                                                                                <span>License:</span>
                                                                                <p>{warehouse.is_licenced == 1 ? 'Yes' : 'No'}</p>
                                                                            </div>
                                                                        </div>
                                                                        {/* <a href="#" class="btn btn-danger btn-block" role="button" data-toggle="modal" data-target="#get-details">Get Details</a> */}
                                                                        <Link to={`/warehouse-details?id=${warehouse.warehouse_id}`} ><a class="btn btn-danger btn-block" role="button" >Get Details</a></Link>
                                                                    </div>
                                                                    <div class="warehouse-detail-box width30 inline-block" hidden>
                                                                        <div class="whbox">
                                                                            <div class="fgroup bb-line" hidden>
                                                                                <span>Amenities:</span>
                                                                                <p>No Information</p>
                                                                            </div>
                                                                            <div class="fgroup bb-line" hidden>
                                                                                <span>Operation Management:</span>
                                                                                <p>No Information</p>
                                                                            </div>
                                                                            <div class="fgroup bb-line" hidden>
                                                                                <span>Insurance:</span>
                                                                                <p>No Information</p>
                                                                            </div>
                                                                            <div class="fgroup" hidden>
                                                                                <span>Past History:</span>
                                                                                <p>Not Avallable</p>
                                                                            </div>
                                                                            &nbsp;
                                                                        </div>
                                                                        {
                                                                            this.state.userid == '' ?
                                                                                <a style={{ cursor: 'pointer' }} class="btn btn-danger btn-block" role="button" data-toggle="modal" data-target="#modal-signin" onClick={() => this.setLocalStorage()}>Book Space</a>
                                                                                :
                                                                                <a style={{ cursor: 'pointer' }} class="btn btn-danger btn-block" role="button" data-toggle="modal" data-target="#get-details" onClick={() => this.componentDidMount_commodities(warehouse.warehouse_id)}>Book Space</a>

                                                                        }
                                                                    </div>


                                                                    {/* <!-- Footer Buttons --> */}
                                                                    <div class="mt-20">
                                                                        <Link to={`/warehouse-details?id=${warehouse.warehouse_id}&tab=tab-11`}><button class="btn mr-10 wr-dtl whbtn1">Details</button></Link>
                                                                        <Link to={`/warehouse-details?id=${warehouse.warehouse_id}&tab=tab-22`}><button class="btn mr-10 wr-dtl whbtn1">Surveillance</button></Link>
                                                                        <Link to={`/warehouse-details?id=${warehouse.warehouse_id}&tab=tab-33`}><button class="btn mr-10 wr-dtl whbtn1">Stock Details</button></Link>
                                                                        <Link to={`/warehouse-details?id=${warehouse.warehouse_id}&tab=tab-44`}><button class="btn mr-10 wr-dtl whbtn1">Feedback</button></Link>
                                                                        {/* <a href="" class="mr-10 wr-dtl"><img src={require('../assests/images/thumsup.png')} /></a>
                                            <a href="" class="mr-10 wr-dtl"><img src={require('../assests/images/chat.png')} /></a> */}
                                                                    </div>
                                                                    {/* <!-- Footer Buttons End--> */}
                                                                </div>
                                                                {/* <!--Rent-dtl-form End--> */}
                                                            </div>
                                                        )) :

                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <h5>No Record Found.</h5>
                                                            </div>
                                                        </div>

                                                }
                                                {/* {this.state.loadmore == false ? <Loaderfn></Loaderfn> : ""} */}
                                            </div>
                                            <br /><br /><br />
                                            {
                                                this.state.filtered_list.length > this.state.i ?
                                                    <div class="col-md-12 loadmore">

                                                        <a class="btn btn-danger" onClick={this.loadmorehandle}>Load More</a>

                                                    </div> : ""
                                            }

                                            {/* <Pagination
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={5}
                                                totalItemsCount={this.state.warehouse_details_list.length + 20}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange.bind(this)}
                                            /> */}
                                            {/* <!-- Rent-box End--> */}


                                            {/* <!-- Rent-box --> */}

                                            <div class="modal fade sign-popup" id="get-details">

                                                <form onSubmit={this.onSubmit}>
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span></button>
                                                                <h4 class="modal-title"><b>Booking Details</b></h4>
                                                            </div>
                                                            <div class="modal-body">
                                                                {isFetching == false ? (
                                                                    <div>Loading...</div>
                                                                ) : (
                                                                    <div class="row">
                                                                        {
                                                                            stockdetails.length < 1 ? <div>No Commodity Available</div> :
                                                                                stockdetails.map(item => (
                                                                                    <div class="col-md-4 mt-10">
                                                                                        <label className="labl BDinner-box active" style={{ minHeight: '110px' }}>
                                                                                            {/* <div class="BDinner-box active"> */}
                                                                                            <input type="radio" name="radioname" value={this.state.radioname} onClick={() => this.componentDidMount_cg_info(item.rc_cg_id)} />
                                                                                            <div class="similar-warehouse-list active" style={{ padding: '0px' }} >
                                                                                                <div class="BD-img inline-block">
                                                                                                    <img src={item.cg_Link} />
                                                                                                    <div class="img-label">{item.cg_Name}</div>
                                                                                                </div>
                                                                                                <div class="BD-content inline-block ">
                                                                                                    <div>
                                                                                                        <h4 class="heading">{item.cg_Name}</h4>
                                                                                                        <p class="pile-dtl">Price:<b>Rs.{item.rc_min_rate} Per MT</b></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* </div> */}
                                                                                        </label>
                                                                                    </div>


                                                                                ))
                                                                        }

                                                                    </div>
                                                                )
                                                                }
                                                                <div class="uprofile-form">

                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">

                                                                                <input type="text" class="form-control" onChange={event => this.setState({ quantity: event.target.value.replace(/\D/, '') })} placeholder="Quantity" name="quantity" value={this.state.quantity} />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <input type="date" class="form-control" onChange={this.onChange} placeholder="Start Date" name="fromdate" value={this.state.fromdate} />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <input type="date" class="form-control" onChange={this.onChange} placeholder="End Date" name="todate" value={this.state.todate} />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="signin checkbox">
                                                                                    <label><input type="checkbox" onChange={this.handleCheck} defaultChecked={this.state.termscondition} id="termscondition" name="termscondition" />Terms and Conditions</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div id="showmsg"></div>
                                                            </div>
                                                            <div class="modal-footer text-center">
                                                                <button type="submit" id="btnbookspace" class="btn btn-danger" >Book Space </button>
                                                            </div>
                                                        </div>
                                                        {/* <!-- /.modal-content --> */}
                                                    </div>
                                                </form>

                                            </div>

                                            {/* <!-- /.modal --> */}

                                            {/* <!-- Rent-box End--> */}
                                            <div class="modal fade sign-popup" id="book-space">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">×</span></button>
                                                            <h4 class="modal-title"><b>Booking Details</b></h4>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div id="calendar-container">
                                                                <h1 id="calendar-title">
                                                                    <div class="cbtn left"></div>
                                                                    <span>April, 2019</span>
                                                                    <div class="cbtn right"></div>
                                                                </h1>
                                                                <table id="calendar-table">
                                                                    <tr>
                                                                        <th>Sun</th>
                                                                        <th>Mon</th>
                                                                        <th>Tue</th>
                                                                        <th>Wed</th>
                                                                        <th>Thu</th>
                                                                        <th>Fri</th>
                                                                        <th>Sat</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td>1</td>
                                                                        <td>2</td>
                                                                        <td>3</td>
                                                                        <td>4</td>
                                                                        <td>5</td>
                                                                        <td>6</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>7</td>
                                                                        <td>8</td>
                                                                        <td>9</td>
                                                                        <td>10</td>
                                                                        <td>11</td>
                                                                        <td>12</td>
                                                                        <td>13</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>14</td>
                                                                        <td>15</td>
                                                                        <td>16</td>
                                                                        <td>17</td>
                                                                        <td>18</td>
                                                                        <td>19</td>
                                                                        <td>20</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>21</td>
                                                                        <td>22</td>
                                                                        <td>23</td>
                                                                        <td>24</td>
                                                                        <td>25</td>
                                                                        <td>26</td>
                                                                        <td>27</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>28</td>
                                                                        <td>29</td>
                                                                        <td>30</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>

                                                                </table>
                                                                <p id="date-text"></p>
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-danger pull-left" data-dismiss="modal">Close</button>
                                                            <button type="button" class="btn btn-danger" >OK</button>
                                                        </div>
                                                    </div>
                                                    {/* <!-- /.modal-content --> */}
                                                </div>
                                                {/* <!-- /.modal-dialog --> */}
                                            </div>


                                        </div>

                                    )}
                                    {/* <!-- col-md-8 End--> */}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
        )
    }
}

export default Warehouse_List_ListContent


$(document).ready(function () {

    // alert(1)
    //document.getElementById('searchMapInput').innerText  = "adsfasd";
    //$("#searchMapInput").val("Glenn Quagmire");

});