import React, { Component } from 'react';
import { Link, BrowserRouter } from "react-router-dom";

export class Warehouse_Details_Similar_Warehouse_List extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            lng: this.props.lng,
            lat: this.props.lat,
            similarwarehouselst: [],
            wh_id: this.props.wh_id,
            count_warehouse: 0,
            i: 6
        });
        //alert(2)
    }
    componentDidMount(props) {


        //alert(this.props.wh_id);
        fetch("https://agrigates.com/v2/bmsnew/warehouse/getlist", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "currentLatitude": this.props.lat,
                        "currentLongitude": this.props.lng,
                        "distance": "50",
                        "showInSpaceDiscovery": "1"
                    }, "offset": 0, "limit": 500
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {

                this.setState({
                    isLoaded: true,
                    similarwarehouselst: result.data,
                    isFetching: true,
                    count_warehouse: result.data.length
                });


                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    getmore = () => {
        this.setState({ i: this.state.i + 3 })
    }
    refresh(id) {
        // this.componentDidMount();
        window.location = `/#/warehouse-details?id=${id}`;
        window.location.reload(false);
    }
    render() {
        //alert(this.props.lng);
        const { similarwarehouselst } = this.state;
        return (
            <div>
                <div class="similar-warehouse ">
                    {
                        similarwarehouselst.slice(0, this.state.i).map(item => (
                            item.warehouse_id == this.state.wh_id ? null :
                                // eslint-disable-next-line no-restricted-globals
                                <a style={{ cursor: 'pointer' }} onClick={() => this.refresh(item.warehouse_id)}>
                                    <div>

                                        <div class="similar-warehouse-list mt-20 wow fadeIn">
                                            <div class="sw-img inline-block">
                                                {/* <img src={require('../assests/images/12.jpg')} /> */}
                                                {
                                                    item.images.length > 0
                                                        ? <img src={item.images[0].file_path} />
                                                        : <img src={require('../assests/images/demowarehouse2.jpg')} />
                                                }
                                                <div class="img-label">{item.warehouse_name}</div>
                                            </div>
                                            <div class="sw-content inline-block mt-10">
                                                <div >
                                                    <div class="sw-title">{item.warehouse_name}</div>
                                                    <span >{item.location_name}</span>
                                                </div>
                                                <div class="mt-20">
                                                    <div class="sw-title">{item.availableCapacity}sqft</div>
                                                    <span>{item.distance} km away</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sw-divider"></div>
                                    </div>
                                </a>

                        ))
                    }
                    {/* <div class="mt-30 text-center"><a class="btn btn-danger" role="button" onClick={()=>this.getmore()}>More Warehouses</a></div> */}


                    {
                        this.state.similarwarehouselst.length > this.state.i ?
                            <div class="mt-30 text-center"><a class="btn btn-danger" role="button" onClick={() => this.getmore()}>More Warehouses</a></div>
                            : ""
                    }
                </div>

            </div>
        )
    }
}

export default Warehouse_Details_Similar_Warehouse_List
