import React, { Component } from 'react';
import $ from 'jquery';

export class Home_Component_Our_Partners extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            error: null,
            isLoaded: false,
            partners: [],
            isFetching: false
        }
        this.Bankers();

    }

    Bankers() {
        // alert(1);
        fetch("https://agrigates.com/v2/api/partnerss/get", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "type": "Bankers",
                        "active": "1"
                    },
                    "select": [],
                    "join": {},
                    "sort": {},
                    "offset": 0,
                    "limit": 100
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {

                this.setState({
                    isLoaded: true,
                    partners: result.data,
                    isFetching: true
                });
                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    Banks() {

        fetch("https://agrigates.com/v2/api/partnerss/get", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "type": "Banks",
                        "active": "1"
                    },
                    "select": [],
                    "join": {},
                    "sort": {},
                    "offset": 0,
                    "limit": 100
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {

                this.setState({
                    isLoaded: true,
                    partners: result.data,
                    isFetching: true
                });
                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    Customers() {

        fetch("https://agrigates.com/v2/api/partnerss/get", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "type": "Customers",
                        "active": "1"
                    },
                    "select": [],
                    "join": {},
                    "sort": {},
                    "offset": 0,
                    "limit": 100
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {

                this.setState({
                    isLoaded: true,
                    partners: result.data,
                    isFetching: true
                });
                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    render() {
        var { error, isLoaded, isFetching, partners } = this.state;

        return (
            <div>
                <div className="our-partners ptop-50 wow fadeIn">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 class="heading1">Our partners </h2>
                                <div className="tab-container">

                                    <ul className="tabs">
                                        <li className="tab-link current" data-tab="tab-1" onClick={() => this.Bankers()}>Bankers</li>
                                        <li className="tab-link" data-tab="tab-2" onClick={() => this.Banks()}>Banks</li>
                                        <li className="tab-link" data-tab="tab-3" onClick={() => this.Customers()}>Customers</li>
                                    </ul>
                                    <div id="tab-1" className="tab-content current">
                                        {partners.map(item => (
                                            <a href={item.partner_website} target="_blank" class="partnerbox">
                                                <span class="imgbox">
                                                    <img src={item.partner_logo} alt={item.partner_name} />
                                                </span>
                                                <span class="name">{item.partner_name}</span>
                                            </a>
                                        ))}
                                    </div>

                                    <div id="tab-2" className="tab-content" >
                                        {partners.map(item => (
                                            <a href={item.partner_website} target="_blank" class="partnerbox">
                                                <span class="imgbox">
                                                    <img src={item.partner_logo} alt={item.partner_name} />
                                                </span>
                                                <span class="name">{item.partner_name}</span>
                                            </a>
                                        ))}</div>
                                    <div id="tab-3" className="tab-content">
                                        {partners.map(item => (
                                            <a href={item.partner_website} target="_blank" class="partnerbox">
                                                <span class="imgbox">
                                                    <img src={item.partner_logo} alt={item.partner_name} />
                                                </span>
                                                <span class="name">{item.partner_name}</span>
                                            </a>
                                        ))}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home_Component_Our_Partners


$(document).on('click', 'ul.tabs li', function () {


    // $('ul.tabs li').click(function () {

    var tab_id = $(this).attr('data-tab');
    var tabdetail = '';
    //alert(tab_id);        
    $("#tabdetail").text(tabdetail);
    $('ul.tabs li').removeClass('current');
    $('.tab-content').removeClass('current');

    $(this).addClass('current');
    $("#" + tab_id).addClass('current');
    // })

})
