import React, { Component } from 'react';
import Warehouse_Details_RightSide_PostedOn from '../components/Warehouse_Details_RightSide_PostedOn';
import Warehouse_Details_MainContent from '../components/Warehouse_Details_MainContent';
import Warehouse_List_Header_Search from '../components/Warehouse_List_Header_Search';
import $ from 'jquery';
import queryString from 'query-string';

export class Warehouse_Details extends Component {
    constructor(props) {
        super(props);
        
        // eslint-disable-next-line no-restricted-globals
        //const value = queryString.parse(location.search);
        
        // eslint-disable-next-line no-restricted-globals
        // const params = new URLSearchParams(); 
        // const tags = params.get('id');
        // alert(tags);

        //return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    
    render() {
        //alert(new URLSearchParams(this.props.location.search).get("id"));
        const id = new URLSearchParams(this.props.location.search).get("id");
        localStorage.setItem('warehouseid', id);
        const wh_tab = new URLSearchParams(this.props.location.search).get("tab");
        const warehouseid = this.state;
        return (
            <div>
                {/* <!-- Side Nav --> */}
                
                <Warehouse_List_Header_Search id={warehouseid}></Warehouse_List_Header_Search>
                <div class="mt-30">
                    <div class="container">
                        <div class="">
                            <div class="row">
                                <Warehouse_Details_MainContent wh_id={id}  tabs={wh_tab}></Warehouse_Details_MainContent>
                                <Warehouse_Details_RightSide_PostedOn  wh_id={id}></Warehouse_Details_RightSide_PostedOn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Warehouse_Details

// <!-- agrigate Details Tab script -->


// $(document).ready(function(){
//   $('[data-toggle="tooltip"]').tooltip();   
// });




