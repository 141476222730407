import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Home_Component_Our_Partners from '../components/Home_Component_Our_Partners';

export class Services extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <div>
                    <div>
                        <div className="top-section breadcrumb about-bd">
                            <div className="container">
                                <h2 className="text-center mtb-0">Services</h2>
                            </div>
                        </div>
                        <div className="container">
                            <div className="bd-text">
                                <Link to="/">Agrigate /</Link>  <a className="active">Services</a>
                            </div>
                        </div>

                        {/* Side Nav  */}
                        {/* <div id="mySidenav" class="sidenav ">  
                    <a href="#" id="projects">List your warehouse</a>  
                </div> */}

                        <div className="ptop-50 text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-8">
                                        <div class="heading3">At Agrigate, our all-in-one app encompasses a wide array of warehousing and storage-related services. </div>
                                        <p>Leveraging technology, we aim to maximise trust and transparency in the supply chain.</p>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                                <div className="car-div"></div>
                            </div>
                        </div>

                        <div className="">
                            <div className="container">
                                <div className="step-sec">
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="careers-img text-center">
                                                <img src={require('../assests/images/service1.png')} />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="aboutText">
                                                <h3 className="">Finance</h3>
                                                <p className="">At Agrigate, our financing solutions enable access to simple and secure credit at the touch of a button. Building on our strong partnership with over 25 banks and financial institutions, we provide seamless loans against collateral in a standard manner.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                    <div className="text-center">
                                        <div class="ptop-50">
                                            <h4 class="text-center">The Agrigate advantage:</h4>
                                        </div>
                                        <div className="row ptop-50">
                                            <div className="col-md-1"></div>
                                            <div className="col-md-10">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-a.png')} />
                                                            <div className="fc-name mt-20">Real-time information on stock status through an online dashboard</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-b.png')} />
                                                            <div className="fc-name mt-20 ">Quick credit against the transfer and storage of goods</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-c.png')} />
                                                            <div className="fc-name mt-20">Superior, tech-powered risk management framework with high accountability</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-d.png')} />
                                                            <div className="fc-name mt-20">One-click disposal of stressed stocks through e-marketplaces like Agribazaar.com</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="car-div"></div>
                                <div className="step-sec">
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="">
                                                <h3 className="mt">Logistics</h3>
                                                <p className="">At Agrigate, our financing solutions enable access to simple and secure credit at the touch of a button. Building on our strong partnership with over 25 banks and financial At Agrigate, our end-to-end logistics solutions offer access to a nationwide transporters network at the touch of a button. Leveraging our expertise in the agri commodities space and our partnership with leading 3rd Party Logistics (3PL) service providers, we facilitate safe transportation and delivery of your goods.a standard manner.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="careers-img text-center">
                                                <img src={require('../assests/images/service2.png')} />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                    <div className="text-center">
                                        <div class="ptop-50">
                                            <h4 class="text-center">The Agrigate advantage:</h4>
                                        </div>
                                        <div className="row ptop-50">
                                            <div className="col-md-1"></div>
                                            <div className="col-md-10">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-e.png')} />
                                                            <div className="fc-name mt-20">Quick access to an expansive, country-wide logistics network</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-f.png')} />
                                                            <div className="fc-name mt-20 ">Flexibility to outsource operational logistics from anywhere, anytime and allow experts to take care of the rest</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-g.png')} />
                                                            <div className="fc-name mt-20">Strategically located warehouses, approachable by motorable roads and close to transporters and production units</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-h.png')} />
                                                            <div className="fc-name mt-20">Digitised recordkeeping of transactions across warehouses, including inward and outward movement, inventory financing and logistics entry</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="car-div"></div>

                                <div className="step-sec">
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="careers-img text-center">
                                                <img src={require('../assests/images/service3.png')} />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="aboutText">
                                                <h3 className="">Lab assessment</h3>
                                                <p className="">At Agrigate, our lab assessment services ensure that the quality of goods and commodities is maintained as per industry standards. By setting benchmarks for quality across the value chain, we aim to facilitate fair trade for buyers and sellers. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                    <div className="text-center">
                                        <div class="ptop-50">
                                            <h4 class="text-center">The Agrigate advantage:</h4>
                                        </div>
                                        <div className="row ptop-50">
                                            <div className="col-md-1"></div>
                                            <div className="col-md-10">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-i.png')} />
                                                            <div className="fc-name mt-20">In-depth analysis of stored goods and commodities by certified Quality Assurance (QA) laboratories</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-j.png')} />
                                                            <div className="fc-name mt-20 ">Assured quality for seamless transactions between renters, traders, financial institutions, and collateral managers</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-k.png')} />
                                                            <div className="fc-name mt-20">Digitised recordkeeping of QA reports to empower our customers</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-l.png')} />
                                                            <div className="fc-name mt-20">Compliance with the testing and auditing norms of processing companies</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="car-div"></div>
                                <div className="step-sec">
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5">
                                            <div className="">
                                                <h3 className="mt">Insurance</h3>
                                                <p className="">At Agrigate, our insurance solutions help minimise risks, by providing a safety net. We liaise with reputed insurers to monitor and assess risks and customise our offerings to cover a wide array of goods and commodities against weather related or in-transit damages.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="careers-img text-center">
                                                <img src={require('../assests/images/service4.png')} />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                    <div className="text-center">
                                        <div class="ptop-50">
                                            <h4 class="text-center">The Agrigate advantage:</h4>
                                        </div>
                                        <div className="row ptop-50">
                                            <div className="col-md-1"></div>
                                            <div className="col-md-10">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-m.png')} />
                                                            <div className="fc-name mt-20">Assured protection against possible losses</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-n.png')} />
                                                            <div className="fc-name mt-20 ">Access to standard insurance policies for better coverage</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-o.png')} />
                                                            <div className="fc-name mt-20">Seamless purchase of insurance for transit or stocking at the warehouse</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="serv-advan">
                                                            <img src={require('../assests/images/serv-p.png')} />
                                                            <div className="fc-name mt-20">Digitised recordkeeping for claims management</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="car-div"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Home_Component_Our_Partners></Home_Component_Our_Partners>
            </div>
        )
    }
}

export default Services
