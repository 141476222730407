import React, { Component, useRef, useState } from 'react';
import Home_Component_Search from '../components/Home_Component_Search';
import Home_Component_Feature_Warehouse from '../components/Home_Component_Feature_Warehouse';
import Home_Component_Warehouse_List from '../components/Home_Component_Warehouse_List';
import Home_Component_Cities from '../components/Home_Component_Cities';
import Home_Component_Testimonial from '../components/Home_Component_Testimonial';
import Home_Component_Our_Partners from '../components/Home_Component_Our_Partners';
import $ from 'jquery';
import Loading from 'react-fullscreen-loading';
import { render } from 'react-dom';
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { GoogleComponent } from 'react-google-location';
import Loaderfn from '../components/Loaderfn'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
const API_KEY = "AIzaSyCZaiO-iS1Z8m2uMFnkuZ0BMwN9kd1m4qU";

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            place: null,
            wh_type: '',
            address: '',
            isLoading: true
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({ isLoading: false })
    }
    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
        //alert(this.state.latLng);
    };


    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    searching = (e) => {
        //alert(this.state.wh_type)
        var lat = $("#lat-span").text();
        var lon = $("#lon-span").text();
        var state = $("#state-span").text();
        var city = $("#city-span").text();
        var location = $("#location-snap").text();
        //alert($("#lat-span").text())
        window.location.href = "/#/warehouse-list?type=" + this.state.wh_type + "&lat=" + lat + "&lng=" + lon + "&location=" + location + "&city=" + city + "&state=" +state;
    }

    asd() {
        // /alert(this.id);
        var accordions = document.getElementsByClassName("accordion");
        //alert(accordions.to);
        for (let i = 0; i < accordions.length; i++) {
            //alert(1);
            accordions[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });

        }
    }



    render() {
        function initMap() {
            var input = document.getElementById('searchMapInput');
            //alert(input)
            /* eslint-disable no-undef */
            var autocomplete = new google.maps.places.Autocomplete(input);

            autocomplete.addListener('place_changed', function () {
                var place = autocomplete.getPlace();
                let div = document.createElement('div');
                div.innerHTML = place.adr_address;
                let cityname = div.getElementsByClassName('locality')[0].innerHTML;
                let statename = div.getElementsByClassName('region')[0].innerHTML;
                document.getElementById('city-span').innerHTML = cityname;
                document.getElementById('state-span').innerHTML = statename;
                document.getElementById('location-snap').innerHTML = place.formatted_address;
                document.getElementById('lat-span').innerHTML = place.geometry.location.lat();
                document.getElementById('lon-span').innerHTML = place.geometry.location.lng();
            });
        }

        return (

            this.state.isLoading ? <Loaderfn></Loaderfn> :

                <div>

                    {/* <Loading loading background="#2ecc71" loaderColor="#3498db" /> */}
                    <div class="top-section banner">
                        <div class="container">
                            <div class="slider-head">
                                <p class="qline1">Trusted. Transparent. Tech-driven.</p>
                                <p class="qline2">One-stop solutions for all your warehousing needs and more.</p>
                                <div class="banner-serch-form">
                                    <form>
                                        <div class="wrapper">
                                            <div class="search-container mt-35">
                                                <div class="input-group">
                                                    {/*  */}

                                                    <input id="searchMapInput" class="form-control googlelocation" type="text" onChange={initMap} placeholder="Enter a location" />


                                                    <select class="form-control whtype" name="wh_type" onChange={this.onChange}>
                                                        <option>All</option>
                                                        <option>Cold Storage</option>
                                                        <option>Dry Warehouse</option>
                                                        <option value="Silo">Silo Warehouse</option>
                                                    </select>
                                                    <span class="input-group-btn" ></span>
                                                    <a onClick={this.searching} class="btn btn-primary serchbtn"><FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;Search</a>
                                                </div>
                                                <ul id="geoData" hidden>
                                                    <li>Full Address: <span id="location-snap"></span></li>
                                                    <li>Latitude: <span id="lat-span"></span></li>
                                                    <li>Longitude: <span id="lon-span"></span></li>
                                                    <li>State: <span id="state-span"></span></li>
                                                    <li>City: <span id="city-span"></span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* <!-- Value-added services --> */}
                    <div class="value-services">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mt-30 wow fadeIn">
                                        <h2 class="text-center">Value-added services</h2>
                                    </div>
                                    <div class="service-list">
                                        <ul>
                                            <li class="wow zoomIn">
                                                <div class="mb-10">
                                                    <img src={require('../assests/images/vs1.png')} />
                                                    <h4 class="heading mt-20">Cloud Inventory Management </h4>
                                                    <p>Timely availability of credit to enhance holding capacity</p>
                                                </div>
                                            </li>
                                            <li class="wow zoomIn">
                                                <div class="mb-10">
                                                    <img src={require('../assests/images/vs2.png')} />
                                                    <h4 class="heading mt-20"> Inventory Finance</h4>
                                                    <p>Supply chain efficiency right up to the last mile</p>
                                                </div> </li >
                                            <li class="wow zoomIn">
                                                <div class="mb-10">
                                                    <img src={require('../assests/images/vs3.png')} />
                                                    <h4 class="heading mt-20">Logistics </h4>
                                                    <p>State-of-the-art quality and testing laboratories </p>
                                                </div> </li >
                                            <li class="wow zoomIn">
                                                <div class="vap mb-10">
                                                    <img src={require('../assests/images/vs4.png')} />
                                                    <h4 class="heading mt-20">Quality Testing</h4>
                                                    <p>Protection to address financial risks.</p>
                                                </div> </li >
                                            <li class="wow zoomIn">
                                                <div class="vap mb-10">
                                                    <img src={require('../assests/images/vs5.png')} />
                                                    <h4 class="heading">Insurance</h4>
                                                    <p>Protection to address financial risks.</p>
                                                </div> </li >
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Feature Warehouse Dynamic  */}
                    <Home_Component_Feature_Warehouse></Home_Component_Feature_Warehouse>

                    {/* Warehouse list Dynamic Component */}
                    <Home_Component_Warehouse_List></Home_Component_Warehouse_List>
                    {/* why choose us */}
                    <div class="why-choose-us">
                        <div class="container">
                            <div class="mt-20">
                                <h2 class="text-center wow fadeIn">Why choose us </h2>
                                <p>All warehouse on our platform meet specific standards of sefety, technicalities, compliance etc.</p>
                            </div>
                            <div class="row mt-40">
                                <div class="col-md-3 col-sm-6 wow zoomIn">
                                    <div class="wc-list mb-20">
                                        <img src={require('../assests/images/wc1.png')} />
                                        <h4 class="mt-20 mb-0">Great Choices</h4>
                                        <p>400 cities</p>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 wow zoomIn">
                                    <div class="wc-list mb-20">
                                        <img src={require('../assests/images/wc2.png')} />
                                        <h4 class=" mt-20 mb-0">Trusted by Customers</h4>
                                        <p>15 years of experience</p>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 wow zoomIn">
                                    <div class="wc-list mb-20">
                                        <img src={require('../assests/images/wc3.png')} />
                                        <h4 class=" mt-20 mb-0">Collateral Finance</h4>
                                        <p>25 banks</p>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 wow zoomIn">
                                    <div class="wc-list mb-20">
                                        <img src={require('../assests/images/wc4.png')} />
                                        <h4 class=" mt-20 mb-0">Peace of Mind</h4>
                                        <p>1 Lac MT of grains stored </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <!-- About Section --> */}
                    <div class="">
                        <div class="container">
                            <div class="row mt-30">
                                <div class="col-md-6">
                                    <div class="text-center about-img wow zoomIn">
                                        <img src={require('../assests/images/about.png')} />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mt-20  text-xs-center wow zoomIn">
                                        <h2 class="">About us </h2>
                                        <p>All warehouse on our platform meet specific standards of sefety, technicalities, compliance etc.</p>
                                    </div>
                                    <div class="row mt-30 text-center">
                                        <div class="col-md-4 bd-right wow zoomIn">
                                            <div class="ab-icon">
                                                <img src={require('../assests/images/au1.png')} />
                                            </div>
                                            <div class="mt-20">
                                                <p>All warehouse on our platform meet specific standards of sefety, technicalities, compliance etc.</p>
                                            </div>
                                        </div>
                                        <div class="col-md-4 bd-right wow zoomIn">
                                            <div class="ab-icon">
                                                <img src={require('../assests/images/au2.png')} />
                                            </div>
                                            <div class="mt-20 ">
                                                <p>All warehouse on our platform meet specific standards of sefety, technicalities, compliance etc.</p>
                                            </div>
                                        </div>
                                        <div class="col-md-4 wow zoomIn">
                                            <div class="ab-icon ">
                                                <img src={require('../assests/images/au3.png')} />
                                            </div>
                                            <div class="mt-20">
                                                <p>All warehouse on our platform meet specific standards of sefety, technicalities, compliance etc.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-30 ">
                                        <div class="col-md-12 text-xs-center">
                                            <Link to="/about"><a class="btn btn-danger" role="button " >Read more</a></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Cities Area Dynamic */}
                    <Home_Component_Cities></Home_Component_Cities>

                    {/*Youtube & Testimonial */}
                    <div id="testimonial" class="wow fadeIn">
                        <Home_Component_Testimonial></Home_Component_Testimonial>
                    </div>
                    {/* <!-- FAQs Section --> */}
                    <div class="wow fadeIn" id="faqs">
                        <div class="container">
                            <div class="row mt-30">
                                <div class="col-md-6 col-sm-6">
                                    <div class="mt-35">
                                        <h2 class="">FAQs </h2>
                                    </div>
                                    <div class="mt-20">
                                        <div class="accordion">How many banks is Agrigate associated with?</div>
                                        <div class="panel ">
                                            <p>We are in financial partnerships with 25 banks.</p>
                                        </div>
                                        <div class="accordion">Can I list my warehouse on the website for business?</div>
                                        <div class="panel">
                                            <p>Yes. You can sign up with your mobile number and send an approval request to us with just 4 easy steps.</p>
                                        </div>
                                        <div class="accordion">How many cities does Agrigate cover?</div>
                                        <div class="panel">
                                            <p>We have listed more than 600 warehouses in 400 cities all over India.</p>
                                        </div>
                                        {/* <a href="#" class="btn btn-danger mt-30" role="button " >Read more</a> */}
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="text-center faqs-img">
                                        <img src={require('../assests/images/faq.png')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Our Partners area Dynamic*/}
                    <Home_Component_Our_Partners></Home_Component_Our_Partners>

                </div>


        )
    }
}

export default Home



$(document).on('click', '.accordion', function () {
    // your function here

    var accordions = document.getElementsByClassName("accordion");
    //alert(accordions.to);
    for (let i = 0; i < accordions.length; i++) {
        //alert(1);
        //accordions[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
        //}
        //);
    }
});
// <!-- Tab script -->