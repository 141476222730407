import React, { Component } from 'react'
import { Link, useParams } from 'react-router-dom';
import Loaderfn from './Loaderfn'
export class Home_Component_Feature_Warehouse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            warehouselist: []
        };
    }
    componentDidMount() {

        fetch("https://agrigates.com/v2/bmsnew/warehouse/getlist", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "showInLanding": "2",
                        "showInSpaceDiscovery": "1",
                        "premiumProperty": "1"
                    },
                    "offset": 0,
                    "limit": 2
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    warehouselist: result.data
                });

                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    render() {
        const { error, isLoaded, warehouselist } = this.state;

        //warehouselist.limit < 3;
        //warehouselist = warehouselist.filter(x=>x.lenght < 3);
        return (
            isLoaded == false ? <Loaderfn></Loaderfn> :
                <div>


                    <div class="find-warehouse">
                        <div class="container">
                            <div class="mt-10 mb-30">
                                <h2 class="text-center wow fadeIn">Find a warehouse</h2>
                            </div>
                            {/* <div class="text-right mt-30">
                            <span class="mr-10"><img src={require('../assests/images/settings.svg')} /> Sort by</span>
                            <span><img src={require('../assests/images/filter.svg')} /> Filter</span>
                        </div> */}
                            <div class="row mt-10">
                                {
                                    warehouselist.map(warehouse => (
                                        <div class="col-md-6">
                                            <div class="find-warehouse-box wow fadeIn">
                                                <div class="find-banner">
                                                    <div class="imgboxh400">
                                                        {warehouse.images.length > 0 ? <img src={warehouse.images[0].file_path} width="100"></img> : <img src={require('../assests/images/demowarehouse.jpg')} width="22"></img>}
                                                    </div>
                                                    <div class="img-label">{warehouse.warehouse_name}</div>
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <div class="fw-house">
                                                        <h4 class="heading">{warehouse.warehouse_subtype_name}<br /></h4>
                                                        {/* <span>({warehouse.warehouse_subtype_name})</span> */}
                                                        {/* <p>Timely Availability of credit to enhance holding capacity.Timely Availability of credit to enhance holding capacity</p> */}
                                                        <div><Link to={`/warehouse-details?id=${warehouse.warehouse_id}`}><a class="more-link">Read more</a></Link></div>
                                                        {/* <div class="mt-10">
                                                        <div class="inline-block text-center mr-10 mt-10" ><img src={require('../assests/images/thumsup.png')} width="22"></img><br />Like</div>
                                                        <div class="inline-block text-center mt-10" ><img src={require('../assests/images/chat.png')} width="22"></img><br />Comment</div>
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <div class="warehouse-form">
                                                        <ul>
                                                            <li class="">
                                                                <span>Location-State/City:</span>
                                                                <p>{warehouse.state_name}/{warehouse.location_name}</p>
                                                            </li>
                                                            <li class="">
                                                                <span>Type:</span>
                                                                <p>{warehouse.warehouse_type_name}</p>
                                                            </li>
                                                            <li class="">
                                                                <span>Capacity:</span>
                                                                <p>{warehouse.warehouse_capacity}</p>
                                                            </li>
                                                            {/* <li class="">
                                                            <span>Stock details:</span>
                                                            <p></p>
                                                        </li> */}
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                </div>
        )
    }
}

export default Home_Component_Feature_Warehouse
