import React, { Component } from 'react'
import { BsPrefixComponent } from 'react-bootstrap/esm/helpers';
import Warehouse_List_Header_Search from '../components/Warehouse_List_Header_Search';
import Home_Component_Our_Partner from '../components/Home_Component_Our_Partners';
import User_profile_view from '../components/User_profile_view';
import Edit_userprofile from '../components/Edit_userprofile';
import Sidenavbar from '../components/Sidenavbar';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AsyncStorage } from 'AsyncStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faBackward } from '@fortawesome/free-solid-svg-icons';

toast.configure();

//const notify = () => toast("Please Update your profile.");    
export class User_profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEmptyState: true,
            userdetails: [],
            isFetching: false,
            fname: '',
            lname: '',
            email: '',
            name: '',
            phone: '',
            email: '',
            address: '',
            dob: '',
            aadhar: '',
            pan: '',
            gst: '',
            image: null,
            Attachmentfile: [],
            userid: '',
            message: ''
        };
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                //alert(value)
                this.setState({ userid: value });
            }
            else {
                window.location = '/';
            }
        });
        window.$("#modal-signin").modal('hide');
    }

    trigger_Edit_screen = () => {
        //alert(1);
        this.setState({
            ...this.state,
            isEmptyState: false,
            isAddTripState: true,
            message: ''
        })
    }
    trigger_comeback = () => {
        //alert(1);
        //console.log(1);
        this.setState({
            ...this.state,
            isEmptyState: true,
            isAddTripState: false
        })
        //window.location.href="/#/user-profile";
        // this.componentDidMount()
    }

    componentDidMount() {

        window.scrollTo(0, 0)

        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                //alert(this.state.userid);
                fetch("https://agrigates.com/v2/bmsnew/users/get", {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    },
                    body: JSON.stringify(
                        {
                            "filter": {
                                "id": this.state.userid
                            }
                        }
                    )
                }
                )
                    .then(response => response.json())
                    .then(result => {

                        this.setState({
                            isLoaded: true,
                            userdetails: result.data[0]
                        });
                        console.log(result.data);
                    },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
            }
        })
    }

    callbackFunction = (childData) => {
        this.componentDidMount();
        this.setState({ message: childData })
    }


    render() {



        return (
            <div>

                <Warehouse_List_Header_Search> </Warehouse_List_Header_Search>
                {/* <Sidenavbar></Sidenavbar> */}
                {/* <button onClick={this.trigger_Edit_screen}>Edit screen</button>
                <button onClick={this.trigger_comeback}>View Screen</button> */}

                <div class="mt-30">
                    <div class="container">
                        <h4 class="top-heading" >User Profile</h4>
                        <div class="mt-20">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="filter-box">
                                        <ul class="info-ui">
                                            <li>Dashboard</li>
                                        </ul>
                                    </div>
                                    <div class="inner-box mt-20">
                                        <div class="filter-panel">
                                            <h4 class="heading" ><Link to="/user-profile"><a class="active">User Profile</a></Link></h4>
                                        </div>
                                        <div class="filter-panel">
                                            <h4 class="heading"> <Link to="/booking-orders"><a class="">Orders</a></Link></h4>
                                        </div>
                                        <div class="filter-panel">
                                            <h4 class="heading">Stock</h4>
                                            <p class=""> <Link to="/stock-pile"><a class="">Stock Pile</a></Link></p>
                                            <p class=""> <Link to="/recent-details"><a class="">Recent Details</a></Link></p>
                                        </div>

                                    </div>
                                </div>
                                {/* <!-- col-md-4 End--> */}
                                <div class="col-md-8">
                                    <div class="filter-box">
                                        <ul class="info-ui">
                                            <li>User Profile</li>
                                        </ul>
                                    </div>

                                    {this.state.message == null || this.state.message == '' ?
                                        ""
                                        :
                                        <div class="alert alert-success">
                                            <p>{this.state.message}</p>
                                        </div>
                                    }
                                    {this.state.isEmptyState &&
                                        <div> {(this.state.userdetails.bms_name == null || this.state.userdetails.bms_name == '') || (this.state.userdetails.bms_email == null || this.state.userdetails.bms_email == '') ?
                                            <div className="alert alert-danger">
                                                <p>Please Update your profile. </p>
                                            </div>
                                            : ""
                                        }</div>
                                    }
                                    {/* User profile view start */}
                                    {this.state.isEmptyState && <p class="text-right"><a style={{ cursor: "pointer" }} onClick={this.trigger_Edit_screen}><FontAwesomeIcon icon={faPenToSquare} /> Edit</a></p>}
                                    {this.state.isAddTripState && <p class="text-right"><a style={{ cursor: "pointer" }} onClick={this.trigger_comeback}><FontAwesomeIcon icon={faBackward} /> Back</a></p>}
                                    {this.state.isEmptyState && <User_profile_view />}

                                    {this.state.isAddTripState && <Edit_userprofile handler={this.trigger_comeback} parentCallback={this.callbackFunction} />}

                                    {/* User Profile view end */}

                                </div>
                                {/* <!-- col-md-8 End--> */}
                            </div>
                        </div>
                    </div>
                </div>

                <Home_Component_Our_Partner></Home_Component_Our_Partner>
            </div>
        )
    }
}

export default User_profile

// export function allright()
// {
//     //alert(1);
//     //alert(this.isEmptyState,this.isAddTripState);
//     this.setState({
//         ...this.state,
//         isEmptyState: false,
//         isAddTripState: true
//       })
//       //alert(this.isEmptyState,this.isAddTripState);
//       //alert(2);

// }