import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
// const nodemailer = require("nodemailer");
// const transporter = nodemailer.createTransport({
//     host: "smtp.staragri.com",
//     port: 587,
//     secure: false,
//     auth: {
//         user: 'webmail.staragri.com',
//         pass: '$ecuredOne9#'
//     }
// });

// create transporter object with smtp server details


export class Contact extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            home: '',
            company: '',
            email: '',
            phone: '',
            comment: ''
        };
    }
    onChange = (e) => {
        // alert(e.target.value) ;   
        //e.preventDefault(); 
        this.setState({ [e.target.name]: e.target.value });
    }
    componentDidMount() {
        window.scrollTo(0, 0)
            ;

    }

    sendmail = (e) => {
        e.preventDefault();
        // send mail with defined transport object
        // alert(1)
        //alert(`Home is :- ${this.state.home}, Company is :-  ${this.state.company} , Email is :- ${this.state.email} , Phone is :- ${this.state.phone}, Comment is :- ${this.state.comment}`)
        if (this.state.home == null || this.state.home == '') {
            $("#showmsg_rate").addClass("alert alert-danger").text("Please Enter Home");
        }
        else if (this.state.company == null || this.state.company == '') {
            $("#showmsg_rate").addClass("alert alert-danger").text("Please Enter Company");
        }
        else if (this.state.email == null || this.state.email == '') {
            $("#showmsg_rate").addClass("alert alert-danger").text("Please Enter Email ID");
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.email)) {
                $("#showmsg_rate").addClass("alert alert-danger").text("Please Enter Valid Email ID");
            }
        }
        else if (this.state.phone == null || this.state.phone == '') {
            $("#showmsg_rate").addClass("alert alert-danger").text("Please Enter Phone Number");
        }
        else {
            // transporter.sendMail({
            //     from: 'webadmin@staragri.com',
            //     to: 'webadmin@staragri.com',
            //     subject: "Test email",
            //     text: `Home is :- ${this.state.home}, Company is :-  ${this.state.company} , Email is :- ${this.state.email} , Phone is :- ${this.state.phone}, Comment is :- ${this.state.comment}`
            // });
            $("#showmsg_rate").addClass("alert alert-success").text("your form is submitted, We will contact back soon.");
            this.setState({home : '', comment : '', email : '', phone :'', company:''});
        }
    }



    // send email


    render() {

        return (
            <div>
                <div className="top-section breadcrumb contact-bd">
                    <div className="container">
                        <p>Contact</p>
                        <Link to="/">Home</Link> &gt; <a className="active">Contact</a>
                    </div>
                </div>

                {/* Side Nav  */}
                {/* <div id="mySidenav" class="sidenav ">  
                <a href="#" id="projects">List your warehouse</a>  
                </div> */}

                <div className="">
                    <div className="container">
                        <div className="row">
                            <div class="mt-20 col-md-12"><h2 className="">Contact</h2>	</div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div class="contactaddress">
                                    <div class="location">801, Sumer Plaza, Marol Maroshi Road, Beside KP Aurum, Sankasth Pada Welfare Society, Marol, Andheri (East), Mumbai - 400 059</div>
                                    <div class="phone">+91 9090 397 777</div>
                                    <div class="mail">info@agrigates.com</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mapouter mt-20">
                                    <div className="gmap_canvas">
                                        <iframe width="100%" height="250" id="gmap_canvas" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getintouch">
                    <div className="container">
                        <div className="row">
                            <div class="mt-20 col-md-12"><h2 class="">Get in touch </h2>	</div>
                        </div>
                        <form onSubmit={this.sendmail}>
                            <div className="row">
                                <div className="contact-form mt-20">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control ag-fc" placeholder="Home" name="home" required value={this.state.home} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control ag-fc" placeholder="Company" name="company" required value={this.state.company} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control ag-fc" placeholder="Email" required name="email" value={this.state.email} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control ag-fc" placeholder="Phone" required name="phone" value={this.state.phone} minLength="10" maxLength="10" onChange={event => this.setState({ phone: event.target.value.replace(/\D/, '') })} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea class="form-control" rows="3" placeholder="Comments" name="comment" value={this.state.comment} onChange={this.onChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-20">
                                        <div class="g-recaptcha" data-sitekey="6Ldbdg0TAAAAAI7KAf72Q6uagbWzWecTeBWmrCpJ"></div>
                                        <button type="submit" style={{ cursor: 'pointer' }} className="btn btn-danger mt-30" role="button " >Submit</button>
                                        <div id="showmsg_rate"></div>
                                    </div>
                                    
                                </div>
                               
                            </div>
                        </form>
                    </div>
                </div>



            </div>)
    }
}

export default Contact

// function sendEmail() {
//     Email.send({
//         Host: "smtp.staragri.com",
//         Username: "webmail.staragri.com",
//         Password: "$ecuredOne9#",
//         To: 'nitin.saad1995@gmail.com',
//         From: "webadmin@staragri.com",
//         Subject: "Sending Email using javascript",
//         Body: "Well that was easy!!",
//     })
//         .then(function (message) {
//             alert("mail sent successfully")
//         });
// } 