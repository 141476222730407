import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Home_Component_Our_Partners from '../components/Home_Component_Our_Partners';
import { AsyncStorage } from 'AsyncStorage';

export class Story extends Component {
    constructor(props) {
        super(props);
        this.state = { userid: '' }
        AsyncStorage.getItem('UserID').then((value) => { this.setState({ userid: value }) });
    }
    componentDidMount() {
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
                <div>
                    <div>
                        <div className="top-section breadcrumb about-bd">
                            <div className="container">
                                <h2 className="text-center mtb-0">Our Story</h2>
                            </div>
                        </div>
                        <div className="container">
                            <div className="bd-text">
                                <Link to="/">Agrigate /</Link>  <a className="active">Our Story</a>
                            </div>
                        </div>

                        <div className="mb-30">
                            <div className="container">
                                <div className="row mt-30">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-8">
                                        <div class="heading3">We’re excited to transform Indian warehousing by leveraging technology to offer end-to-end value-added services to customers.</div>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5">
                                        <div className="aboutText mt-30">
                                            <p className="">It all began when we identified a need gap in the Indian warehousing and storage landscape – lack of a
                                            common platform where owners and renters can display and access modern warehouses for agri and non-agri needs alike. To bridge the gap, we envisioned Agrigate as an easy-to-use app that
                            simplifies the online search and listing of storage spaces. And in doing so, increases the transparency of real-time transactions and builds trust.</p>

                                            <div className="mt-20">
                                                {/* <a href="#" class="btn btn-danger mr-10" role="button">Register</a> */}
                                                {this.state.userid == '' &&
                                                    <a style={{ cursor: 'pointer' }} class="btn btn-danger mr-10" data-toggle="modal" data-target="#modal-signup">Register</a>}
                                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.staragri.agrigatespacediscovery" class="btn btn-danger" role="button">Download the App</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="aboutbnr-img text-center">
                                            <img src={require('../assests/images/storybnr.png')} />
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                            </div>
                        </div>

                        {/* Side Nav  */}
                        {/* <div id="mySidenav" class="sidenav ">
                        <a href="#" id="projects">List your warehouse</a>
                    </div> */}

                        <div class="container ptop-50 text-center">
                            <div class="caption">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div className="caption-icon"> <img src={require('../assests/images/cap-icon2.png')} /></div>
                                        <p className="">Agrigate not only facilitates warehouse booking and listing, but also offers seamless access to allied services,
                            like finance, logistics, lab assessment, and insurance at the click of a button.</p>
                                        <div className="facilitates">
                                            <ul>
                                                <li className="facilitates-list">
                                                    <div className="">
                                                        <img src={require('../assests/images/finance.png')} />
                                                        <div className="fc-name">Finance</div>
                                                    </div>
                                                </li>
                                                <li className="facilitates-list">
                                                    <div className="">
                                                        <img src={require('../assests/images/logistic.png')} />
                                                        <div className="fc-name">Logistics</div>
                                                    </div>
                                                </li>
                                                <li className="facilitates-list">
                                                    <div className="">
                                                        <img src={require('../assests/images/lab-assi.png')} />
                                                        <div className="fc-name">Lab Assessment</div>
                                                    </div>
                                                </li>
                                                <li className="facilitates-list">
                                                    <div className="">
                                                        <img src={require('../assests/images/insurance.png')} />
                                                        <div className="fc-name">Insurance</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Home_Component_Our_Partners></Home_Component_Our_Partners>
            </div>
        )
    }
}

export default Story
