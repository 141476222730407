import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import { GoogleComponent } from 'react-google-location';
import Warehouse_List_ListContent from './Warehouse_List_ListContent';
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const API_KEY = "AIzaSyCZaiO-iS1Z8m2uMFnkuZ0BMwN9kd1m4qU";


export class Warehouse_List_Header_Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            place: null,
            lat : '',
            lng : '',
            txtsearch : ''
        };

    }
    onChange = (e) => {     
         this.setState({ [e.target.name]: e.target.value });   
          }
    searching = (e) =>
    {
        var lat = $("#lat-span").text();
        var lon = $("#lon-span").text();
        var location = $("#location-snap").text();
        var city = $("#city-span").text();
        var state = $("#state-span").text();
        //alert($("#lat-span").text())
        window.location.href = "/#/warehouse-list?search=" + this.state.txtsearch+"&lat="+lat+"&lng="+lon+"&location="+location+ "&city=" + city + "&state=" +state;
    
        //window.location.href="/#/warehouse-list?search="+this.state.txtsearch;
        //alert(2);
    }
    render() {
       // console.warn("result", this.state.place);
       function initMap() {
        var input = document.getElementById('searchMapInput');
        //alert(input)
        /* eslint-disable no-undef */
        var autocomplete = new google.maps.places.Autocomplete(input);

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            let div = document.createElement('div');
            div.innerHTML = place.adr_address;
            let cityname = div.getElementsByClassName('locality')[0].innerHTML;
            let statename = div.getElementsByClassName('region')[0].innerHTML;
            document.getElementById('city-span').innerHTML = cityname;
            document.getElementById('state-span').innerHTML = statename;
            document.getElementById('location-snap').innerHTML = place.formatted_address;
            document.getElementById('lat-span').innerHTML = place.geometry.location.lat();
            document.getElementById('lon-span').innerHTML = place.geometry.location.lng();
        });
    }
        return (
            <div>
                {/* <!-- Search Form  --> */}
                <div class="top-section inner-form">
                    <div class="container">
                        <div class="row">
                            <div class="">
                                <div class="col-md-3 col-sm-5 ">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Search for warehouse" name="txtsearch" value={this.state.txtsearch} onChange={this.onChange} />
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-5 ">
                                    <div class="form-group">                                        
                                      <input id="searchMapInput" class="form-control"  type="text" onChange={initMap} placeholder="Enter a location" />
                                      
                                    </div>
                                </div>
                                <ul id="geoData" hidden>
                                                    <li>Full Address: <span id="location-snap"></span></li>
                                                    <li>Latitude: <span id="lat-span"></span></li>
                                                    <li>Longitude: <span id="lon-span"></span></li>
                                                    <li>State: <span id="state-span"></span></li>
                                                    <li>City: <span id="city-span"></span></li>
                                                </ul>
                                <div class="col-md-2 col-sm-2">
                                    <div class="form-group">
                                        <a onClick={this.searching} class="btn btn-danger" role="button"><FontAwesomeIcon icon={faSearch} /> Search</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Warehouse_List_Header_Search
