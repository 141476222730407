import React, { Component } from 'react'
//import {Link} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
export class Footer extends Component {
    render() {
        return (
            <div>
                <footer className="">
                    <div className="top-footer ">
                        <div className="container">
                            <div className="row mt-30">
                                <div className="col-md-6">
                                    <div class="contact-dtl">
                                        
                                        <h4 className="heading">Contact</h4>
                                        <p className="con-address">
                                            <img src={require('../assests/images/address.png')} className="adrspng" />
                                            <span>801, Sumer Plaza, Marol Maroshi Road, Beside KP Aurum, Sankasth Pada Welfare <br />Society, Marol, Andheri (East), Mumbai - 400 059.
 </span></p>
                                        <p className="con-address">
                                            <img src={require('../assests/images/call.png')} />  +91 9090 397 777
<span className="mail-left"><img src={require('../assests/images/mail.png')} /> info@agrigate.com</span>
                                        </p>

                                        <h4 className="heading mt-30"><Link to="/home#whstates">Browse in Top 6 States</Link></h4>
                                        {/* <p className="city-name">
                                            <span>Rajasthan | </span>
                                            <span>Andhra Pradesh | </span>
                                            <span>Gujarat | </span>
                                            <span>Madhya Pradesh | </span>
                                            <span>Maharashtra | </span>
                                            <span>Telangana</span>
                                        </p> */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="social-icon">
                                        <a className="" href="#"> <img src={require('../assests/images/f-logo.png')} className="custom-logo" /></a>
                                        <p className="mt-10">One of our core goals is to create a platform for the exchange, interaction and integration of warehousing information, competencies, expertise and resources.</p>
                                        <div className="mt-20">
                                            <img src={require('../assests/images/android.png')} className="play-store mr-10" />
                                            <img src={require('../assests/images/ios.png')} className="play-store mr-10" />
                                            {/* <span className="social-icon-list">
                                                <a href="" ><img src={require('../assests/images/fb.png')} className="mr-10" /></a>
                                                <a href="" ><img src={require('../assests/images/twitter.png')} className="mr-10" /></a>
                                                <a href="" ><img src={require('../assests/images/LinkedIn.png')} className="mr-10" /></a>
                                                <a href="" ><img src={require('../assests/images/youtube.png')} className="mr-10" /></a>
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- row --> */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="footer-nav mt-20">
                                        <Link to="/about" > About</Link>
                                        <Link to="/services">Services</Link>
                                        <Link to="/story">Story</Link>
                                        <Link to="/careers" >Careers</Link>
                                        <Link to="/home#testimonial">Testimonials</Link>
                                        <Link to="/home#faqs">FAQs</Link>
                                        <Link to="/contact" >Contact</Link>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- row --> */}


                        </div>
                    </div>
                    <div className="copyright">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-sm-10">
                                    <div class="">All rights reserved. © Copyright 2020 Agrigate.</div>
                                </div>
                                <div className="col-md-6 col-sm-2"><div className="pull-right"><a href="https://riteknowledgelabs.com/" target="_blank" class="rite">rite</a></div></div>
                            </div>
                        </div>
                    </div>
                </footer>


            </div>
        )
    }
}

export default Footer
