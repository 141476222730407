import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Edit_userprofile from './Edit_userprofile';
import Moment from 'moment';
import Loaderfn from './Loaderfn'
import { AsyncStorage } from 'AsyncStorage';
//import { LazyLoadImage } from 'react-lazy-load-image-component';
// import {triggerAddTripState} from '../Pages/User_profile';

export class User_profile_view extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            userdetails: [],
            isFetching: false,
            image: '',
            image_loaded: false
        };
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                // this.setState({userid: value});
                this.state = {
                    userid: value
                }

            }
            else {
                window.location = '/';
            }
        });
        // AsyncStorage.getItem('UserID').then((value) => { this.setState({ userid: value }) });
        // if (this.state.userid == '' || this.state.userid == undefined) {
        //     window.location = '/';
        // }

        //this.filtervalue = this.filtervalue.bind(this);
    }
    componentDidMount() {
        console.log(this.state.userid);
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                fetch("https://agrigates.com/v2/bmsnew/users/get", {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'session': 'pass'
                    },
                    body: JSON.stringify(
                        {
                            "filter": {
                                "id": this.state.userid
                            }
                        }
                    )
                }
                )
                    .then(response => response.json())
                    .then(result => {
                        setTimeout(() => {
                            this.setState({
                                image: result.data[0].bms_client_image,
                                image_loaded: true
                            })
                        }, 2000)
                        this.setState({
                            isLoaded: true,
                            userdetails: result.data[0],
                            isFetching: true
                        });


                        console.log(result.data);
                    },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    );
            }
        })
    }
    onLoad(feedItem) {
        // alert(1)
    }


    render() {
        const { error, isLoaded, userdetails, isFetching } = this.state;
        return (
            <div>


                {/* <button   onClick={triggerAddTripState.bind(this)}> testss </button>      */}
                {isFetching == false ? (
                    <Loaderfn></Loaderfn>
                ) : (


                    <div class="inner-box mt-20" style={{ padding: '0' }}>
                        <div class="uprofile-sec">
                            <div class="uprofile-img text-center">


                                {userdetails.bms_client_image != null ?
                                    // <img src={userdetails.bms_client_image} />
                                    this.state.image_loaded ?
                                        <img key={`${new Date().getTime()}`} src={this.state.image} />
                                        : <img src={require('../assests/images/img-load.gif')} />
                                    : <img src={require('../assests/images/demo-profile.jpg')} />
                                }

                                {/* <LazyLoadImage
                                        alt={"asdf"}
                                        height={150}
                                        src={userdetails.bms_client_image} // use normal <img> attributes as props
                                        width={150} /> */}


                                {/* <img src={userdetails.bms_client_image} /> */}
                            </div>
                            <div class="uprofile-form">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            Full Name
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="form-group">
                                            <b>{userdetails.bms_name}</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            Phone Number
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="form-group">
                                            <b>{userdetails.bms_mobile}</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            Email Address
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="form-group">
                                            <b>{userdetails.bms_email}</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            Address
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="form-group">
                                            <b>{userdetails.bms_user_address}</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            DOB
                                        </div>
                                    </div>
                                    <div class="col-md-9">

                                        <div class="form-group">
                                            {
                                                userdetails.bms_user_dob != null && userdetails.bms_user_dob != '0000-00-00' ?
                                                    <b>
                                                        {Moment(userdetails.bms_user_dob).format('DD MMM yyyy')}
                                                        {/* {new Intl.DateTimeFormat('en-GB', {
                                                        month: 'long',
                                                        day: '2-digit',
                                                        year: 'numeric',
                                                    }).format(new Date(userdetails.bms_user_dob))} */}
                                                    </b> : ""
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            Aadhar Number
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="form-group">
                                            <b>{userdetails.bms_user_aadhar}</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            PAN
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="form-group">
                                            <b>{userdetails.bms_user_pan}</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            GST
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="form-group">
                                            <b>{userdetails.bms_user_gst}</b>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </div>
        )
    }
}

export default User_profile_view


