import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
export class View_feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            ratinglist: [],
            isFetching: false

        };
        // this.Headline = this.Headline.bind(this);
    }


    componentDidMount() {
        // eslint-disable-next-line no-restricted-globals
        //const value=queryString.parse(location.search);
        const id = this.props.wh_id;
        //alert(id);
        //alert(id);
        //first api for list
        fetch("https://agrigates.com/v2/api/warehouseRatings/get", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "warehouseId": id
                    },
                    "join": { "user": true },
                    "offset": 0,
                    "limit": 100
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    ratinglist: result.data,
                    isFetching: true
                });

                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    render() {
        const { error, isLoaded, ratinglist } = this.state;
        return (
            <div>

                {
                    ratinglist.map(rating => (
                        <div class="feedback rent-box mt-10">
                            <div class="feedback-list wow fadeIn">
                                <div class="feedback-img"><img src={require('../assests/images/logo-feedback.png')} /></div>
                                <div class="feedback-content">

                                    <p>{new Intl.DateTimeFormat('en-GB', {
                                        month: 'long',
                                        day: '2-digit',
                                        year: 'numeric',
                                    }).format(new Date(rating.rating_created_at))} </p>


                                    <p>{rating.rating_review} </p>

                                    {(() => {
                                        if (rating.rating_score == 1) {
                                            return <div><FontAwesomeIcon icon={faStar} />
                                            </div>
                                                ;
                                        }
                                        if (rating.rating_score == 2) {
                                            return <div><FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                            </div>;
                                        }
                                        if (rating.rating_score == 3) {
                                            return <div><FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                            </div>;
                                        }
                                        if (rating.rating_score == 4) {
                                            return <div><FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                            </div>;
                                        }
                                        if (rating.rating_score == 5) {
                                            return <div><FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />

                                            </div>;
                                        }
                                    })()}



                                </div>
                            </div>
                        </div>

                    ))
                }


            </div>
        )
    }
}

export default View_feedback
