import React, { Component } from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import Warehouse_List_Header_Search from '../components/Warehouse_List_Header_Search';
import Warehouse_List_Filters from '../components/Warehouse_List_Filters';
import Home_Component_Our_Partner from '../components/Home_Component_Our_Partners';
import Warehouse_List_ListContent from '../components/Warehouse_List_ListContent';
import $ from 'jquery'

export class Warehouse_List extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        //alert( localStorage.getItem('UserID') );
        const txtsearch = new URLSearchParams(this.props.location.search).get("search");
        const wh_type = new URLSearchParams(this.props.location.search).get("type");
        const lat = new URLSearchParams(this.props.location.search).get("lat");
        const lng = new URLSearchParams(this.props.location.search).get("lng");
        const location = new URLSearchParams(this.props.location.search).get("location");
        const state = new URLSearchParams(this.props.location.search).get("state");
        const city = new URLSearchParams(this.props.location.search).get("city");
        var loc = window.location;
        var currentURL = loc.pathname;
        
       // alert(wh_type)
        
        return (
            <div>
                {/* <Warehouse_List_Header_Search ></Warehouse_List_Header_Search> */}
                {/* <div class="mt-30">
                    <div class="container">
                        <div class="">
                            <div class="row">
                                {/* <Warehouse_List_Filters></Warehouse_List_Filters> */}
                                {/* <Warehouse_List_ListContent search = {txtsearch}></Warehouse_List_ListContent>
                            </div>
                        </div>
                    </div> 
                </div> */}
                 <Warehouse_List_ListContent search = {txtsearch} wh_type = {wh_type} lat ={lat} lng={lng} location={location} state = {state} city={city}></Warehouse_List_ListContent>
                <Home_Component_Our_Partner></Home_Component_Our_Partner>
            </div>
        )
    }
}

export default Warehouse_List
