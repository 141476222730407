import React, { Component } from 'react'

export class Home_Component_Search extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        
        fetch('/api/form-submit-url', {
          method: 'POST',
          body: data,
        });
      }
    render() {
        

        
        return (
            <div>
                <h1></h1>
            </div>
        )
    }
}

export default Home_Component_Search
