import React from 'react'
import Loading from "react-fullscreen-loading";

function Loaderfn() {
    return (        
            <Loading loading={true}  background="#ffffff" loaderColor="#3498db" />      
           )
}

export default Loaderfn
