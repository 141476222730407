import React, { Component } from 'react';
import $ from 'jquery';
import * as _ from "lodash";

export class Home_Component_Cities extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isFetching: false,
            warehouselist: []
        };
        //this.filtervalue = this.filtervalue.bind(this);
    }
    componentDidMount(id) {
        //alert(id);
        if (id == undefined) {
            id = 14;
        }
        fetch("https://agrigates.com/v2/bmsnew/warehouse/getlist", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "state": id,
                        "showInSpaceDiscovery": "1",
                        "premiumProperty": "1"
                    },
                    "offset": 0,
                    "limit": 18
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {

                this.setState({
                    isLoaded: true,
                    warehouselist: result.data,
                    isFetching: true
                });


                console.log(result.data.sort);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    render() {

        const { warehouselist } = this.state;
        // const groupBystate = this.state.warehouselist.reduce((acc, it) => {
        //     acc[it.state_name] = acc[it.state_name] + 1 || 1;
        //     return acc;
        // }, {});
        //   _.sortBy(groupBystate,"state_name").map(application => (
        //     //alert(JSON.stringify(application))
        //     console.log("application")
        //     )
        // )
        // alert(JSON.stringify(this.state.application))
        //alert(JSON.stringify(groupBystate));
        return (
            <div>
                {/* <!-- Cities tab--> */}
                <div class="top-section" id="whstates">
                    <div class="container">
                        <div class="row wow fadeIn">
                            <div class="col-md-12">
                                <h2 class="text-center">Browse in Top 6 States  </h2>
                                <div class="tab-container mt-30">
                                    <ul class="city-tabs">

                                        <li class="tab-link current col-md-2" data-tab="city-1" id="tab-city-1" onClick={() => this.componentDidMount(14)}>Rajasthan</li>
                                        <li class="tab-link col-md-2" data-tab="city-2" id="tab-city-2" onClick={() => this.componentDidMount(1)}>Andhra Pradesh</li>
                                        <li class="tab-link col-md-2" data-tab="city-3" id="tab-city-3" onClick={() => this.componentDidMount(5)}>Gujarat</li>
                                        <li class="tab-link col-md-2" data-tab="city-4" id="tab-city-4" onClick={() => this.componentDidMount(10)}>Madhya Pradesh</li>
                                        <li class="tab-link col-md-2" data-tab="city-5" id="tab-city-5" onClick={() => this.componentDidMount(11)}>Maharashtra</li>
                                        <li class="tab-link col-md-2" data-tab="city-6" id="tab-city-6" onClick={() => this.componentDidMount(18)}>Telangana</li>
                                    </ul>
                                    <div id="city-1" class="city-tab-content current">
                                        <div class="">
                                            {
                                                warehouselist.map(item => (
                                                    <div class="col-md-2 mb-10">
                                                        <div>{item.warehouse_name}</div>
                                                        <b>{item.location_name}</b>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div id="city-2" class="city-tab-content">
                                        <div class="">
                                            {
                                                warehouselist.map(item => (
                                                    <div class="col-md-2 mb-10">
                                                        <div>{item.warehouse_name}</div>
                                                        <b>{item.location_name}</b>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div id="city-3" class="city-tab-content">
                                        <div class="">
                                            {
                                                warehouselist.map(item => (
                                                    <div class="col-md-2 mb-10">
                                                        <div>{item.warehouse_name}</div>
                                                        <b>{item.location_name}</b>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div id="city-4" class="city-tab-content">
                                        <div class="">
                                            {
                                                warehouselist.map(item => (
                                                    <div class="col-md-2 mb-10">
                                                        <div>{item.warehouse_name}</div>
                                                        <b>{item.location_name}</b>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div id="city-5" class="city-tab-content">
                                        <div class="">
                                            {
                                                warehouselist.map(item => (
                                                    <div class="col-md-2 mb-10">
                                                        <div>{item.warehouse_name}</div>
                                                        <b>{item.location_name}</b>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div id="city-6" class="city-tab-content">
                                        <div class="">
                                            {
                                                warehouselist.map(item => (
                                                    <div class="col-md-2 mb-10">
                                                        <div>{item.warehouse_name}</div>
                                                        <b>{item.location_name}</b>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Our Partners End--> */}

            </div>
        )
    }
}

export default Home_Component_Cities


$(document).on('click', 'ul.city-tabs li', function () {
    // your function here
    var tab_id = $(this).attr('data-tab');
    //alert(tab_id)
    var as = "#tab-" + tab_id;
    //alert($(as).text());


    $('ul.city-tabs li').removeClass('current');
    $('.city-tab-content').removeClass('current');

    $(this).addClass('current');
    $("#" + tab_id).addClass('current');
});



// $(document).ready(function () {

//     $('ul.city-tabs li').click(function () {
//         var tab_id = $(this).attr('data-tab');
//         var as = "#tab-" + tab_id;
//         //alert($(as).text());


//         $('ul.city-tabs li').removeClass('current');
//         $('.city-tab-content').removeClass('current');

//         $(this).addClass('current');
//         $("#" + tab_id).addClass('current');
//     })

// })
