import React, { Component } from 'react';
import { Link, useParams, params } from 'react-router-dom';
import queryString from 'query-string';
import { map } from 'jquery';


export class Warehouse_Details_Detail_Tab extends Component {
    constructor(props) {
        super(props);
        //this.routeParam = props.match.params.userId;
        this.state = {
            error: null,
            isLoaded: false,
            praposal: [],
            isFetching: false
        };
        //const id = new URLSearchParams(this.props.location.search).get("id");

        //alert(search);
        // this.Headline = this.Headline.bind(this);
    }

    componentDidMount() {
        //const query = require('query-string');
        // eslint-disable-next-line no-restricted-globals

        // eslint-disable-next-line no-restricted-globals
        //const value = queryString.parse(location.search);

        // alert(this.props.wh_id);
        const id = this.props.wh_id;

        fetch("https://agrigates.com/v2/bmsnew/warehouse/proposal", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "id": id
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    praposal: result.data,
                    isFetching: true
                });

                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    render() {
        const { error, isLoaded, praposal } = this.state;
        const { isFetching } = this.state;
        var singledata = null;
        singledata = praposal.length == 0 ? null : praposal;
        if (singledata != null) {
            singledata = singledata[0];
        }

        var maplink = "";
        if (isFetching == true) {
            maplink = "http://www.google.com/maps/place/" + singledata.lat + "," + singledata.long;
        }

        return (
            <div>
                {isFetching == false ? (
                    <div class="rentbox-title">Loading... </div>
                ) : (
                    <div >
                        {/* <!-- First Box --> */}
                        <div class="rentbox-title" style={{ borderBottom: "0" }}>
                            <h4 class="heading" hidden>Location</h4>
                            <p>Distance From:</p>
                            <div hidden>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.8589690933627!2d73.0590673142133!3d19.113841955750505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1bd20318861%3A0x37d753a63d2d7b6e!2sGarg%20Warehouse%20(Garib%20Nawaz%20Dahisar%20Mori)!5e0!3m2!1sen!2sin!4v1597927706353!5m2!1sen!2sin" width="100%" height="200" frameborder="0" style={{ border: "0;" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                                <iframe src={maplink} width="100%" height="200" frameborder="0" style={{ border: "0;" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

                            </div>

                            <div class="row mt-20">
                                <div class="col-md-2 col-sm-4 col-xs-6">
                                    <div class="box">
                                        <div class="distance-cir">
                                            <img src={require('../assests/images/a.png')} width="18" />
                                            <div class="distance-text">Main<br />Road
                                                <br />
                                                <span>{singledata.distance_from_main_road == null ? 0 : singledata.distance_from_main_road} km</span>
                                            </div>
                                        </div>
                                        {/* <div class="tooltip--multiline">
                                                <p>{singledata.distance_from_main_road == null ? 0 : singledata.distance_from_main_road} km</p>
                                            </div> */}
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4 col-xs-6">
                                    <div class="box">
                                        <div class="distance-cir">
                                            <img src={require('../assests/images/b.png')} width="30" />
                                            <div class="distance-text">State<br />Highway<br />
                                                <span>{singledata.distance_from_state_highway == null ? 0 : singledata.distance_from_state_highway} km</span>
                                            </div>
                                        </div>
                                        {/* <div class="tooltip--multiline">
                                                <p>{singledata.distance_from_state_highway == null ? 0 : singledata.distance_from_state_highway} km</p>
                                            </div> */}
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4 col-xs-6">
                                    <div class="box">
                                        <div class="distance-cir">
                                            <img src={require('../assests/images/c.png')} width="28" />
                                            <div class="distance-text">National<br />Highway <br />
                                                <span>{singledata.distance_from_national_highway == null ? 0 : singledata.distance_from_national_highway} km</span>
                                            </div>
                                        </div>
                                        {/* <div class="tooltip--multiline">
                                                <p>{singledata.distance_from_national_highway == null ? 0 : singledata.distance_from_national_highway} km</p>
                                            </div> */}
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4 col-xs-6">
                                    <div class="box">
                                        <div class="distance-cir">
                                            <img src={require('../assests/images/d.png')} width="28" />
                                            <div class="distance-text">Railway<br />Station <br />
                                                <span>{singledata.distance_from_distance_from_mandi_railway_station == null ? 0 : singledata.distance_from_distance_from_mandi_railway_station} km</span>
                                            </div>
                                        </div>
                                        {/* <div class="tooltip--multiline">
                                                <p>{singledata.distance_from_distance_from_mandi_railway_station == null ? 0 : singledata.distance_from_distance_from_mandi_railway_station} km</p>
                                            </div> */}
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4 col-xs-6">
                                    <div class="box">
                                        <div class="distance-cir">
                                            <img src={require('../assests/images/e.png')} width="28" />
                                            <div class="distance-text">Police<br />Highway <br />
                                                <span>{singledata.nearest_police_stattion == null ? 0 : singledata.nearest_police_stattion} km</span>
                                            </div>
                                        </div>
                                        {/* <div class="tooltip--multiline">
                                                <p>{singledata.nearest_police_stattion == null ? 0 : singledata.nearest_police_stattion} km</p>
                                            </div> */}
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4 col-xs-6">
                                    <div class="box">
                                        <div class="distance-cir">
                                            <img src={require('../assests/images/f.png')} width="28" />
                                            <div class="distance-text">Fire<br />Highway <br />
                                                <span> {singledata.nearest_fire_stattion == null ? 0 : singledata.nearest_fire_stattion} km</span>
                                            </div>
                                        </div>
                                        {/* <div class="tooltip--multiline">
                                                <p>{singledata.nearest_fire_stattion == null ? 0 : singledata.nearest_fire_stattion} km</p>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- ===========--> */}
                            <div class="wd-table table-responsive   mt-20" style={{ bordertop: "1px dotted;" }}>
                                <table class="table mt-20">
                                    <tbody>
                                        <tr>
                                            <th class="">Insurance</th>
                                            <th class=""></th>
                                            <th class="">Management</th>
                                            <th class=""></th>
                                        </tr>
                                        <tr>
                                            <td>Structural</td>
                                            <td>{singledata.structural_insurance == "no" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                            <td>Professionally Management by WSP</td>
                                            <td>{singledata.wsp_managed == null ? "N.A." : singledata.wsp_managed}</td>
                                        </tr>
                                        <tr>
                                            <td>Stock</td>
                                            <td>{singledata.stock_insurance == "No" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                            <td>Warehouse Owner Managed</td>
                                            <td>{singledata.who_managed}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Lock and Key</td>
                                            <td>{singledata.lock_and_key}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* <!-- Structural Propertiess --> */}
                            <div class="wd-table table-responsive mt-20" style={{ bordertop: "1px dotted" }}>
                                <h4 class="heading " style={{ paddingtop: "20px" }}>Structural Properties</h4>
                                <table class="table mt-20">
                                    <tbody>
                                        <tr>
                                            <td>Capacity</td>
                                            <td>{singledata.warehouse_capacity}</td>
                                            <td>Windows</td>
                                            <td>{singledata.no_of_windows}</td>
                                        </tr>
                                        <tr>
                                            <td>Length(ft)</td>
                                            <td>{singledata.length}</td>
                                            <td>Ventilators</td>
                                            <td>{singledata.no_of_ventilators}</td>
                                        </tr>
                                        <tr>
                                            <td>Width(ft)</td>
                                            <td>{singledata.breadth}</td>
                                            <td>Compound Wall</td>
                                            <td>{singledata.compound}</td>
                                        </tr>
                                        <tr>
                                            <td>Hight(ft)</td>
                                            <td>{singledata.length}</td>
                                            <td>Compound Gate</td>
                                            <td>{singledata.compound_gate}</td>
                                        </tr>
                                        {/* <tr>
                                                <td>Levels</td>
                                                <td>Single Story</td>
                                                <td>No of years from  constration</td>
                                                <td>0</td>
                                            </tr> */}
                                        <tr>
                                            {/* <td>Roof</td>
                                                <td>Cemented</td> */}
                                            <td>Plinth height</td>
                                            <td>{singledata.plinth_height}</td>
                                        </tr>
                                        <tr>
                                            <td>Flooring</td>
                                            <td>{singledata.flooring_type}</td>
                                            <td>Elevation from road</td>
                                            <td>{singledata.elevation_from_road}</td>
                                        </tr>
                                        <tr>
                                            <td>Walls</td>
                                            <td>{singledata.wall_type}</td>
                                            <td>Dreinage Channel</td>
                                            <td>{singledata.drainage}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- Amenities --> */}
                            <div class="wd-table mt-20  ">
                                <h4 class="heading pt-20">Amenities</h4>
                                <div class="row mt-20">

                                    {
                                        singledata.security_personal_available == "Yes" &&
                                        <div class="col-md-4 col-sm-6 col-xs-12 mb-20">
                                            <div class="">
                                                <div class="amenities-cir">
                                                    <img src={require('../assests/images/security.png')} width="25" />
                                                </div>
                                                <div class="amenities-text">
                                                    <div>Security</div>
                                                    <span class="amenities-subtext">{singledata.gaurd_type}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        singledata.is_stack_card_used == "Yes" &&
                                        <div class="col-md-4 col-sm-6 col-xs-12 mb-20">
                                            <div class="">
                                                <div class="amenities-cir">
                                                    <img src={require('../assests/images/sc.png')} width="35" />
                                                </div>
                                                <div class="amenities-text">
                                                    <p>Stack Cards</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        singledata.is_firefighting_available == "Yes" &&
                                        <div class="col-md-4 col-sm-6 col-xs-12 mb-20">
                                            <div class="">
                                                <div class="amenities-cir">
                                                    <img src={require('../assests/images/fa.png')} width="25" />
                                                </div>
                                                <div class="amenities-text">
                                                    <div>Firefighting Arrangements</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div class="col-md-4 col-sm-6 col-xs-12 mb-20">
                                        <div class="">
                                            <div class="amenities-cir">
                                                <img src={require('../assests/images/wf.png')} width="30" />
                                            </div>
                                            <div class="amenities-text">
                                                <div>Weighbridge </div>
                                                <span class="amenities-subtext">{singledata.weighbridge_facility}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (singledata.power_backup != "No Information" && singledata.power_backup != "") ?
                                            <div class="col-md-4 col-sm-6 col-xs-12 mb-20">
                                                <div class="">
                                                    <div class="amenities-cir">
                                                        <img src={require('../assests/images/pb.png')} width="35" />
                                                    </div>
                                                    <div class="amenities-text">
                                                        <div>Power Backup</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        (singledata.labour_availability != "" && singledata.labour_availability != "No Information") ?
                                            <div class="col-md-4 col-sm-6 col-xs-12 mb-20">
                                                <div class="">
                                                    <div class="amenities-cir">
                                                        <img src={require('../assests/images/la.png')} width="30" />
                                                    </div>
                                                    <div class="amenities-text">
                                                        <div>Labour Avallability</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        singledata.grading_sorting_facility != "" && singledata.grading_sorting_facility != "No Information" ?
                                            <div class="col-md-4 col-sm-6 col-xs-12 mb-20">
                                                <div class="">
                                                    <div class="amenities-cir">
                                                        <img src={require('../assests/images/gsf.png')} width="25" />
                                                    </div>
                                                    <div class="amenities-text">
                                                        <div>Grading Sorting</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        singledata.unloading_platform != "" && singledata.unloading_platform != "No Information" ?
                                            <div class="col-md-4 col-sm-6 col-xs-12 mb-20">
                                                <div class="">
                                                    <div class="amenities-cir">
                                                        <img src={require('../assests/images/up.png')} width="30" />
                                                    </div>
                                                    <div class="amenities-text">
                                                        <div>Unloading Platform</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>
                            {/* <!-- Amenities End--> */}
                            {/* <!-- Operation Management--> */}
                            <div class="wd-table table-responsive   mt-20" style={{ bordertop: "1px dotted" }}>
                                <h4 class="heading pt-20" style={{ paddingtop: "20px" }}>Operation Management</h4>
                                <table class="table mt-20">
                                    <tbody>
                                        <tr>
                                            <td>Dunnage</td>
                                            <td>{singledata.is_dunnage_used == "No" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                            <td>Spilage Colection and Record</td>
                                            <td>{singledata.spillage_collection_and_record == "No Information" || singledata.spillage_collection_and_record == "" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                        </tr>
                                        <tr>
                                            <td>Stack Plan</td>
                                            <td>{singledata.is_stack_card_used == "Yes" ? <img src={require('../assests/images/check.png')} /> : <img src={require('../assests/images/cancel.png')} />}</td>
                                            {/* <td>Cleaning of  Warehouse </td>
                                                <td>{singledata.warehouse_cleaning_frequency}</td> */}
                                        </tr>
                                        <tr>
                                            <td>Stock Register</td>
                                            <td>{singledata.is_register_used == "Yes" ? <img src={require('../assests/images/check.png')} /> : <img src={require('../assests/images/cancel.png')} />}</td>
                                            <td>Digital Book Keeping</td>
                                            <td>{singledata.digital_bookkeeping == "No Information" || singledata.digital_bookkeeping == "" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                        </tr>
                                        {/* <tr>
                                                <td>Document Keeping</td>
                                                <td>0</td>
                                                <td></td>
                                                <td></td>
                                            </tr> */}
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- Past History--> */}
                            <div class="wd-table mt-20" style={{ bordertop: "1px" }}>
                                <h4 class="heading pt-20" style={{ paddingtop: "20px" }}>Past History</h4>
                                <table class="table mt-20">
                                    <tbody>
                                        <tr>
                                            <td>Theft</td>
                                            <td>{singledata.theft == "No" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                            <td>Flooding</td>
                                            <td>{singledata.flooding == "No" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                        </tr>
                                        <tr>
                                            <td>Contamination</td>
                                            <td>{singledata.contamination == "No" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                            <td>Fire </td>
                                            <td>{singledata.fire == "No" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                        </tr>
                                        <tr>
                                            <td>Shortage</td>
                                            <td>{singledata.shortage == "No" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                            <td>Flood</td>
                                            <td>{singledata.flooding == "No" ? <img src={require('../assests/images/cancel.png')} /> : <img src={require('../assests/images/check.png')} />}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default Warehouse_Details_Detail_Tab
