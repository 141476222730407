import React, { Component } from 'react'
import $ from 'jquery'
import Loaderfn from './Loaderfn';
import { AsyncStorage } from 'AsyncStorage';

export class Warehouse_details_survillance_tab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            warehouselist: [],
            isFetching: false,
            stockdetails: [],
            filterlist: [],
            commodities: [],
            quantity: '',
            fromdate: '',
            todate: '',
            cg_id: null,
            hfwarehouseid: null,
            hfwarehousename: '',
            hfrc_id: null, //register customer id
            booking_date: '',
            req_status: 'Order Placement',
            order_type: 'Fixed Rent',
            hfClientID: null,
            client_mobile: '',

            singlecommodity: [],

            radioname: false,
            termscondition: false,
            userid: '',
            user_mobileno: ''
        };
        this.setState({ ids: this.props.wh_id })
        var today = new Date();
        var getdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                this.setState({ userid: value });
            }

        });
        AsyncStorage.getItem('username').then((value) => {
            if (value) {
                this.setState({ user_mobileno: value });
            }

        });

        // this.Headline = this.Headline.bind(this);
    }



    state = {
        startDate: new Date()
    };

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };


    componentDidMount_commodities(id) {

        //first api for list
        this.setState({
            quantity: '',
            fromdate: '',
            todate: '',
            termscondition: false
        });

        $('#termscondition').prop('checked', false);

        fetch("https://agrigates.com/v2/api/rateCards/get", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "whId": id
                    },
                    "select": [],
                    "join": {
                        "commodityGroup": true
                    },
                    "sort": {},
                    "offset": 0,
                    "limit": 100
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    stockdetails: result.data,
                    isFetching: true
                });

                console.log(result.data);
                if (this.state.stockdetails.length < 1) {
                    $("#btnbookspace").attr("disabled", true);
                }
                else {
                    $("#btnbookspace").attr("disabled", false);
                }
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    componentDidMount() {
        fetch("https://agrigates.com/v2/bmsnew/warehouse/getlist", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "limit": 2000000
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    warehouselist: result.data,
                    isFetching: true
                });

                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    onChange = (e) => {
        /*
          Because we named the inputs to match their
          corresponding values in state, it's
          super easy to update the state
        */
        this.setState({ [e.target.name]: e.target.value });
    }
    handleCheck = (e) => {
        this.setState({ termscondition: !this.state.termscondition });
    }
    onSubmit = (e) => {
        if (this.state.radioname == false) {
            e.preventDefault();
            //alert('Please choose commodity');
            $("#showmsg").addClass("alert alert-danger").text("Please choose commodity.");
        }

        else if (this.state.quantity == '') {
            e.preventDefault();
            $("#showmsg").addClass("alert alert-danger").text("Please Enter Quantity");
            // alert('Please Enter Quantity');
        }
        else if (this.state.fromdate > this.state.todate) {
            e.preventDefault();
            $("#showmsg_posted").addClass("alert alert-danger").text("From Date should be less than from To Date.");
        }
        else if (this.state.fromdate == '') {
            e.preventDefault();
            $("#showmsg").addClass("alert alert-danger").text("Please Enter From Date");
            //alert('Please Enter From Date');
        }
        else if (this.state.todate == '') {
            e.preventDefault();
            $("#showmsg").addClass("alert alert-danger").text("Please Enter To Date");
            //alert('Please Enter To Date');
        }
        else if (this.state.termscondition == false) {
            e.preventDefault();
            $("#showmsg").addClass("alert alert-danger").text("Please check terms and conditions");
            //alert('Please check terms and condition');
        }
        else {
            //$("#btnbookspace").attr("disabled", false);
            e.preventDefault();
            const Request = {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'session': 'pass'
                },
                body: JSON.stringify(
                    {
                        "document": {
                            "od_wh_id": this.state.singlecommodity[0].rc_wh_id,
                            "od_wh_name": this.state.singlecommodity[0].rc_wh_name,
                            "od_rc_id": this.state.singlecommodity[0].rc_id, // registered customer
                            "od_cg_id": this.state.singlecommodity[0].rc_cg_id, // commodity id
                            "od_from_date": this.state.fromdate,
                            "od_to_date": this.state.todate,
                            "od_tnc_time": this.state.booking_date,
                            "od_req_status": this.state.req_status,
                            "od_quantity": this.state.quantity,
                            "od_order_type": this.state.order_type,
                            "od_final_order_price": this.state.singlecommodity[0].rc_min_rate,
                            "od_client_id": this.state.userid, //logged in user
                            "od_client_mobile_no": this.state.user_mobileno, //logged in user mobile no
                            "od_created_at": this.state.getdate,
                            "od_created_by": this.state.userid
                        }
                    })
            }
            fetch("https://agrigates.com/v2/api/orders", Request)
                .then(response => response.json())
                .then(result => {
                    this.setState({
                        //Successmsg: (result)

                    });
                    alert('Warehouse Booked');
                    window.location.href = '/#/booking-orders';
                    window.$('#details_survi').modal('hide');
                    console.log(result);
                },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    })

        }
    }
    componentDidMount_cg_info(id) {
        //alert(this.state.isFetching);
        if (id != '') {
            this.setState({ radioname: true });
            if (this.state.isFetching == true) {

                this.state.singlecommodity = this.state.stockdetails.filter(x => x.rc_cg_id == id);

            }
        }

    }
    setLocalStorage() {
        localStorage.setItem('lastscreen', 'bookspacefromsurvillance');
    }
    render() {
        const { error, isLoaded, warehouselist } = this.state;
        const { isFetching } = this.state;
        var { filterlist, stockdetails, singlecommodity } = this.state;

        return (
            <div>
                {isFetching == false ? (
                    <Loaderfn></Loaderfn>
                ) : (
                    <div>
                        <div class="surveillance-tab text-center">
                            <img src={require('../assests/images/surveilance.png')} width="" />
                            <p class="mt-30"><b>Live Video Feeds are available on request  once you<br /> stock is in this Warehouse</b></p>
                            {
                                this.state.userid == '' ?
                                    <a style={{ cursor: 'pointer' }} class="btn btn-danger mt-30" role="button" data-toggle="modal" data-target="#modal-signin" onClick={() => this.setLocalStorage()}>Book Space</a>
                                    :
                                    <a style={{ cursor: 'pointer' }} class="btn btn-danger mt-30" role="button" data-toggle="modal" data-target="#details_survi" onClick={() => this.componentDidMount_commodities(this.props.wh_id)}>Book Space</a>

                            }

                        </div>
                    </div>

                )}
                <div class="modal fade sign-popup" id="details_survi">

                    <form onSubmit={this.onSubmit}>
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                    <h4 class="modal-title"><b>Booking Details</b></h4>
                                </div>
                                <div class="modal-body">
                                    {isFetching == false ? (
                                        <div>Loading...</div>
                                    ) : (
                                        <div class="row">
                                            {
                                                stockdetails.length < 1 ? <div>No Commodity Available</div> :
                                                    stockdetails.map(item => (
                                                        <div class="col-md-4 mt-10">
                                                            <label className="labl BDinner-box active" style={{ minHeight: '110px' }}>
                                                                {/* <div class="BDinner-box"> */}
                                                                <input type="radio" name="radioname" value={this.state.radioname} onClick={() => this.componentDidMount_cg_info(item.rc_cg_id)} />
                                                                <div class="similar-warehouse-list" style={{ padding: '0px' }}>
                                                                    <div class="BD-img inline-block">
                                                                        <img src={item.cg_Link} />
                                                                        <div class="img-label">{item.cg_Name}</div>
                                                                    </div>
                                                                    <div class="BD-content inline-block ">
                                                                        <div>
                                                                            <h4 class="heading">{item.cg_Name}</h4>
                                                                            <p class="pile-dtl">Price:<b>Rs.{item.rc_min_rate} Per MT</b></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* </div> */}
                                                            </label>
                                                        </div>


                                                    ))
                                            }

                                        </div>
                                    )
                                    }
                                    <div class="uprofile-form">

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">

                                                    <input type="text" class="form-control" onChange={event => this.setState({ quantity: event.target.value.replace(/\D/, '') })} placeholder="Quantity" name="quantity" value={this.state.quantity} />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="date" class="form-control" onChange={this.onChange} placeholder="Start Date" name="fromdate" value={this.state.fromdate} />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="date" class="form-control" onChange={this.onChange} placeholder="End Date" name="todate" value={this.state.todate} />
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="signin checkbox">
                                                        <label><input type="checkbox" onChange={this.handleCheck} defaultChecked={this.state.termscondition} name="termscondition" />Terms and Conditions</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div id="divmsg"></div>
                                    <div id="showmsg"></div>


                                </div>
                                <div class="modal-footer text-center">
                                    <button type="submit" id="btnbookspace" class="btn btn-danger" >Book Space </button>
                                </div>
                            </div>

                            {/* <!-- /.modal-content --> */}
                        </div>
                    </form>

                </div>

            </div>
        )
    }
}

export default Warehouse_details_survillance_tab
