import React, { Component } from 'react';
import $ from 'jquery';
import { AsyncStorage } from 'AsyncStorage';

export class Add_feedback extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            name: '',
            comments: '',
            userid: ''
        };
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                this.setState({ userid: value });
            }
            else {
                window.location = '/';
            }
        });
    }
    onChange = (e) => {
        // alert(e.target.value) ;   
        //e.preventDefault(); 
        this.setState({ [e.target.name]: e.target.value });
    }
    onSubmit = (e) => {

        e.preventDefault();

        if (this.state.rate == null || this.state.rate == 0) {
            $("#showmsg_rate").addClass("alert alert-danger").text("Please Choose Rating");
        }
        else if (this.state.comments == '') {
            $("#showmsg_rate").addClass("alert alert-danger").text("Please Enter Review");
        }
        else {
            const warehouseid = localStorage.getItem('warehouseid');
            // alert(warehouseid);
            // alert(this.state.userid);
            const Request = {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'session': 'pass'
                },
                body: JSON.stringify(
                    {
                        "warehouseId": warehouseid,
                        "review": this.state.comments,
                        "score": this.state.rate,
                        "session": {
                            "userId": this.state.userid
                        }
                    }
                )
            }

            fetch("https://agrigates.com/v2/api/warehouseRatings", Request)
                .then(response => response.json())
                .then(result => {

                    this.setState({
                        isLoaded: true,
                        isFetching: true

                    });
                    this.props.handler();
                    this.props.parentCallback("Review posted successfully.");
                },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                );
        }
    }
    render() {
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <div>
                        <div class="form-group">
                            <div class="ratestar">
                                <input type="radio" id="star5" name="rate" value="5" onChange={this.onChange} />
                                <label for="star5">5 stars</label>
                                <input type="radio" id="star4" name="rate" value="4" onChange={this.onChange} />
                                <label for="star4">4 stars</label>
                                <input type="radio" id="star3" name="rate" value="3" onChange={this.onChange} />
                                <label for="star3">3 stars</label>
                                <input type="radio" id="star2" name="rate" value="2" onChange={this.onChange} />
                                <label for="star2">2 stars</label>
                                <input type="radio" id="star1" name="rate" value="1" onChange={this.onChange} />
                                <label for="star1">1 star</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" name="comments" value={this.state.comments} onChange={this.onChange} placeholder="Please input Comment"></textarea>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                        <div id="showmsg_rate"></div>
                    </div>
                </form>
            </div>
        )
    }
}

export default Add_feedback
