import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Headline from './Warehouse_List_Filters';
import { AsyncStorage } from 'AsyncStorage';

export class Home_Component_Warehouse_List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            warehouselist: [],
            userid: ''//localStorage.getItem('UserID')
        };
        AsyncStorage.getItem('UserID').then((value) => {
            if (value) {
                this.setState({ userid: value });
            }
        });
        // this.Headline = this.Headline.bind(this);
    }
    componentDidMount() {
        fetch("https://agrigates.com/v2/bmsnew/warehouse/getlist", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'session': 'pass'
            },
            body: JSON.stringify(
                {
                    "filter": {
                        "showInLanding": "4",
                        "showInSpaceDiscovery": "1",
                        "premiumProperty": "1"
                    },
                    "offset": 0,
                    "limit": 4
                }
            )
        }
        )
            .then(response => response.json())
            .then(result => {
                this.setState({
                    isLoaded: true,
                    warehouselist: result.data
                });

                console.log(result.data);
            },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    setLocalStorage() {
        localStorage.setItem('lastscreen', 'listyourwarehouse');
    }

    render() {
        const { error, isLoaded, warehouselist } = this.state;

        //warehouselist = warehouselist.limit < 5;
        return (
            <div>

                <div class="mt-20">
                    <div class="container">

                        <div class="row mt-30">
                            {
                                warehouselist.map(warehouse => (
                                    <div class="col-md-3 col-sm-6">
                                        <div class="find-warehouse-box wow fadeIn">
                                            <div class="find-banner">
                                                <div class="imgboxh200">
                                                    {warehouse.images.length > 0 ? <img src={warehouse.images[0].file_path} style={{ height: '195px' }}></img> : <img src={require('../assests/images/demowarehouse.jpg')} style={{ height: '195px' }} />}
                                                </div>
                                                <div class="img-label">{warehouse.warehouse_name}</div>
                                            </div>
                                            <div class="find-content">
                                                <h4 class="heading">{warehouse.warehouse_subtype_name}</h4>
                                                {/* <p>Timely Availability of credit to enhance holding capacity, Timely Availability of credit to enhance holding capacity</p> */}
                                                <Link to={`/warehouse-details?id=${warehouse.warehouse_id}`}><a class="more-link">Read more</a></Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                                )
                            }
                        </div>
                    </div>
                </div>



                <div class="text-center mt-30 listpadding">
                    <Link to="/warehouse-list?type=&lat=&lng=&location=&city=&state=" ><a class="btn btn-danger mr-10" role="button " >See all</a></Link>

                    {
                        this.state.userid == '' || this.state.userid == undefined ?
                            <Link data-toggle="modal" id="projects" data-target="#modal-signin" onClick={() => this.setLocalStorage()}>
                                <a class="btn btn-danger" role="button "> List your warehouse </a>
                            </Link>
                            :
                            <Link to="/warehouse-listing">
                                <a class="btn btn-danger" role="button " > List your warehouse</a>
                            </Link>
                    }
                    {/* <Link to="/warehouse-listing"><a class="btn btn-danger" role="button " >List your  warehouse</a></Link> */}
                </div>
            </div>
        )
    }
}

export default Home_Component_Warehouse_List
